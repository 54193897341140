import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { get } from 'lodash';
import { feeSchedulePrograms as fspUtils } from '@unite-us/client-utils';
import AuthorizationSpendCard from 'pages/service-authorizations/components/AuthorizationSpendCard';
import CapsInfo from 'components/Referrals/components/CapsInfo';
import { Spinner } from 'common/spinners';
import { useFind } from 'src/api/APIHooks';
import formatSpend from 'common/utils/Money/formatSpend';
import { getCapInformation } from 'components/ServiceAuthorization/utils';

function AuthorizationsSection({
  authorizedSpends, isCostBased, state, unit,
}) {
  const filterAuthorizationSpends =
    authorizedSpends.filter((authorizationSpend) => authorizationSpend.state === state);
  return (
    <>
      <div className="mb-3">
        {filterAuthorizationSpends.length} {pluralize('request', filterAuthorizationSpends.length)}
      </div>
      <div className="w-9/12 space-y-2">
        {filterAuthorizationSpends.map((authorizationSpend) => (
          <AuthorizationSpendCard
            key={authorizationSpend.short_id}
            item={authorizationSpend}
            unit={unit}
            isAuthorized={authorizationSpend.state === 'approved'}
            isCostBased={isCostBased}
          />
        ))}
      </div>
    </>
  );
}

AuthorizationsSection.propTypes = {
  authorizedSpends: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    short_id: PropTypes.string,
    state: PropTypes.string,
    spend: PropTypes.number,
    service_starts_at: PropTypes.string,
    service_ends_at: PropTypes.string,
    updated_at: PropTypes.string,
    created_at: PropTypes.string,
    requester_name: PropTypes.string,
    case_state: PropTypes.string,
    case_opened_date: PropTypes.string,
  })),
  isCostBased: PropTypes.bool,
  state: PropTypes.string,
  unit: PropTypes.string,
};

AuthorizationsSection.defaultProps = {
  authorizedSpends: [],
  isCostBased: false,
  state: '',
  unit: '',
};

const CapInformationSection = ({
  feeScheduleProgram,
  startDate,
  endDate,
  personId,
  setAvailableAmount,
}) => {
  const { data: authorizedSpendsData, isFetching, isLoading } = useFind(
    'authorized_spend',
    {
      person: personId,
      fee_schedule_program: feeScheduleProgram.id,
      requested_starts_at: startDate.toISOString(),
      requested_ends_at: endDate.toISOString(),
    },
    { queryConfig: { placeholderData: undefined } },
  );

  const capInfo = getCapInformation(feeScheduleProgram, get(authorizedSpendsData, 'data.meta'));
  const isLoaded = !isFetching && !isLoading;

  const authorizedSpends = get(authorizedSpendsData, 'data.data', []);
  const totalApprovedSpend = get(authorizedSpendsData, 'data.meta.total_approved_spend', 0);
  const totalRequestedSpend = get(authorizedSpendsData, 'data.meta.total_requested_spend', 0);
  const availableCap = get(authorizedSpendsData, 'data.meta.available_cap', 0);
  const isCostBased = feeScheduleProgram && fspUtils.isCostPaymentType(feeScheduleProgram.payment_type);

  const sectionFormatSpend = (spend) => formatSpend(spend, feeScheduleProgram?.unit, isCostBased);

  const formattedTotalApprovedSpend = sectionFormatSpend(totalApprovedSpend);
  const formattedTotalRequestedSpend = sectionFormatSpend(totalRequestedSpend);
  const formattedAmountAvailable = capInfo.hasCap ? sectionFormatSpend(availableCap) : null;
  setAvailableAmount(availableCap);

  return (
    <>
      {isLoading ?
        <Spinner /> : (
          <CapsInfo
            totalApprovedSpend={formattedTotalApprovedSpend}
            totalRequestedSpend={formattedTotalRequestedSpend}
            capInfo={capInfo}
            amountAvailable={formattedAmountAvailable}
            approvedAuthorizationsSection={(
              <AuthorizationsSection
                state="approved"
                authorizedSpends={authorizedSpends}
                isCostBased={isCostBased}
                unit={feeScheduleProgram?.unit || ''}
              />
            )}
            requestedAuthorizationsSection={(
              <AuthorizationsSection
                state="requested"
                authorizedSpends={authorizedSpends}
                isCostBased={isCostBased}
                unit={feeScheduleProgram?.unit || ''}
              />
            )}
            isLoaded={isLoaded}
          />
        )}
    </>
  );
};

CapInformationSection.propTypes = {
  endDate: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  feeScheduleProgram: PropTypes.shape({
    id: PropTypes.string.isRequired,
    unit: PropTypes.string,
    has_cap: PropTypes.bool,
    payment_type: PropTypes.string,
  }).isRequired,
  personId: PropTypes.string.isRequired,
  setAvailableAmount: PropTypes.func.isRequired,
};

export default CapInformationSection;
