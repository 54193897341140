import { coreApi } from 'src/api/config';

const populatePreferredLanguages = async (contact, preferredLanguagesEnabled) => {
  const recordLanguagesResponse = preferredLanguagesEnabled ? await coreApi.query(
    'record_languages',
    { record_id: contact.id, record_type: 'Person' },
  ) : { data: { data: [] } };

  return {
    ...contact,
    preferred_languages: recordLanguagesResponse.data.data,
  };
};

export default populatePreferredLanguages;
