import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCurrentPayerId } from 'common/contexts/CurrentProviderContext';
import { SelectField } from 'components/Backoffice/form/SelectField';
import { SearchableField } from 'components/Backoffice/form/SearchableField';
import { SearchableSelectField } from 'components/Backoffice/form/SearchableSelectField';
import { assign, merge, isEmpty } from 'lodash';
import {
  useEnrollmentShortIdOptions,
  useEnrollmentClientNameOptions,
} from 'src/pages/enrollments/hooks';
import { Button, DurationField } from '@unite-us/ui';
import moment from 'moment';

const AllFiltersDrawer = ({
  filterValues,
  filterOptions,
  setRequestShortIdFilter,
  setRequestTypeFilter,
  setClientNameFilter,
  setSccPlanFilter,
  setDaysSinceLastUpdatedFilter,
  setInsurancePlanFilter,
  setSendingProviderFilter,
  setCreatedAfterFilter,
  setCreatedBeforeFilter,
  onSuccess,
  onUpdateFilter,
  enrollmentStatus,
  isFilterDataLoading,
}) => {
  const currentPayerId = useCurrentPayerId();
  const [localShortId, setLocalShortId] = useState(filterValues.requestShortId);
  const [localRequestType, setLocalRequestType] = useState(filterValues.requestType);
  const [localClientName, setLocalClientName] = useState(filterValues.clientName);
  const [localSccPlan, setLocalSccPlan] = useState(filterValues.sccPlan);
  const [localDaysSinceLastUpdated, setLocalDaysSinceLastUpdated] = useState(filterValues.daysSinceLastUpdated);
  const [localInsurancePlan, setLocalInsurancePlan] = useState(filterValues.insurancePlan);
  const [localSendingProvider, setLocalSendingProvider] = useState(filterValues.sendingProvider);
  const [startRequestDateField, setStartRequestDateField] = useState({
    value: !isEmpty(filterValues.createdAfter) ?
      moment(filterValues.createdAfter).unix() : undefined,
    valid: true,
    invalid: false,
    touched: false,
    pristine: true,
    dirty: false,
    visited: false,
    error: '',
  });
  const [endRequestDateField, setEndRequestDateField] = useState({
    value: !isEmpty(filterValues.createdBefore) ?
      moment(filterValues.createdBefore).unix() : undefined,
    valid: true,
    invalid: false,
    touched: false,
    pristine: true,
    dirty: false,
    visited: false,
    error: '',
  });

  const startCallbacks = {
    onChange: (value) => {
      const newStartRequestDateField = assign({}, startRequestDateField, { value, dirty: true, pristine: false });
      setStartRequestDateField({
        ...newStartRequestDateField,
      });
    },
  };

  const endCallbacks = {
    onChange: (value) => {
      const newEndRequestDateField = assign({}, endRequestDateField, { value, dirty: true, pristine: false });
      setEndRequestDateField({
      ...newEndRequestDateField,
      });
    },
  };

  const onSubmit = () => {
    setRequestShortIdFilter(localShortId);
    setRequestTypeFilter(localRequestType);
    setClientNameFilter(localClientName);
    setSccPlanFilter(localSccPlan);
    setDaysSinceLastUpdatedFilter(localDaysSinceLastUpdated);
    setInsurancePlanFilter(localInsurancePlan);
    setSendingProviderFilter(localSendingProvider);
    setCreatedAfterFilter(
      startRequestDateField.value ? moment.unix(startRequestDateField.value).toString() : undefined,
    );
    setCreatedBeforeFilter(
      endRequestDateField.value ? moment.unix(endRequestDateField.value).toString() : undefined,
    );
    onUpdateFilter();
    onSuccess();
  };

  const clearFilters = () => {
    setLocalShortId(undefined);
    setLocalRequestType(undefined);
    setLocalClientName(undefined);
    setLocalSccPlan(undefined);
    setLocalDaysSinceLastUpdated(undefined);
    setLocalInsurancePlan(undefined);
    setLocalSendingProvider(undefined);
    setStartRequestDateField({
      value: undefined,
      valid: true,
      invalid: false,
      touched: false,
      pristine: true,
      dirty: false,
      visited: false,
      error: '',
    });
    setEndRequestDateField({
      value: undefined,
      valid: true,
      invalid: false,
      touched: false,
      pristine: true,
      dirty: false,
      visited: false,
      error: '',
    });
  };

  const shortIdOptions = useEnrollmentShortIdOptions(
    currentPayerId,
    localShortId,
    enrollmentStatus,
  );

  const clientNameOptions = useEnrollmentClientNameOptions(
    currentPayerId,
    localClientName,
    enrollmentStatus,
  );

  return (
    <div className="flex flex-col pt-6 mb-0 h-full">
      <div className="col-9 mx-6 px-6 pt-4">
        <div className="col-9 flex flex-col text-text-blue w-5/6 mx-0 px-2">
          <h1 className="mb-2 font-extrabold">
            Filters
          </h1>
          <p className="">
            Add filters to refine your search.
          </p>
        </div>
      </div>
      <div className="col-9 my-3 flex flex-col mx-6 px-4">
        <h3
          className="block mb-1 leading-snug font-extrabold font-medium-font normal-case text-13px text-text-blue mx-4"
        >
          Request ID
        </h3>
        <SearchableField
          className="w-full mx-4"
          label="Request ID"
          onChange={({ value }) => setLocalShortId(value)}
          searchQuery={localShortId}
          options={shortIdOptions}
          onInputChange={setLocalShortId}
          placeholder={'Search for a request'}
          truncateOptions={false}
          clearable
          hideLabel
        />
      </div>
      <div className="col-9 my-3 flex flex-col mx-6 px-4">
        <h3
          className="block mb-1 leading-snug font-extrabold font-medium-font normal-case text-13px text-text-blue mx-4"
        >
          Request Type
        </h3>
        <SelectField
          className="w-full mx-4"
          label="Drawer Request Type"
          placeholder="Choose Type"
          options={filterOptions.requestType}
          value={localRequestType ?? ''}
          onChange={({ value }) => setLocalRequestType(value)}
          clearable
          hideLabel
        />
      </div>
      <div className="col-9 my-3 flex flex-col mx-6 px-4">
        <h3
          className="block mb-1 leading-snug font-extrabold font-medium-font normal-case text-13px text-text-blue mx-4"
        >
          Client Name
        </h3>
        <SearchableField
          className="w-full mx-4"
          label="Drawer Client Name"
          onChange={({ value }) => setLocalClientName(value)}
          searchQuery={localClientName}
          options={clientNameOptions}
          onInputChange={setLocalClientName}
          placeholder={'Search for a client'}
          truncateOptions={false}
          clearable
          hideLabel
        />
      </div>
      <div className="col-9 my-3 flex flex-col mx-6 px-4">
        <h3
          className="block mb-1 leading-snug font-extrabold font-medium-font normal-case text-13px text-text-blue mx-4"
        >
          Social Care Coverage Plan
        </h3>
        <SearchableSelectField
          className="w-full mx-4"
          label="Drawer Social Care Coverage Plan"
          placeholder="Choose Social Care Coverage Plan"
          value={localSccPlan ?? ''}
          onChange={({ value }) => setLocalSccPlan(value)}
          options={filterOptions.sccPlan}
          truncateOptions={false}
          clearable
          hideLabel
        />
      </div>
      <div className="col-9 my-3 flex flex-col mx-6 px-4">
        <h3
          className="block mb-1 leading-snug font-extrabold font-medium-font normal-case text-13px text-text-blue mx-4"
        >
          Last Updated
        </h3>
        <SelectField
          className="w-full mx-4"
          label="Drawer Last Updated"
          placeholder="Choose Last Updated"
          value={localDaysSinceLastUpdated ?? ''}
          onChange={({ value }) => setLocalDaysSinceLastUpdated(value)}
          options={filterOptions.daysSinceLastUpdated}
          clearable
          hideLabel
        />
      </div>
      <div className="col-9 my-3 flex flex-col mx-6 px-4">
        <h3
          className="block mb-1 leading-snug font-extrabold font-medium-font normal-case text-13px text-text-blue mx-4"
        >
          Insurance Plan
        </h3>
        <SearchableSelectField
          className="w-full mx-4"
          label="Insurance Plan"
          placeholder="Choose Insurance Plan"
          value={localInsurancePlan ?? ''}
          onChange={({ value }) => setLocalInsurancePlan(value)}
          options={filterOptions.insurancePlan}
          truncateOptions={false}
          clearable
          hideLabel
        />
      </div>
      <div className="col-9 my-3 flex flex-col mx-6 px-4">
        <h3
          className="block mb-1 leading-snug font-extrabold font-medium-font normal-case text-13px text-text-blue mx-4"
        >
          Sending Organization
        </h3>
        <SearchableSelectField
          className="w-full mx-4"
          label="Sending Organization"
          placeholder="Choose Sending Organization"
          value={localSendingProvider ?? ''}
          onChange={({ value }) => setLocalSendingProvider(value)}
          options={filterOptions.sendingProvider}
          truncateOptions={false}
          clearable
          hideLabel
        />
      </div>
      <div className="my-3 grid grid-cols-12 gap-2">
        <div className="col-start-2 col-span-4 mx-0">
          <h3 className="block mb-1 leading-snug font-extrabold font-medium-font normal-case text-13px text-text-blue">
            Date of Request
          </h3>
          <DurationField
            className="w-80"
            id="payer-filters-drawer-date-filter"
            label="Service Delivery Date(s)"
            startField={merge(startRequestDateField, startCallbacks)}
            endField={merge(endRequestDateField, endCallbacks)}
            placeholder="Choose Date Range"
            hideLabel
          />
        </div>
      </div>
      <div className="col-5 mt-3 flex flex-row justify-end mx-6 px-6 mb-6 pb-6">
        <button
          aria-label="Clear all"
          className="text-action-blue mx-4"
          onClick={clearFilters}
          type="button"
        >
          <span className="text-action-blue hover:text-text-blue  hover:text-text-blue text-md">
            Clear all
          </span>
        </button>
        <Button
          className="primary-button"
          label="Apply filters"
          primary
          onClick={onSubmit}
          disabled={isFilterDataLoading}
        />
      </div>
    </div>
  );
};

AllFiltersDrawer.propTypes = {
  filterValues: PropTypes.shape({
    requestShortId: PropTypes.string,
    requestType: PropTypes.string,
    clientName: PropTypes.string,
    sccPlan: PropTypes.string,
    daysSinceLastUpdated: PropTypes.string,
    insurancePlan: PropTypes.string,
    sendingProvider: PropTypes.string,
    createdAfter: PropTypes.string,
    createdBefore: PropTypes.string,
  }).isRequired,
  filterOptions: PropTypes.shape({
    requestType: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    sccPlan: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    daysSinceLastUpdated: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    insurancePlan: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    sendingProvider: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  setRequestShortIdFilter: PropTypes.func.isRequired,
  setRequestTypeFilter: PropTypes.func.isRequired,
  setClientNameFilter: PropTypes.func.isRequired,
  setSccPlanFilter: PropTypes.func.isRequired,
  setDaysSinceLastUpdatedFilter: PropTypes.func.isRequired,
  setInsurancePlanFilter: PropTypes.func.isRequired,
  setSendingProviderFilter: PropTypes.func.isRequired,
  setCreatedAfterFilter: PropTypes.func.isRequired,
  setCreatedBeforeFilter: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onUpdateFilter: PropTypes.func.isRequired,
  enrollmentStatus: PropTypes.string.isRequired,
  isFilterDataLoading: PropTypes.bool.isRequired,
 };

export default AllFiltersDrawer;
