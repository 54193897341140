import { compact, filter, get, includes, isEmpty, map } from 'lodash';
import {
  MULTIPLE_RECIPIENT_CC_ERROR_MESSAGE,
} from 'src/components/Referrals/ReferralServicesForm/constants';

const isCCSelected = (groupId, ccGroupIds) => includes(ccGroupIds, groupId);

const getInvalidGroupErrors = ({
  caseReferrals = [],
  ccGroupIds = [],
  fields = [],
  groupId = '',
  referral,
}) => {
  if (!isCCSelected(groupId, ccGroupIds) && isEmpty(caseReferrals)) {
    return [];
  }

  const currentRecipientId = get(referral, 'referred_to_group.id');
  const activeReferrals = filter(caseReferrals, (r) => get(r, 'state') !== 'accepted');
  const otherReferralsRecipientsIds = map(activeReferrals, (r) => get(r, 'receiving_provider.id'))
    .filter((recipientId) => recipientId !== currentRecipientId);
  const selectedGroupIds = compact(
    map(fields, (selected) => get(selected, 'group.value.id')),
  ).concat(otherReferralsRecipientsIds);

  const ccCount = (
    filter(selectedGroupIds, (id) => includes(ccGroupIds, id)) || []
  ).length;

  const providerCount = selectedGroupIds.length - ccCount;
  const hasMultipleRecipientsOrMixedProviders = (ccCount > 0 && providerCount > 0) || (ccCount > 1);

  return hasMultipleRecipientsOrMixedProviders ? [MULTIPLE_RECIPIENT_CC_ERROR_MESSAGE] : '';
};

export default getInvalidGroupErrors;
