/* eslint-disable no-param-reassign */
/* eslint-disable query-config/enforce-query-config */
import React, { useState } from 'react';
import { useFind, usePopulate, useFindRecord } from 'src/api/APIHooks';
import { dates } from '@unite-us/app-components';
import { get, isEmpty, upperFirst, isEqual, replace, startCase } from 'lodash';
import moment from 'moment';

const MONTHS_ENUM = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// can be added to a common date utility file
// if needed for referrals/cases
const getNoteDates = (interactionDate) => {
  const date = interactionDate.split('T')[0];
  if (!date) {
    return {
      day: '',
      month: '',
      year: '',
      fullDate: '',
    };
  }

  const dateObj = new Date(interactionDate);
  const year = date.substr(0, 4);
  const day = date.substr(8, 2);
  const monthIndex = date.substr(5, 2);
  const month = MONTHS_ENUM[parseInt(monthIndex, 10) - 1];

  return {
    day,
    month,
    year,
    time: moment(dateObj).format('h:mm a'),
    fullDate: date,
  };
};

const populateNotesCard = (notes) => {
  notes.forEach((n) => {
    let noteType;
    let added = '';
    const stateChange = get(n, 'details.context', null);
    const updateChange = get(n, 'details.type', null) === 'update';
    if (isEqual(n.category, 'interaction')) {
      noteType = 'Interaction ';
    } else if (isEqual(n.category, 'service_provided')) {
      noteType = 'Service Provided ';
    } else if (stateChange) {
      noteType = startCase(replace(stateChange, '/_/g', ' '));
    } else if (updateChange) {
      noteType = 'Updated ';
    } else {
      noteType = 'Note ';
      added = 'added';
    }
    const date = dates.formatLocalDateTime(get(n, 'created_at'));
    const interactionDate = get(n, 'created_at');
    n.interactionDate = getNoteDates(interactionDate);
    n.interactionType = upperFirst(get(n, 'details.interaction.type', ''));
    n.duration = get(n, 'details.interaction.duration', '');
    n.Title = () => (
      <p className="pb-2">
        <strong>{noteType}</strong>
        {added} by <strong>{ get(n, 'employee.full_name', '') }</strong> on {date}
      </p>
    );
  });
};

const initialPagingState = { size: 10, page: 1 };
const placeholderMessageResponse = {
  data: {
    data: [],
    paging: {
      number: 1,
      size: 10,
      total_count: 10,
      total_pages: 1,
    },
  },
};

export const useMessages = ({ subject, providerId }) => {
  const [page, setPage] = useState(initialPagingState.page);
  const [pageSize, setPageSize] = useState(initialPagingState.size);
  const {
    data: messagesResponse,
    isFetching: isLoading,
  } = useFind('communication', {
    person: subject,
    'sender.provider': providerId,
  }, {
    page: {
      number: page,
      size: pageSize,
    },
    queryConfig: {
      placeholderData: placeholderMessageResponse,
      enabled: Boolean(subject),
    },
  });

  const messages = get(messagesResponse, 'data.data', []);
  usePopulate('person', 'person', messages);
  usePopulate('sender', 'employee', messages);

  messages.forEach((m) => {
    const interactionDate = get(m, 'created_at');
    if (!interactionDate) { return; }
    m.interactionDate = getNoteDates(interactionDate);
    m.interactionType = '';
    m.Title = () => (
      <p>{ m.details.message_subject }</p>
    );
  });

  return {
    messages,
    response: messagesResponse,
    isLoading,
    page,
    pageSize,
    setPageSize: (val) => setPageSize(val),
    setPageNumber: (val) => setPage(val),
  };
};

export const useNotes = ({ subject, subjectType, providerId }) => {
  const [page, setPage] = useState(initialPagingState.page);
  const [pageSize, setPageSize] = useState(initialPagingState.size);
  const { data: notesResponse, isFetching: isLoading } = useFind('notes', {
    'subject.type': subjectType,
    subject,
    category: 'general,interaction,service_provided',
  }, {
    page: {
      number: page,
      size: pageSize,
    },
    context: {
      provider: providerId,
    },
    queryConfig: {
      enabled: Boolean(subject && subjectType && providerId),
    },
  });

  const { data: subjectResponse } = useFindRecord(
    subjectType,
    subject,
    { queryConfig: { enabled: true } },
  );
  const kase = subjectResponse.data.data;
  const caseId = get(kase, 'case.id', null);
  const notes = notesResponse.data.data;
  usePopulate('employee', 'employee', notes);
  usePopulate('employee.provider', 'provider', notes);
  const isReferral = subjectType === 'referral';

  const { data: caseRecord } = useFindRecord(
    'case',
    caseId,
    {
      queryConfig: {
        enabled: Boolean(isReferral && caseId),
      },
    },
  );
  kase.case = isEmpty(caseRecord) ? kase.case : get(caseRecord, 'data.data');
  let subjectName;
  switch (subjectType) {
    case 'case':
      subjectName = 'Case';
      break;
    case 'referral':
      subjectName = 'Referral';
      break;
    case 'assistance-request':
      subjectName = 'Assistance Request';
      break;
    default:
      subjectName = 'Case';
  }
  const serviceName = get(kase, 'case.service.name');
  populateNotesCard(notes, serviceName, subjectName);

  return {
    notes,
    isLoading,
    response: notesResponse,
    page,
    pageSize,
    setPageSize: (val) => setPageSize(val),
    setPageNumber: (val) => setPage(val),
  };
};

export const useOutcomeNotes = (item) => {
  let caseId;
  let providerId;
  if (item.case) {
    caseId = get(item, 'case.id', '');
    providerId = get(item, 'referred_to_group.id', '');
  } else {
    caseId = get(item, 'id', '');
    providerId = get(item, 'provider.id', '');
  }

  const { data: notesResponse } = useFind(
    'notes',
    {
      'subject.type': 'case',
      subject: caseId,
      'details.context': 'closed',
      category: 'general',
    },
    {
      queryConfig: {
        enabled: Boolean(caseId && providerId),
      },
    },
  );
  const notes = notesResponse.data.data;
  usePopulate('employee', 'employee', notes);
  usePopulate('employee.provider', 'provider', notes);
  return notes;
};

export const useReasonNote = (referral, providerId) => {
  const referralId = get(referral, 'id');
  const referralState = get(referral, 'state');
  const { data: notesResponse } = useFind('notes', {
    'subject.type': 'referral',
    subject: `${referralId}`,
    'details.context': referralState === 'in_review' ? 'held_in_review' : `${referralState}`,
    category: 'general',
  }, {
    queryConfig: {
      enabled: Boolean(referralId && providerId),
    },
  });
  const reasonNote = notesResponse.data.data[0];

  return reasonNote;
};
