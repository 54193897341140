import React from 'react';
import { PropTypes } from 'prop-types';
import { Card } from 'common/Card';
import { useFeatureFlag } from 'common/hooks';
import { get } from 'lodash';
import ClientDetailsSection from './ClientDetailsSection';
import EnrollmentDetailsCardHeader from './EnrollmentDetailsCardHeader';
import RequestDetailsSection from './RequestDetailsSection';
import SocialCareCoverageDetailsSection from './SocialCareCoverageDetailsSection';
import AssessmentFormsSection from './AssessmentFormsSection';
import SupportingDocumentsSection from './SupportingDocumentsSection';
import { REQUEST_TYPES } from '../../constants';
import { useFindEligibilityForInsurance } from '../../hooks/useFindEligibilityForInsurance';
import { NcTracksVerification } from '../NcTracks/NcTracksVerification';
import CardSubHeader from '../../../../common/Card/CardSubHeader';
import { useCurrentProviderId } from '../../../../common/contexts/CurrentProviderContext';
import { useFind } from '../../../../api/APIHooks';

const EnrollmentDetailsCard = ({
  enrollmentRequest,
  primaryInsurance,
}) => {
  const pays4252NcTracksVerification = useFeatureFlag(
      'pays-4252-temp-display-nctracks-verification-result-on-enrollment-request',
  );
  const pays6096UpdateEWQDesign = useFeatureFlag('pays-6096-update-ewq-design');

  const fetchEligibilityRecord = !!primaryInsurance?.id && pays4252NcTracksVerification;
  const eligibility = useFindEligibilityForInsurance(primaryInsurance, fetchEligibilityRecord);

  const showNcTracksVerification = !!eligibility?.id;

  const isDisenroll = enrollmentRequest.request_type === REQUEST_TYPES.disenroll;
  const providerId = useCurrentProviderId();
  const { data: clientRelationshipData } = useFind(
    'client_relationship',
    {
      person: enrollmentRequest?.person?.id,
      provider: providerId,
    },
    {
      queryConfig: {
        enabled: !!enrollmentRequest?.person?.id && !!providerId && pays6096UpdateEWQDesign,
        placeholderData: undefined,
      },
    },
  );

  const clientRelationships = get(clientRelationshipData, 'data.data', []);

  return (
    <div className="pt-4 flex-1 px-3 py-2 space-y-6">
      <Card className="mt-2 px-4 py-2 bg-white">
        <div className="p-4">
          <EnrollmentDetailsCardHeader
            enrollmentRequest={enrollmentRequest}
            label={isDisenroll ?
              'Disenrollment Request #' :
              'Enrollment Request #'}
          />
        </div>
        <hr className="pt-2 -mx-4 border-dark-border-blue" />
        <div className="grid grid-cols-2 divide-x divide-solid divide-dark-border-blue">
          <div className="inline-block py-4 space-y-4">
            <ClientDetailsSection
              showClientFacesheetLink={clientRelationships.length > 0}
              enrollmentRequest={enrollmentRequest}
              primaryInsurance={primaryInsurance}
            />
          </div>
          <div className="inline-block px-4 py-4 m-2 space-y-4">
            <RequestDetailsSection enrollmentRequest={enrollmentRequest} isDisenroll={isDisenroll} />
          </div>
        </div>
        <hr className="pt-2 -mx-4 border-dark-border-blue" />
        {
          showNcTracksVerification ? (
            <div className="grid grid-cols-2 divide-x divide-solid divide-dark-border-blue">
              <div className="inline-block py-4 space-y-4">
                <CardSubHeader>Insurance Verification</CardSubHeader>
                <NcTracksVerification eligibility={eligibility} />
              </div>

              <div className="inline-block px-4 py-4 m-2 space-y-4">
                <SocialCareCoverageDetailsSection enrollmentRequest={enrollmentRequest} />
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-2 divide-x divide-solid divide-dark-border-blue">
              <div className="inline-block py-4 space-y-4">
                <SocialCareCoverageDetailsSection enrollmentRequest={enrollmentRequest} />
              </div>
            </div>
          )
        }
      </Card>
      <div>
        <AssessmentFormsSection enrollmentRequest={enrollmentRequest} />
      </div>
      <div>
        <SupportingDocumentsSection enrollmentRequest={enrollmentRequest} />
      </div>
    </div>

  );
};

EnrollmentDetailsCard.propTypes = {
  enrollmentRequest: PropTypes.object.isRequired,
  primaryInsurance: PropTypes.object.isRequired,
};

export default EnrollmentDetailsCard;
