import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { useCurrentPayerId } from 'common/contexts/CurrentProviderContext';
import { useFind } from 'src/api/APIHooks';
import { Card } from 'src/common/Card';
import { Spinner } from 'src/common/spinners';
import { Drawer } from 'src/common/Drawer';
import {
  EnrollmentDrawerDetails,
  EnrollmentsTable,
  EnrollmentPagination,
} from '../components';
import getSort from '../utils/getSort';
import FilterSelectors from '../components/FilterSelectors';
import {
  useEnrollmentDrawer,
  useEnrollmentPagination,
  useEnrollmentFilters,
  useEnrollmentSorting,
} from '../hooks';
import NoEnrollmentsFoundCard from '../components/NoEnrollmentsMessage/NoEnrollmentsFoundCard';
import useWorkqueueRowSelector from '../../../common/utils/Workqueue/useWorkqueueRowSelector';
import useFeatureFlag from '../../../common/hooks/useFeatureFlag';
import AllFiltersDrawer from '../components/AllFiltersDrawer';
import { EnrollmentBulkActions } from '../components/EnrollmentBulkActions';

const getISOStringForNowMinusDays = (daysToSubtract = 0) => {
  const date = new Date();
  date.setMinutes(0, 0, 0);
  date.setDate(date.getDate() - daysToSubtract);
  return date.toISOString();
};

export const EnrollmentWorkqueue = ({
  availableBulkActions,
  enrollmentStatus,
  showEnrollmentAging,
  showSelectMultipleEnrollments,
}) => {
  const paginationProps = useEnrollmentPagination();
  const filterProps = useEnrollmentFilters();
  const currentPayerId = useCurrentPayerId();
  const sortProps = useEnrollmentSorting({ resetPagination: paginationProps.resetPagination });
  const pays6096UpdateEWQDesign = useFeatureFlag('pays-6096-update-ewq-design');
  const [openAllFiltersDrawer, setOpenAllFiltersDrawer] = useState(false);
  const { isLoading, data } = useFind(
    'enrollment_requests',
    {
      enrollment_status: enrollmentStatus,
      'plan.payer': currentPayerId,
      short_id: filterProps.filterValues.requestShortId,
      request_type: filterProps.filterValues.requestType,
      'person.query': filterProps.filterValues.clientName,
      plan: filterProps.filterValues.sccPlan,
      'insurance.primary_health_insurance.plan': filterProps.filterValues.insurancePlan,
      updated_after: parseInt(filterProps.filterValues.daysSinceLastUpdated, 10) ?
        getISOStringForNowMinusDays(parseInt(filterProps.filterValues.daysSinceLastUpdated, 10)) :
        undefined,
      'person.client_relationships.provider': filterProps.filterValues.sendingProvider,
      created_after: filterProps.filterValues.createdAfter,
      created_before: filterProps.filterValues.createdBefore,
    },
    {
      page: {
        number: paginationProps.pageNumber,
        size: paginationProps.pageSize,
      },
      queryConfig: {
        enabled: !!currentPayerId,
        placeholderData: undefined,
      },
      sort: getSort(sortProps),
    },
  );

  const enrollmentRequests = get(data, 'data.data', []);
  const totalItemCount = get(data, 'data.paging.total_count', 0);
  const totalPageCount = get(data, 'data.paging.total_pages', 0);
  const rowSelectorProps = useWorkqueueRowSelector({ pageRows: enrollmentRequests });

  const drawerProps = useEnrollmentDrawer({
    enrollmentRequests,
    pageNumber: paginationProps.pageNumber,
    pageSize: paginationProps.pageSize,
    setPageNumber: paginationProps.setPageNumber,
    totalPageCount,
  });

  const onUpdateFilter = useCallback(() => {
    paginationProps.resetPagination();
    rowSelectorProps.deselectAll();
  }, [paginationProps.resetPagination, rowSelectorProps.deselectAll]);

  const closeAllFiltersDrawer = () => {
    drawerProps.setOpen(false);
    setOpenAllFiltersDrawer(false);
  };

  const resetFiltersAndRefresh = () => {
    filterProps.resetFilters();
    onUpdateFilter();
  };

  return (
    <div className="divide-y divide-solid divide-dark-border-blue">
      {
        pays6096UpdateEWQDesign ? (
          <div className="single-card-ewq-container px-3 py-3" data-testid="updated-enrollment-workqueue-container">
            <Card className="bg-white py-10 pt-30 overflow-x-scroll">
              <h1
                className="text-text-blue font-extrabold font-heavy-font text-2xl mb-6 px-8 pb-5"
              >
                Enrollment Requests
              </h1>
              <div className="px-8 flex flex-row">
                {
                  showSelectMultipleEnrollments && (
                    <EnrollmentBulkActions
                      bulkActions={availableBulkActions}
                      deselectAll={rowSelectorProps.deselectAll}
                      deselectRows={rowSelectorProps.deselectRows}
                      selectedRows={rowSelectorProps.selectedRows}
                    />
                  )
                }
                <FilterSelectors
                  filterValues={filterProps.filterValues}
                  filterOptions={filterProps.filterOptions}
                  resetFilters={filterProps.resetFilters}
                  onUpdateFilter={onUpdateFilter}
                  setRequestTypeFilter={filterProps.setRequestTypeFilter}
                  setSccPlanFilter={filterProps.setSccPlanFilter}
                  setDaysSinceLastUpdatedFilter={filterProps.setDaysSinceLastUpdatedFilter}
                  setClientNameFilter={filterProps.setClientNameFilter}
                  setOpenAllFiltersDrawer={setOpenAllFiltersDrawer}
                />
              </div>
              <div className="px-0 py-4">
                {/* eslint-disable no-nested-ternary */}
                {isLoading ? (
                  <div role="alert"><Spinner /></div>
                ) : (
                  !isEmpty(enrollmentRequests) ? (
                    <>
                      <div className="py-5">
                        <EnrollmentsTable
                          enrollmentRequests={enrollmentRequests}
                          openDrawer={drawerProps.openDrawer}
                          showEnrollmentAging={showEnrollmentAging}
                          showSelectMultipleEnrollments={showSelectMultipleEnrollments}
                          toggleSelectedRow={rowSelectorProps.toggleSelectedRow}
                          toggleSelectAllRows={rowSelectorProps.toggleSelectAllRows}
                          selectedRows={rowSelectorProps.selectedRows}
                          {...sortProps}
                        />
                      </div>
                      <div className="px-6">
                        <EnrollmentPagination
                          {...paginationProps}
                          totalItemCount={totalItemCount}
                          totalPageCount={totalPageCount}
                        />
                      </div>
                    </>
                  ) : (
                    <NoEnrollmentsFoundCard
                      resetFilters={resetFiltersAndRefresh}
                      isFiltered={filterProps.isFiltered}
                      status={enrollmentStatus}
                    />
                  ))}
              </div>
              <Drawer open={drawerProps.open} setOpen={drawerProps.setOpen}>
                {isLoading || !drawerProps.clickedRequest ? (
                  <div className="pt-8" role="alert"><Spinner /></div>
                ) : (
                  <EnrollmentDrawerDetails
                    getNextRequest={drawerProps.getNextRequest}
                    getPrevRequest={drawerProps.getPrevRequest}
                    enrollmentRequest={drawerProps.clickedRequest}
                    setDrawerOpen={drawerProps.setOpen}
                  />
                )}
              </Drawer>
              {
                openAllFiltersDrawer && (
                  <Drawer open={openAllFiltersDrawer} setOpen={setOpenAllFiltersDrawer}>
                    {
                      <AllFiltersDrawer
                        {...filterProps}
                        onSuccess={closeAllFiltersDrawer}
                        onUpdateFilter={onUpdateFilter}
                        enrollmentStatus={enrollmentStatus}
                      />
                    }
                  </Drawer>
                )
              }
            </Card>
          </div>
        ) : (
          <>
            <div className="original-ewq-container p-6 pt-8 bg-white" data-testid="enrollment-workqueue-container">
              <h1 className="text-text-blue font-medium-font text-2xl">Enrollment Requests</h1>
            </div>
            <div className="bg-light-border-grey px-6 py-4">
              <FilterSelectors
                filterValues={filterProps.filterValues}
                filterOptions={filterProps.filterOptions}
                resetFilters={filterProps.resetFilters}
                onUpdateFilter={() => paginationProps.resetPagination()}
                setRequestTypeFilter={filterProps.setRequestTypeFilter}
                setSccPlanFilter={filterProps.setSccPlanFilter}
                setDaysSinceLastUpdatedFilter={filterProps.setDaysSinceLastUpdatedFilter}
                setOpenAllFiltersDrawer={setOpenAllFiltersDrawer}
              />
            </div>
            <div className="px-6 py-4">
              {/* eslint-disable no-nested-ternary */}
              {isLoading ? (
                <div role="alert"><Spinner /></div>
              ) : (
                !isEmpty(enrollmentRequests) ? (
                  <>
                    <Card className="mb-6">
                      <EnrollmentsTable
                        enrollmentRequests={enrollmentRequests}
                        openDrawer={drawerProps.openDrawer}
                        showEnrollmentAging={showEnrollmentAging}
                        {...sortProps}
                      />
                    </Card>
                    <EnrollmentPagination
                      {...paginationProps}
                      totalItemCount={totalItemCount}
                      totalPageCount={totalPageCount}
                    />
                  </>
              ) : (
                <NoEnrollmentsFoundCard
                  resetFilters={resetFiltersAndRefresh}
                  isFiltered={filterProps.isFiltered}
                  status={enrollmentStatus}
                />
                )
              )}
              {/* eslint-enable no-nested-ternary */}
              <Drawer open={drawerProps.open} setOpen={drawerProps.setOpen}>
                {isLoading || !drawerProps.clickedRequest ? (
                  <div className="pt-8" role="alert"><Spinner /></div>
                ) : (
                  <EnrollmentDrawerDetails
                    getNextRequest={drawerProps.getNextRequest}
                    getPrevRequest={drawerProps.getPrevRequest}
                    enrollmentRequest={drawerProps.clickedRequest}
                    setDrawerOpen={drawerProps.setOpen}
                  />
                )}
              </Drawer>
            </div>
          </>
        )
      }
    </div>
  );
};

EnrollmentWorkqueue.propTypes = {
  availableBulkActions: PropTypes.array,
  enrollmentStatus: PropTypes.string.isRequired,
  showEnrollmentAging: PropTypes.bool,
  showSelectMultipleEnrollments: PropTypes.bool,
  statusOptionsConstant: PropTypes.string,
};

EnrollmentWorkqueue.defaultProps = {
  availableBulkActions: [],
  showEnrollmentAging: false,
  showSelectMultipleEnrollments: false,
  statusOptionsConstant: null,
};

export default EnrollmentWorkqueue;
