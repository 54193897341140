import { isEmpty } from 'lodash';
import { coreApi } from 'src/api/config';
import fetchClientRelationship from './fetchClientRelationship';

const findOrCreateClientRelationship = async (contactId, groupId) => {
  const relationship = await fetchClientRelationship(contactId, groupId);

  if (isEmpty(relationship)) {
    await coreApi.createRecord('client_relationship', {
      person: contactId,
      provider: groupId,
    });
  }
};

export default findOrCreateClientRelationship;
