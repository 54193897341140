import React from 'react';
import { PropTypes } from 'prop-types';
import { CardDetail, CardSubHeader } from 'common/Card';
import { get, isEmpty } from 'lodash';
import { epochToDate } from 'common/utils/utils';
import { useFeatureFlag } from 'common/hooks';
import { Link } from 'react-router';
import { useInsuranceExternalMemberID } from '../../hooks';

const ClientDetailsSection = ({
  enrollmentRequest,
  primaryInsurance,
  showClientFacesheetLink,
}) => {
  const linkSccToHealthInsuranceFlag = useFeatureFlag('pays-2199-temp-link-scc-to-health-insurance');

  const { externalMemberID: payerExternalMemberID } = useInsuranceExternalMemberID(
    enrollmentRequest?.person?.id,
    enrollmentRequest?.plan?.payer?.id,
    linkSccToHealthInsuranceFlag,
  );
  const externalMemberID = linkSccToHealthInsuranceFlag ? primaryInsurance?.external_member_id : payerExternalMemberID;

  return (
    <>
      <CardSubHeader>Client Details</CardSubHeader>
      <CardDetail label="Client Name">
        {
          showClientFacesheetLink ? (
            <Link
              className="text-action-blue"
              to={`/facesheet/${enrollmentRequest.person.id}`}
            >
              {get(enrollmentRequest, 'person.last_name')}, {get(enrollmentRequest, 'person.first_name')}
            </Link>
          ) : (
            <>{get(enrollmentRequest, 'person.last_name')}, {get(enrollmentRequest, 'person.first_name')}</>
          )
        }
      </CardDetail>
      {(!linkSccToHealthInsuranceFlag || externalMemberID) && (
        <CardDetail label="Client ID#">
          {isEmpty(externalMemberID) ? 'No Client ID' : externalMemberID}
        </CardDetail>
      )}
      {(linkSccToHealthInsuranceFlag && primaryInsurance) && (
        <CardDetail label="Insurance Plan">
          {primaryInsurance.plan?.name}
        </CardDetail>
      )}
      <CardDetail label="Date of Birth">{epochToDate(get(enrollmentRequest, 'person.date_of_birth'))}</CardDetail>
    </>
  );
};

ClientDetailsSection.propTypes = {
  enrollmentRequest: PropTypes.object.isRequired,
  primaryInsurance: PropTypes.object.isRequired,
  showClientFacesheetLink: PropTypes.bool.isRequired,
};

export default ClientDetailsSection;
