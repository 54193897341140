import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'common/utils/browserHistory';
import { validateReduxForm } from 'common/form';
import {
  Button,
  BaseCard,
  BaseCardBody,
  CheckBoxField,
} from '@unite-us/ui';
import { UULogoWithText } from '@unite-us/client-utils';
import { Eula } from '@unite-us/app-components';
import { acceptEulaAgreement } from 'actions/Agreement';
import callOrLog from 'src/common/utils/callOrLog';
import { LOGIN } from 'common/utils/EventTracker/utils/eventConstants';
import { isSuccess } from 'common/utils/Notifications/utils';
import { get } from 'lodash';

const fields = [
  'eulaAccepted',
];

const EulaSections = [
  {
    header: null,
    subContent: [
      {
        title: null,
        content: (
          <p>
            By accessing or using this software (the{' '}
            <span className="defined-term">&ldquo;Unite Us Platform&rdquo;</span>
            ), you agree to the terms and conditions of this end-user-license
            agreement (the{' '}
            <span className="defined-term">&ldquo;EULA&rdquo;</span>). If you do
            not agree to any terms and conditions in the EULA, do not access or
            use the Unite Us Platform or any related materials or documentation,
            and immediately return to Unite Us all materials and documentation
            provided by Unite Us.
          </p>
        ),
      },
      {
        title: null,
        content: (
          <p>
            The EULA may be modified by Unite Us at any time with or without
            notice. Any such modification will be effective immediately upon
            public posting. Your continued use of the Unite Us Platform
            following any such modification by Unite Us constitutes your
            acceptance of the modified terms.
          </p>
        ),
      },
    ],
  },
  {
    header: 'YOUR USE OF THE UNITE US PLATFORM',
    subSections: [
      {
        title: 'Unite Us Intellectual Property Rights.',
        content: (
          <span>
            As between Unite Us and you, Unite Us and its licensors own and
            shall retain all intellectual property rights in and to the Unite Us
            Platform and any services, materials, documentation and
            infrastructure used to provide the Unite Us Platform. If you provide
            any feedback to Unite Us concerning the functionality and
            performance of the Unite Us Platform (including identifying
            potential errors or improvements), you hereby assign to Unite Us all
            right, title and interest in and to the feedback and Unite Us is
            free to use such feedback without payment or restriction. You may
            use the Unite Us Platform solely as permitted by the Governing
            Documents. For purposes of this EULA, the{' '}
            <span className="defined-term">&ldquo;Governing Documents&rdquo;</span>{' '}
            means (a) the master agreement and statement of work with Unite Us
            (if applicable) and (b) the Network Participation Terms and
            Conditions, in each case as agreed to by the entity with whom you
            are affiliated (such entity, the{' '}
            <span className="defined-term">
              &ldquo;Affiliated Network Participant&rdquo;
            </span>
            ).
          </span>
        ),
      },
      {
        title: 'Unauthorized Use.',
        content: (
          <span>
            Unite Us reserves the right to refuse registration of, or to cancel,
            login IDs that violate this EULA. You agree to notify Unite Us
            immediately upon learning of any unauthorized use of your account.
          </span>
        ),
      },
      {
        title: 'Platform Restrictions.',
        content: (
          <span>
            You may not and may not permit third parties to (a) sell, assign,
            sublicense or otherwise transfer the Unite Us Platform to third parties;
            (b) resell the Unite Us Platform to any third party; (c) use the Unite Us Platform
            to provide or perform service bureau processing, or hosting services for any third party;
            (d) otherwise use the Unite Us Platform for the benefit of any third party;
            (e) disassemble, decompile, reverse engineer or use any other means to attempt
            to discover any source code of the Unite Us Platform, or the underlying ideas, algorithms
            or trade secrets therein; (f) use the Unite Us Platform to knowingly transmit malware,
            spam or other unsolicited emails in violation of applicable law, or to post or send any unlawful,
            threatening, harassing, racist, abusive, libelous, pornographic, defamatory, obscene, or other similarly
            inappropriate content; (g) remove any copyright notice, trademark notice or other proprietary
            legend set forth on or contained within any of the documentation or other materials
            provided by Unite Us; (h) use automated tools, scripts, bots, artificial intelligence tools,
            or other software not expressly permitted in writing for use by Unite Us in connection with the
            Unite Us Platform; or (i) otherwise use the Unite Us Platform or any data accessed therein in
            violation of any applicable law.
          </span>
        ),
      },
      {
        title: 'Data Restrictions.',
        content: (
          <span>
            You may submit or upload personally identifiable information
            (including protected health information) (collectively,{' '}
            <span className="defined-term">&ldquo;PII&rdquo;</span>) to Unite Us
            in the course of using the Unite Us Platform only if (a) disclosure
            of such PII is necessary for permitted exploitation of the Unite Us
            Platform and services provided by Unite Us; (b) you or the
            Affiliated Network Participant has all consents, rights and
            authorizations necessary to provide Unite Us with such PII; (c) such
            PII is collected and disclosed to Unite Us pursuant to and in
            accordance with the applicable privacy policies of the Affiliated
            Network Participant and (d) your provision of such PII to Unite Us
            and Unite Us&apos; retention and use of such PII as contemplated under
            this EULA does not and will not violate any applicable privacy
            policy of the Affiliated Network Participant or any applicable laws.
          </span>
        ),
      },
    ],
  },
  {
    header: 'UNITE US RESPONSIBILITIES',
    subSections: [
      {
        title: 'Training and Support.',
        content: (
          <span>
            Unite Us shall maintain the Unite Us Platform and provide technical
            and advisory training and support to the Affiliated Network
            Participant in connection with its use of the Unite Us Platform.
            Unite Us shall determine the scope and frequency of such training
            and support in its sole and reasonable discretion.
          </span>
        ),
      },
    ],
  },
  {
    header: 'SECURITY',
    subSections: [
      {
        title: 'Security.',
        content: (
          <span>
            The Unite Us Platform may contain technological measures designed to
            prevent unauthorized or illegal use. You agree that Unite Us may use
            these measures to verify your compliance with the terms of this EULA
            and enforce Unite Us&apos; rights, including all intellectual property
            rights, in and to the Unite Us Platform and any materials or
            documentation provided by Unite Us. Unite Us may collect, maintain,
            process and use diagnostic, technical, usage, and related
            information to improve the performance or effectiveness of the Unite
            Us Platform.
          </span>
        ),
      },
      {
        title: 'Security Incident Reporting.',
        content: (
          <span>
            In the event that you (a) discover or are notified of any act or
            omission that materially compromises the security, confidentiality
            or integrity of any data on the Unite Us Platform or any physical,
            technical, administrative or organizational safeguards that relate
            to the security, confidentiality or integrity of such data or (b)
            receive a complaint in relation to the Affiliated Network
            Participant&apos;s privacy practices or notice of a breach or alleged
            breach of this EULA in connection with such privacy practices
            (collectively, a{' '}
            <span className="defined-term">&ldquo;Security Incident&rdquo;</span>
            ), you shall notify Unite Us of such Security Incident by sending
            an email to{' '}
            <a
              className="eula-content__link"
              href="mailto:compliance@uniteus.com"
              title="compliance@uniteus.com"
            >
              compliance@uniteus.com
            </a>{' '}
            with detailed information regarding the Security Incident as soon
            as possible, but in any event no later than twenty four (24) hours
            from the discovery of facts giving rise to such knowledge or belief
            and cooperate with Unite Us in the remediation of such Security
            Incident.
          </span>
        ),
      },
    ],
  },
  {
    header: 'CONFIDENTIALITY',
    subSections: [
      {
        title: 'Confidentiality.',
        content: (
          <span>
            Each party agrees (a) to protect the disclosing party&apos;s confidential
            information from unauthorized dissemination and use; (b) to use the
            disclosing party&apos;s confidential information only for the performance
            of the receiving party&apos;s obligations and in connection with the
            exercise of the receiving party&apos;s rights hereunder; (c) to disclose
            any confidential information only to those of its employees, agents,
            or contractors who have a need to know for the performance of their
            duties and who are bound to comply with confidentiality obligations
            no less restrictive than the requirements set forth in this section;
            (d) not to disclose or otherwise provide to any third party, without
            the prior written consent of the disclosing party, any confidential
            information or any part or parts thereof; (e) to undertake whatever
            action is necessary to prevent or remedy (or authorize the
            disclosing party to do so in the name of the receiving party) any
            breach of the receiving party&apos;s confidentiality obligations set
            forth herein or any other unauthorized disclosure of any
            confidential information by its current or former employees, agents,
            or contractors; and (f) not to remove or destroy any proprietary or
            confidential legends or markings placed upon or contained within any
            confidential information.
          </span>
        ),
      },
      {
        title: 'Legally Compelled Disclosures.',
        content: (
          <span>
            Notwithstanding the restrictions on the use and disclosure of
            confidential information set forth herein, the receiving party may
            use or disclose confidential information to the extent the receiving
            party is legally compelled to disclose such confidential
            information; provided, however, prior to any such compelled
            disclosure the receiving party shall (to the extent allowed under
            applicable law) notify the disclosing party and cooperate fully with
            the disclosing party in protecting against any such disclosure, and
            if applicable, obtaining a protective order narrowing the scope of
            such disclosure and use of the confidential information.
          </span>
        ),
      },
      {
        title: 'Equitable Relief.',
        content: (
          <span>
            Each party acknowledges and agrees that, due to the unique nature of
            such confidential information, there may be no adequate remedy at
            law to compensate the disclosing party for the breach of the
            confidentiality obligations set forth herein; that any such breach
            may result in irreparable harm to the disclosing party that would be
            difficult to measure; and, therefore, that upon any such breach or
            threat thereof, the disclosing party shall be entitled to seek
            injunctive and other appropriate equitable relief (without the
            necessity of posting a bond), in addition to whatever remedies it
            may have at law, under this EULA, or otherwise.
          </span>
        ),
      },
    ],
  },
  {
    header: 'WARRANTY DISCLAIMER',
    subSections: [
      {
        title: null,
        content: (
          <p>
            YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE UNITE US
            PLATFORM AND SERVICES PROVIDED BY UNITE US ARE AT YOUR SOLE RISK AND
            THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE,
            ACCURACY AND EFFORT IS WITH YOU. THE UNITE US PLATFORM AND SERVICES
            ARE PROVIDED &apos;AS IS&apos; AND, TO THE MAXIMUM EXTENT PERMITTED UNDER
            APPLICABLE LAW, UNITE US EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER
            EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, WITH RESPECT TO THE UNITE
            US PLATFORM (INCLUDING ALL THIRD PARTY AND OPEN-SOURCE COMPONENTS),
            DOCUMENTATION, DATA, MEDIA AND ANY OTHER SERVICES AND MATERIALS
            PROVIDED TO YOU UNDER THIS EULA, INCLUDING ALL IMPLIED WARRANTIES OF
            MERCHANTABILITY, QUALITY, FITNESS FOR A PARTICULAR PURPOSE,
            NON-INFRINGEMENT, AND WARRANTIES ARISING FROM A COURSE OF DEALING,
            USAGE OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, UNITE US
            PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF
            ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, THAT THE
            UNITE US PLATFORM OR SERVICES PROVIDED BY UNITE US WILL MEET YOUR
            REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR WORK
            WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE
            WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS
            OR BE ERROR FREE.
          </p>
        ),
      },
    ],
  },
  {
    header: 'LIMITATION OF LIABILITY',
    subSections: [
      {
        title: null,
        content: (
          <p>
            IN NO EVENT WILL UNITE US OR ANY OF ITS LICENSORS, PARTNERS OR
            REPRESENTATIVES BE LIABLE UNDER THIS EULA TO YOU OR ANY THIRD PARTY
            FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR
            PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO ANY DAMAGES FOR
            BUSINESS INTERRUPTION, INTERRUPTIONS IN THE UNITE US PLATFORM, UNITE
            US WEBSITE, OR UNITE US SERVICES, LOSS OF USE, DATA, REVENUE OR
            PROFIT, WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT (INCLUDING
            NEGLIGENCE) OR OTHERWISE, OR ACCURACY OR COMPLETENESS OF ANY DATA
            CONTAINED IN OR ACCESSIBLE VIA THE UNITE US PLATFORM OR UNITE US
            WEBSITE, REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND
            WHETHER UNITE US WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN
            NO EVENT WILL UNITE US&apos; COLLECTIVE AGGREGATE LIABILITY UNDER OR IN
            CONNECTION WITH THIS EULA OR ITS SUBJECT MATTER, UNDER ANY LEGAL OR
            EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
            NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE, EXCEED $1,000. THE
            FOREGOING LIMITATIONS SHALL APPLY EVEN IF YOUR REMEDIES UNDER THIS
            EULA FAIL OF THEIR ESSENTIAL PURPOSE.
          </p>
        ),
      },
    ],
  },
  {
    header: 'TERMINATION',
    subSections: [
      {
        title: 'Termination.',
        content: (
          <span>
            Without prejudice to any payment obligations set forth in the
            Governing Documents, Unite Us may immediately terminate your access
            to the Unite Us Platform (a) in the event that you materially breach
            any term or condition of this EULA or (b) in connection with any
            termination of any of the Governing Documents. In the event of any
            such termination or the termination of this EULA, you shall
            immediately cease use of the Unite Us Platform and return or destroy
            all materials and documentation provided by Unite Us. Unite Us
            further reserves the right to block, terminate, or otherwise deny
            you access to the Unite Us Platform, or remove any material from the
            Unite Us Platform, in the event that Unite Us believes in good faith
            that such access or material violates the copyright, privacy,
            trademark, or publicity rights of any person or party, or otherwise
            violates applicable law.
          </span>
        ),
      },
    ],
  },
  {
    header: 'GENERAL TERMS',
    subSections: [
      {
        title: 'Nondiscrimination Policy.',
        content: (
          <span>
            You shall not discriminate as to race, color, age, religion, sex,
            national origin, socioeconomic status, sexual orientation, gender
            identity or expression, disability, veteran status, or source of
            payment in connection with your use of the Unite Us Platform.
          </span>
        ),
      },
      {
        title: 'Force Majeure.',
        content: (
          <span>
            In the event that a party is prevented from performing, or is unable
            to perform, any of its obligations under this EULA due to any cause
            beyond its reasonable control, the affected party shall give written
            notice thereof to the other party and its performance shall be
            extended for the period of delay or inability to perform due to such
            occurrence.
          </span>
        ),
      },
      {
        title: 'Assignment.',
        content: (
          <span>
            You may not assign or transfer any of your rights under this EULA,
            either voluntarily or by operation of law, without Unite Us&apos; prior
            written consent.
          </span>
      ),
    },
    {
      title: 'Severability.',
      content: (
        <span>
          Any provision of this EULA that is deemed invalid, void or illegal
          shall in no way affect, impair, or invalidate any other provisions
          set forth herein, and such other provisions shall remain in full
          force and effect.
        </span>
      ),
    },
  ],
  },
];

export class EulaAgreement extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    return this.props.acceptEulaAgreement()
      .then((response) => {
        if (isSuccess(get(response, 'status'))) {
          callOrLog(() => this.context.eventTracker(LOGIN.acceptEulaAgreement));
          if (!this.props.noRedirect) {
            browserHistory.push('/user/settings');
          }
        }
      });
  }

  render() {
    const {
      fields: {
        eulaAccepted,
      },
      handleSubmit,
      submitting,
    } = this.props;

    const allTermsAccepted = eulaAccepted.value;
    const checkBoxLabelText = `I hereby acknowledge that I have read, understand, 
    and agree to the End User License Agreement above.`;

    return (
      <div className="eula-agreement container">
        <div className="eula-agreement__logo">
          <UULogoWithText />
        </div>
        <div className="eula-agreement__content">
          <BaseCard>
            <BaseCardBody withPadding>
              <Eula sections={EulaSections} />
              <form
                className="eula-confirmation"
                onSubmit={handleSubmit(this.onSubmit)}
              >
                <CheckBoxField
                  field={eulaAccepted}
                  id="eulaAccepted"
                  label={checkBoxLabelText}
                  showError={false}
                  showHint={false}
                />
                <Button
                  disabled={!allTermsAccepted || submitting}
                  id="eula-agree-btn"
                  label="I Agree"
                  onClick={handleSubmit(this.onSubmit)}
                  primary
                />
              </form>
            </BaseCardBody>
          </BaseCard>
        </div>
      </div>
    );
  }
}

EulaAgreement.propTypes = {
  acceptEulaAgreement: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  noRedirect: PropTypes.bool,
};

EulaAgreement.defaultProps = {
  noRedirect: false,
};

EulaAgreement.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default validateReduxForm(
  {
    form: 'eula-confirmation',
    fields,
    destroyOnUnmount: true,
  },
  null,
  { acceptEulaAgreement },
)(EulaAgreement);
