import React, { useContext, useMemo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  concat,
  uniq,
  omitBy,
  isEmpty,
} from 'lodash';
import {
  InputField,
  BaseCard,
  BaseCardHeader,
  BaseCardBody,
} from '@unite-us/ui';
import $scripts from 'scriptjs';
import { requiredMapScript } from 'src/components/Browse/Map/utils';
import { serviceAreaSupportForOrgs, orgAdminProgramStatusNA, orgAdminDeactivatedPrograms, orgAdminEnhancements } from 'src/common/utils/FeatureFlags/flags';
import fetchStatesGeography from 'src/actions/Geography/fetchStatesGeography';
import {
  validations,
  TrackerContext,
  ageRangeConverters,
} from '@unite-us/client-utils';
import statesEnums from 'src/reducers/constants/serviceAreasStates';
import fetchServices from 'src/common/utils/Session/actions/fetchServices';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Spinner } from 'src/common/spinners';
import { useFindProvider } from 'src/components/Organization/api/hooks/v1';
import someEligibilityExists from 'src/components/Organization/utils/someProgramEligibilityExists';
import { ORG_SETTINGS } from 'src/common/utils/EventTracker/utils/eventConstants';
import DialogV2 from 'common/modal/DialogV2';
import ButtonFormSubmit from '../ButtonFormSubmit';
import PhoneNumbersField from '../PhoneNumbersField';
import EmailAddressesField from '../EmailAddressesField';
import ProgramInfoForm from './ProgramInfoForm';
import ProgramAccessForm from './ProgramAccessForm';
import EligibilityFormV2 from './EligibilityFormV2';
import EligibilityFormV2Deprecated from './EligibilityFormV2Deprecated';
import ProgramLocationsForm from './ProgramLocationsForm';
import AccessibilityFormV2 from './AccessibilityFormV2';
import ServiceAreasForm from './ServiceAreasForm';
import {
  PERMISSION_CATEGORIES,
  getProgramPermissions,
} from '../../utils/getProgramPermissions';
import './index.scss';

const {
  convertApiAgeRangeToUnits, convertAgeToApiAgeRange,
} = ageRangeConverters;

const stateCodes = statesEnums.map((state) => state.value);
// FIXED service types should never be shown, but their values
// should be maintained through updates.
const FIXED_SERVICE_TYPES = [
  PERMISSION_CATEGORIES.UU_PAYMENTS,
];

// DEPRECATED service types should only be shown if the program
// already has that service type.
const DEPRECATED_SERVICE_TYPES = [
  PERMISSION_CATEGORIES.UU_HEALTH,
];

export const ProgramForm = (props) => {
  const {
    initialValues,
    onSubmit,
    serviceTypes,
    currentProviderId,
    serviceAreaSupportForOrgs: serviceAreaSupportForOrgsFlag,
    orgAdminProgramStatusNA: orgAdminProgramStatusNAFlag,
    orgAdminDeactivatedPrograms: orgAdminDeactivatedProgramsFlag,
    orgAdminEnhancements: orgAdminEnhancementsFlag,
    fetchLocations,
    fetchServiceTypes,
  } = props;
  const sensitiveServiceRef = useRef(null);
  const [scriptsLoaded, setScriptsLoaded] = useState(window.google && window.google.maps);
  const [sensitiveServiceRemoved, setSensitiveServiceRemoved] = useState(false);
  // Let's check to make sure that the google maps script is loaded
  if (!(window.google && window.google.maps)) {
    $scripts(requiredMapScript.scripts, () => {
      setScriptsLoaded(true);
    });
  }

  useEffect(() => {
    fetchLocations(stateCodes, 'places');
    fetchServiceTypes();
  }, []);

  const eventTracker = useContext(TrackerContext);
  const defaultValues = { ...initialValues };
  const defaultToEnglish = !defaultValues?.languages?.length;
  if (defaultToEnglish) {
    defaultValues.languages = ['en'];
  }

  if (defaultValues.age_requirements) {
    const [min_age, min_age_units, max_age, max_age_units] =
      convertApiAgeRangeToUnits(defaultValues.age_requirements);

    defaultValues.age_req_min = min_age;
    defaultValues.age_req_min_units = min_age_units;
    defaultValues.age_req_max = max_age;
    defaultValues.age_req_max_units = max_age_units;
  }

  if (defaultValues.age_specializations) {
    const [min_age, min_age_units, max_age, max_age_units] =
      convertApiAgeRangeToUnits(defaultValues.age_specializations);

    defaultValues.age_spec_min = min_age;
    defaultValues.age_spec_min_units = min_age_units;
    defaultValues.age_spec_max = max_age;
    defaultValues.age_spec_max_units = max_age_units;
  }

  if (defaultValues.state_requirements?.length) {
    defaultValues.state_reqs = defaultValues.state_requirements.map((x) => x.id);
  }

  if (defaultValues.city_requirements?.length) {
    defaultValues.city_reqs = defaultValues.city_requirements.map((x) => x.id);
    defaultValues.city_requirements = defaultValues.city_requirements.map(
      (city) => ({ value: city.id, display_name: `${city.name}, ${city.state}`, label: city.name }),
    );
  }

  if (defaultValues.county_requirements?.length) {
    defaultValues.county_reqs = defaultValues.county_requirements.map((x) => x.id);
    defaultValues.county_requirements = defaultValues.county_requirements.map(
      (county) => ({ value: county.id, display_name: `${county.name}, ${county.state}`, label: county.name }),
    );
  }

  if (defaultValues.postal_code_requirements?.length) {
    defaultValues.postal_code_reqs = defaultValues.postal_code_requirements.map((x) => x.id);
    defaultValues.postal_code_requirements = defaultValues.postal_code_requirements.map(
      (postal_code) => (
        { value: postal_code.id, display_name: postal_code.postal_code, label: postal_code.postal_code }
      ),
    );
  }

  if (orgAdminProgramStatusNAFlag && defaultValues.status === 'Temporarily Not Available') {
    defaultValues.status = undefined;
  }

  const initialServiceTypes = initialValues?.services || [];
  const activeServiceTypes = useMemo(() => {
    const initialServiceTypeCodes = initialServiceTypes?.map((st) => st.code);
    return omitBy(serviceTypes, (st) => (
      DEPRECATED_SERVICE_TYPES.includes(st.code) && !initialServiceTypeCodes?.includes(st.code)
    ));
  }, [initialServiceTypes, serviceTypes]);

  const trueSubmit = (values) => {
    eventTracker(ORG_SETTINGS.progSaved);
    const fixedServicesToMaintain = initialServiceTypes?.filter((st) => (
      FIXED_SERVICE_TYPES.includes(st.code) ||
      FIXED_SERVICE_TYPES.includes(serviceTypes[st.parent?.id]?.code)
    ));
    const selectedServices = uniq(concat((values?.services || []), fixedServicesToMaintain).reduce((acc, service) => {
      const parent = service.parent?.id ? service.parent.id : service.parent;
      return [
        ...acc,
        service.id,
        parent,
      ];
    }, []));

    const receiving_referrals = values.status === 'Accepting Referrals';

    const objectToArray = (object) => Object.keys(object).map((key) => (object[key] ? key : null));

    const eligibilityOverrides = values.no_eligibility_requirements ?
      {
        eligibility_text: null,
        population_restrictions: [],
        requirements: [],
        age_requirements: null,
        age_specializations: null,
        states: [],
        counties: [],
        cities: [],
        postal_code_requirements: [],
        state_requirements: [],
        county_requirements: [],
        city_requirements: [],
      } :
      {};

    // These clears are for removing values that do not need to be sent to the programs endpoint
    // as they are only used temporarily for the front end
    const ageValueClear = {
      age_req_min: undefined,
      age_req_min_units: undefined,
      age_req_max: undefined,
      age_req_max_units: undefined,
      age_spec_min: undefined,
      age_spec_min_units: undefined,
      age_spec_max: undefined,
      age_spec_max_units: undefined,
    };

    const locReqClear = {
      state_reqs: undefined,
      postal_code_reqs: undefined,
      county_reqs: undefined,
      city_reqs: undefined,
    };

    const requirementsClear = {
      genders: undefined,
    };

    // We no longer update cities, states, and counties, so we take them out of the OnSubmit below
    // If and when these fields are deprecated, this code can be removed (CP 6/29/23)
    const oldLocDataClear = {
      cities: undefined,
      states: undefined,
      counties: undefined,
    };

    const genderReqs = values.genders?.map((opt) => ({
      type: 'program_requirement_option',
      id: opt.value ? opt.value : opt,
    }));

    onSubmit({
      ...values,
      services: selectedServices,
      requirements: [...(genderReqs ?? [])],
      permissions: getProgramPermissions({ serviceTypes: activeServiceTypes, selectedServices })
        .map((permission) => ({ id: permission })),
      accessibility_options: values.accessibility_options,
      delivery_options: objectToArray(values.delivery_options),
      email_addresses: values.email_addresses?.map(
        (email) => Object.values(email)[0],
      ) ?? [],
      age_requirements: convertAgeToApiAgeRange(
        values.age_req_min,
        values.age_req_min_units,
        values.age_req_max,
        values.age_req_max_units,
      ),
      age_specializations: convertAgeToApiAgeRange(
        values.age_spec_min,
        values.age_spec_min_units,
        values.age_spec_max,
        values.age_spec_max_units,
      ),
      phone_numbers: values.phone_numbers?.filter((phoneNumber) => Object.values(phoneNumber).length) ?? [],
      payment_options: values.payment_options,
      postal_code_requirements: values.postal_code_reqs?.map((p) => ({ id: p, type: 'program_postal_code' })),
      state_requirements: values.state_reqs,
      county_requirements: values.county_reqs?.map((p) => ({ id: p, type: 'program_county' })),
      city_requirements: values.city_reqs?.map((p) => ({ id: p, type: 'program_city' })),
      receiving_referrals,
      status: receiving_referrals ? '' : values.status,
      transportation_options: values.transportation_options,
      ...eligibilityOverrides,
      ...ageValueClear,
      ...locReqClear,
      ...requirementsClear,
      ...oldLocDataClear,
    });
  };

  const openDialog = () => {
    sensitiveServiceRef.current.openDialog();
  };

  const closeDialog = (values) => () => {
    sensitiveServiceRef.current.closeDialog();
    trueSubmit(values);
  };

  const cancelDeleteService = (form, values) => () => {
    form.mutators.resetRemovedSensitiveServices([...values.services, ...sensitiveServiceRemoved]);
    sensitiveServiceRef.current.closeDialog();
  };

  const onSubmitForm = (values) => {
    const remainingServiceIds = values?.services?.map((service) => service.id) ?? [];
    const removedSensitiveServices = initialValues?.services
      ?.filter((service) => !remainingServiceIds.includes(service.id) && service.sensitive);

    if (removedSensitiveServices?.length) {
      setSensitiveServiceRemoved(removedSensitiveServices);
      openDialog();
    } else {
      setSensitiveServiceRemoved(undefined);
      trueSubmit(values);
    }
  };

  const { data: provider, isFetching: isFetchingProvider } = useFindProvider({
    providerId: currentProviderId,
  });

  if (isFetchingProvider || !scriptsLoaded) {
    return <Spinner center />;
  }

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={onSubmitForm}
      initialValues={defaultValues}
      mutators={{
        ...arrayMutators,
        resetRemovedSensitiveServices: (newServices, state, { changeValue }) => {
          changeValue(state, 'services', () => newServices[0]);
        },
      }}
      validate={(values) => {
        const errors = {};
        // If Eligibility Section is enabled, atleast one of the fields must have a value
        if (
          !values.no_eligibility_requirements &&
          !someEligibilityExists(values)
        ) {
          errors.eligibility_text =
            "Please describe client eligibility requirements or select 'No Client Eligibility Requirements.'";
        }
        // check if program has locations
        if (
          !serviceAreaSupportForOrgsFlag &&
          !values?.locations?.some((l) => l.selected)
        ) {
          errors.locations = [
            'Please select at least one location for the program.',
          ];
        }

        if (serviceAreaSupportForOrgsFlag) {
          if (values?.delivery_options?.in_office && !values?.locations?.some((l) => l.selected)) {
            errors.locations = [
              'Please select at least one location for the program.',
            ];
          }
          if (values && values?.delivery_options) {
            if (Object.keys(values?.delivery_options)
              .some((option) => option !== 'in_office' && values?.delivery_options[option]) &&
              !(values?.service_areas_national?.selected ||
                values?.service_areas_states?.some((s) => s.selected) ||
                values?.locations?.some((l) => l.selected)
              )) {
              errors.locations = [
                'Please select at least one location or service area for this program.',
              ];
            }
          }
        }

        if (!values?.languages?.length) {
          errors.languages = 'Required';
        }
        if (!values.status) {
          errors.status = 'Required';
        }

        if (values?.status === 'Accepting Referrals' && isEmpty(values?.services)) {
          errors.services = [
            'Please select at least one service type for the program.',
          ];
        }

        return errors;
      }}
      render={({
        form,
        handleSubmit,
        pristine,
        submitting,
        valid,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <ProgramInfoForm
            acceptingReferrals={values?.status === 'Accepting Referrals'}
            activeServiceTypes={activeServiceTypes}
            currentServices={values?.services}
            initialValues={initialValues}
            providerState={provider.state}
          />
          <BaseCard className="mt-6">
            <BaseCardHeader title="Program Contact Info" />
            <BaseCardBody className="px-8 py-10">
              <div className="w-7/12">
                <Field
                  name="website_url"
                  validate={(value) => validations.isUrl(value)}
                >
                  {(params) => (
                    <InputField
                      id="program-website_url"
                      label={
                        (
                          <>
                            Program Website URL{' '}
                            <small className="font-medium-font">
                              (e.g. https://www.uniteus.com)
                            </small>
                          </>
                        )
                      }
                      {...params}
                    />
                  )}
                </Field>
              </div>
              <hr className="mb-6" />
              <PhoneNumbersField
                name="phone_numbers"
                userPhoneTypes={false}
              />
              <hr className="mb-6" />
              <EmailAddressesField
                name="email_addresses"
                label="Program Email Address"
              />
            </BaseCardBody>
          </BaseCard>
          <ProgramAccessForm
            serviceAreaSupportForOrgsFlag={serviceAreaSupportForOrgsFlag}
            isDraft={provider?.state === 'draft'}
          />
          <ProgramLocationsForm
            programId={currentProviderId}
            values={values}
            serviceAreaSupportForOrgsFlag={serviceAreaSupportForOrgsFlag}
          />
          {serviceAreaSupportForOrgsFlag && (
            <ServiceAreasForm
              values={values}
              orgAdminDeactivatedPrograms={orgAdminDeactivatedProgramsFlag}
            />
          )}
          {orgAdminEnhancementsFlag ? (
            <EligibilityFormV2
              form={form}
              values={values}
              orgAdminDeactivatedPrograms={orgAdminDeactivatedProgramsFlag}
              orgAdminEnhancements={orgAdminEnhancementsFlag}
            />
            ) :
          (
            <EligibilityFormV2Deprecated
              form={form}
              values={values}
              orgAdminDeactivatedPrograms={orgAdminDeactivatedProgramsFlag}
            />
          )}
          <AccessibilityFormV2
            values={values}
            orgAdminEnhancements={orgAdminEnhancementsFlag}
          />
          <DialogV2
            cancelHandler={cancelDeleteService(form, values)}
            confirmationHandler={closeDialog(values)}
            cancelLabel="Go Back"
            confirmLabel="Save"
            ref={sensitiveServiceRef}
            title="Save Program Without Sensitive Service Type"
            isWarning
          >
            <span>
              <div>
                You removed: {
                  sensitiveServiceRemoved &&
                  sensitiveServiceRemoved?.map((service) => service.name)?.join(', ')
                }.
              </div>
              <div>These are sensitive service type(s).
                If you remove them and this program is the only one that offers those service type(s):
              </div>
              <br />
              <li>No one at your organization will have access to any cases or incoming
                referrals with these service type(s).
              </li>
              <li>You will have to fill out the Sensitive Service Program Update Form
                if you want to add these service type(s) again.
              </li>
              <br />
              <div>Do you want to save your changes? Click Go Back to recover the sensitive service type(s). </div>
            </span>
          </DialogV2>
          <ButtonFormSubmit
            isSaveDisabled={submitting || pristine || !valid}
          />
        </form>
        )}
    />
  );
};

ProgramForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  serviceTypes: PropTypes.object.isRequired,
  currentProviderId: PropTypes.string.isRequired,
  serviceAreaSupportForOrgs: PropTypes.bool,
  orgAdminProgramStatusNA: PropTypes.bool,
  orgAdminDeactivatedPrograms: PropTypes.bool,
  orgAdminEnhancements: PropTypes.bool,
  fetchLocations: PropTypes.func.isRequired,
  fetchServiceTypes: PropTypes.func.isRequired,
};

ProgramForm.defaultProps = {
  initialValues: {
    status: 'Accepting Referrals',
  },
  serviceAreaSupportForOrgs: false,
  orgAdminProgramStatusNA: false,
  orgAdminDeactivatedPrograms: false,
  orgAdminEnhancements: false,
};

function mapStateToProps(state) {
  return {
    serviceTypes: state.session.globals.service_types_flattened,
    currentProviderId: state.session.groupId,
    serviceAreaSupportForOrgs: serviceAreaSupportForOrgs(state),
    orgAdminProgramStatusNA: orgAdminProgramStatusNA(state),
    orgAdminDeactivatedPrograms: orgAdminDeactivatedPrograms(state),
    orgAdminEnhancements: orgAdminEnhancements(state),
  };
}

export default connect(mapStateToProps, {
  fetchLocations: fetchStatesGeography,
  fetchServiceTypes: fetchServices,
})(ProgramForm);
