import { useFind, usePopulate } from 'src/api/APIHooks';
import { get, map, isEmpty, sortBy, uniqBy } from 'lodash';

function getFullName (enrollment) {
  return [enrollment.person.first_name, ' ', enrollment.person.last_name].join('');
}

function sortEnrollments (enrollments) {
  const uniqueNames = uniqBy(enrollments, 'label').slice(0, 25);
  return !isEmpty(uniqueNames) ? sortBy(uniqueNames, 'label') : [];
}
const useEnrollmentClientNameOptions = (
  providerId,
  clientNameLike,
  enrollmentStatus,
) => {
  const { isLoading, data } = useFind(
    'enrollment_requests',
    {
      enrollmentStatus,
      'plan.payer': providerId,
      'person.query': clientNameLike,
    },
    {
      queryConfig: {
        enabled: (!isEmpty(clientNameLike)),
        placeholderData: undefined,
      },
    },
  );

  const enrollments = get(data, 'data.data', []);
  const { isLoading: isPersonDataLoading } = usePopulate(
    'person',
    'person',
    enrollments,
    {
      queryConfig: { placeholderData: undefined },
    },
  );
  const enrollmentClientNameOptions = map(enrollments, (key) => ({
    label: getFullName(key),
    value: getFullName(key),
  }));

  return (isLoading || isPersonDataLoading) ? [] : sortEnrollments(enrollmentClientNameOptions);
};

export default useEnrollmentClientNameOptions;
