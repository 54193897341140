import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import _ from 'lodash';
import { validateReduxForm } from 'common/form';
import buildResponses from '@unite-us/surveyjs/dist/components/Renderer/buildResponses';
import FormForm from 'common/form/FormForm';
import { returnedAssessment, setAssessmentsResponses, cleanReturnedAssessments } from 'actions/Assessment';
import { updateGroupContact, fetchGroupContact } from 'actions/Contact/Group';
import checkFields, { removeOptionalFields } from 'src/components/Cases/utils/checkFields';
import { CASE_DETAILS_FORM } from 'src/components/Cases/constants';
import {
  Button,
} from '@unite-us/ui';
import { reduxForm7 } from '@unite-us/client-utils';
import { caseDetailFields } from 'src/components/Cases/utils/fields';
import {
  filterAssessmentsData,
  returnInvalidFormIds,
} from 'common/utils/FormRenderer';
import addNotification from '../../common/utils/Notifications/actions/AddNotification';

export class AddCaseAssessments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedFormCard: 0,
      showErrors: false,
      nextDisabled: true,
      formsRendered: 0,
      sensitiveFormsSkipped: 0,
    };

    this.formRefs = {};

    this.handleExpandChange = this.handleExpandChange.bind(this);
    this.getAssessmentsResponses = this.getAssessmentsResponses.bind(this);
    this.skipStep = this.skipStep.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    const {
      contact,
      contactId,
      fields: { service_case, program_id, program_entry },
      groupId,
      params,
    } = this.props;

    const caseFields = removeOptionalFields({
      options: {
        serviceCase: service_case,
        programId: program_id,
        programEntry: program_entry,
      },
    });

    const containEmptyFields = checkFields(caseFields);

    if (containEmptyFields) {
      const { assistance_request_id } = params;
      const prefix = assistance_request_id ?
        `/assistance-requests/${assistance_request_id}` :
        '';

      browserHistory.push(`${prefix}/contacts/${contactId}/cases/new/add-case-details`);
    }

    if (_.isEmpty(contact)) {
      this.props.fetchGroupContact(groupId, contactId);
    }
    this.props.cleanReturnedAssessments();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(this.state) !== JSON.stringify(nextState)) {
      return true;
    }
    return false;
  }

  handleExpandChange(index) {
    return () => {
      this.setState({ expandedFormCard: index });
    };
  }

  handleSensitiveFormSkipped() {
    return () => {
      this.setState((prevState) => ({
        sensitiveFormsSkipped: prevState.sensitiveFormsSkipped + 1,
      }));
    };
  }

  handleRef(groupForms) {
    // enable the next button when all the forms are loaded
    if (this.state.nextDisabled) {
      const totalForms = groupForms.length;
      if (totalForms === this.state.formsRendered) {
        this.setState({ nextDisabled: false });
      } else {
        this.setState((prevState) => ({ formsRendered: prevState.formsRendered + 1 }), () => {
          if (this.state.formsRendered === totalForms - this.state.sensitiveFormsSkipped) {
            this.setState({ nextDisabled: false });
          }
        });
      }
    }
  }

  onNextClick() {
    const { groupForms, contactId } = this.props;

    return this.getAssessmentsResponses((responses) => {
      this.props.setAssessmentsResponses(responses, contactId);

      for (const assessment of responses) {
        const groupForm = _.find(groupForms, { id: assessment.id }) || {};
        this.props.returnedAssessment(groupForm.name);
      }

      this.skipStep();
    });
  }

  getAssessmentsResponses(onComplete) {
    const formResponses = {};
    let hasErrors = false;
    Object.keys(this.formRefs).forEach((formId) => {
      const formRef = this.formRefs[formId];
      formRef.formId = formId;

      if (formRef.ref.hasErrors()) {
        hasErrors = true;
      }
      const response = buildResponses(formRef.ref);
      formResponses[formId] = response;
    });

    this.setState({ showErrors: true });

    if (!hasErrors) {
      return onComplete(filterAssessmentsData(this.formRefs, formResponses));
    }

    return 0;
  }

  skipStep() {
    const { contactId, params } = this.props;
    const requestId = _.get(params, 'assistance_request_id');
    const prefix = requestId ? `/assistance-requests/${requestId}` : '';
    browserHistory.push(`${prefix}/contacts/${contactId}/cases/new/review`);
  }

  render() {
    const {
      groupForms,
      handleSubmit,
      isFetchingForms,
      groupId,
      contactId,
      currentEmployee,
    } = this.props;
    if (_.isEmpty(groupForms)) { this.setState({ nextDisabled: false }); }
    const { showErrors } = this.state;

    return (
      <div>
        {
          groupForms.map((form, index) => (
            <FormForm
              id={`group-form-${index}`}
              className={`custom-form-${index}`}
              setRef={(c) => {
                this.formRefs[form.id] = c;
                this.handleRef(groupForms);
              }}
              key={`${form.id}formform`}
              formId={form.id}
              groupId={groupId}
              handleExpandChange={this.handleExpandChange(index)}
              handleSensitiveFormSkipped={this.handleSensitiveFormSkipped()}
              expanded={index === this.state.expandedFormCard}
              onFormLoaded={this.onFormLoaded}
              currentEmployee={currentEmployee}
              hasErrors={showErrors ? this.formRefs[form.id]?.ref?.hasErrors() : false}
              contextType="case"
              contextPerson={contactId}
              usage_type="referral_assessment"
            />
          ))
        }

        <footer className="detail-review__footer">
          <Button
            onClick={handleSubmit(this.onNextClick)}
            id="next-btn"
            label="Next"
            primary
            disabled={isFetchingForms || this.state.nextDisabled}
          />
        </footer>
      </div>
    );
  }
}

AddCaseAssessments.propTypes = {
  cleanReturnedAssessments: PropTypes.func.isRequired,
  contact: PropTypes.object,
  contactId: PropTypes.string.isRequired,
  fetchGroupContact: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  groupForms: PropTypes.array,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isFetchingForms: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  returnedAssessment: PropTypes.func.isRequired,
  setAssessmentsResponses: PropTypes.func.isRequired,
  currentEmployee: PropTypes.object.isRequired,
};

AddCaseAssessments.defaultProps = {
  contact: {},
  groupForms: [],
};

AddCaseAssessments.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { groupForms } = state;
  const groupId = _.get(state, 'session.groupId');
  const contacts = _.get(state, 'contacts', []);
  const groupFormsArray = _.get(groupForms, groupId, []);
  const invalidForms = returnInvalidFormIds(groupFormsArray, reduxForm7, state);
  const isFetchingForms = _.get(state, 'groupForms.isFetching', false);
  const currentEmployee = _.get(state, 'globalState.currentEmployee');

  return {
    contact: _.find(contacts.contacts, { id: ownProps.params.id }),
    contactId: ownProps.params.id,
    groupForms: groupFormsArray,
    groupId,
    invalidForms,
    isFetchingForms,
    currentEmployee,
  };
}

export default validateReduxForm(
  {
    form: CASE_DETAILS_FORM,
    fields: caseDetailFields,
    destroyOnUnmount: false,
  },
  mapStateToProps,
  {
    cleanReturnedAssessments,
    fetchGroupContact,
    returnedAssessment,
    setAssessmentsResponses,
    updateGroupContact,
    addNotification,
  },
)(AddCaseAssessments);
