import { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce, isEqual } from 'lodash';
import utcSecondsToString from './utils/utcSecondsToString';

/* Time values to consider:
- logoutValue
  The time in seconds it takes to logout due to inactivity.

- warningDifference
  The time in seconds during which we see the warning.
  It will first appear at logoutValue - warningDifference seconds until logout.

- logDebounceValue
  The time in seconds we wait to assume the user has become inactive.
*/

/* 15 minutes = 900 seconds */

class AutoLogout extends Component {
  constructor(props) {
    super(props);
    this.events = [
      'click',
      'keydown',
      'mousemove',
      'scroll',
    ];

    this.storageKey = 'autologout';

    // the length of time in seconds from last action to warning
    this.warnValue = props.logoutSeconds - props.warningSeconds;

    this.logDebounceSeconds = 1;

    this.resetTimers = this.resetTimers.bind(this);
    this.continue = this.continue.bind(this);
    this.logInactivity = debounce(this.logInactivity.bind(this), this.logDebounceSeconds * 1000);
    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);

    this.events.forEach((event) => {
      window.addEventListener(event, this.handleActivity);
      window.addEventListener(event, this.logInactivity);
    });

    window.addEventListener('storage', () => this.handleStorage({ key: 'autologout' }));

    this.state = {
      warn: false,
      logout: false,
    };

    /* start timers immediately */
    this.setTimers();
  }

  componentDidMount() {
    /* log immediately. Because it is debounced, it will not fire if the user begins interacting
    within the debounce value time. */
    this.logInactivity();
  }

  componentDidUpdate(prevProps, prevState) {
    // set localStorage
    if (!isEqual(prevState, this.state)) {
      if (window.localStorage) {
        const data = JSON.stringify(this.state);
        localStorage.setItem('autologout', data);
      }
    }

    // when activity stops for [logDebounceSeconds]
    if (!this.state.warn && prevState.isActive && !this.state.isActive) {
      this.resetTimers();
    }

    // when modal closes
    if (prevState.warn && !this.state.warn) {
      this.resetTimers();
    }

    if (!prevState.logout && this.state.logout) {
      if (!this.props.ignoreLogout) {
        this.props.handleLogout();
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.warningTimeout);
    clearTimeout(this.logoutTimeout);
  }

  handleActivity = () => {
    if (!this.state.warn && !this.state.isActive) {
      this.setState({ isActive: true });
    }
  }

  handleStorage({ key } = {}) {
    if (key !== this.storageKey) { return; }
    const storageItem = localStorage.getItem(this.storageKey);
    const storageData = JSON.parse(storageItem);
    /*
    the conditional is necessary because IE implements the storage event incorrectly.
    The event is not intended to fire in the window that made the storage update,
    but in IE it does. So we need to see if the state and the storage are the same,
    which would indicate that we are in the same window. In that case, we shouldn't
    do anything, or else we will get stuck in an infinite loop.
    */
    if (!isEqual(this.state, storageData)) {
      this.setState(storageData);
    }
  }

  setTimers = () => {
    /* Set two timers for the warning and final logout. These are always running,
    but are continuously being reset then there is activity. */
    const { logoutSeconds, warningSeconds } = this.props;
    this.warningTimeout = setTimeout(this.warn, (logoutSeconds - warningSeconds) * 1000);
    this.logoutTimeout = setTimeout(this.logout, logoutSeconds * 1000);
  }

  logInactivity() {
    /* Get the current time in UTC seconds. This is [logDebounceValue] seconds after
    we stopped activity. */
    const now = Math.floor(Date.now() / 1000);

    /* Adjust the above value to get the exact time at which we stopped activity. */
    const adjusted = now - (this.logDebounceSeconds);

    /* Tell the server when we stopped, and get a new expiration back. */
    this.props.updateActivity({
      lastActive: adjusted,
    }).then(({ expiration }) => this.setState({ expiration }));

    this.setState({
      isActive: false,
      lastActive: adjusted,
    });
  }

  resetTimers() {
    /* Resetting the timers just involves clearing them and starting them again.
    They never stop without restarting. */
    if (!this.state.warn) {
      clearTimeout(this.warningTimeout);
      clearTimeout(this.logoutTimeout);
      this.setTimers();
    }
  }

  continue() {
    this.setState({
      warn: false,
      logout: false,
    });
  }

  warn() {
    if (this.state.isActive) return;
    this.setState({ warn: true });
  }

  logout() {
    if (this.state.isActive) return;
    this.setState({ logout: true });
  }

  render() {
    const { lastActive, expiration, ...rest } = this.state;
    const lastActiveString = lastActive && utcSecondsToString(lastActive);
    const expirationString = expiration && utcSecondsToString(expiration);
    return this.props.children({
      ...rest,
      lastActive,
      lastActiveString,
      expiration,
      expirationString,
      onClickContinue: this.continue,
      onClickLogout: this.logout,
    });
  }
}

AutoLogout.defaultProps = {
  ignoreLogout: false,
  logoutSeconds: 900,
  warningSeconds: 60,
};

AutoLogout.propTypes = {
  children: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  ignoreLogout: PropTypes.bool,
  logoutSeconds: PropTypes.number,
  warningSeconds: PropTypes.number,
  updateActivity: PropTypes.func.isRequired,
};

export default AutoLogout;
