import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import { validateReduxForm } from 'common/form';
import { Serializer } from '@unite-us/client-utils';
import { validations } from '@unite-us/app-components';
import {
  Button,
  SelectField,
  TextField,
  RadioField,
} from '@unite-us/ui';
import { browserHistory } from 'common/utils/browserHistory';
import { Spinner } from 'common/spinners';
import resolutionOptions from 'src/common/utils/Outcomes/utils/resolutionOptions';
import getOutcomesOptions from 'src/common/utils/Outcomes/utils/getOutcomesOptions';
import { closeReferral } from 'actions/Referral/Group';
import getClosingParams from 'src/common/utils/Closing/getClosingParams';
import { CLOSE_REFERRAL_FORM, CLOSE_REFERRAL_FIELDS } from 'src/components/Referrals/constants';
import callOrLog from 'src/common/utils/callOrLog';
import { DASHBOARD_EVENTS, DASHBOARD_VIEWS } from 'common/utils/EventTracker/utils/eventConstants';
import { crtb676CloseInboundReferrals, crtb1350VariableBWebApp } from 'src/common/utils/FeatureFlags/flags';
import 'react-loading-skeleton/dist/skeleton.css';
import './CloseReferralForm.scss';

class CloseReferralForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onResolvedChange = this.onResolvedChange.bind(this);
    this.state = {
      showSkeleton: false,
      showFields: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.fields?.resolved?.value !== this.props?.fields?.resolved?.value) {
      if (this.props?.fields?.resolved?.value) {
        this.setState({ showSkeleton: true, showFields: false });
        setTimeout(() => {
          this.setState({ showSkeleton: false, showFields: true });
        }, 500);
      }
    }
  }

  onResolvedChange(input) {
    if (input.value !== this.props.fields.resolved.value) {
      this.props.fields.outcome_id.onChange('');
    }
  }

  onSubmit(values) {
    this.props.onChangeSubmit(true);
    const {
      referral,
      referralId,
      groupId,
      isCC,
      closeInboundReferralsEnabled,
    } = this.props;

    const closingParams = getClosingParams(values);
    return this.props.closeReferral(groupId, referralId, closingParams, referral, isCC, closeInboundReferralsEnabled)
      .then((response) => {
        const referralResponse = _.get(response, 'data.data', {});
        const outcomePayload = Serializer.build({ outcome: values });

        this.props.onChangeSubmit(false);

        callOrLog(() => {
          this.context.eventTracker(DASHBOARD_EVENTS.closeReferral, {
            view: DASHBOARD_VIEWS.referralDetailView,
            ...outcomePayload,
          }, { referralResponse });
        });
        // when case is in draft, referral.receiving_provider.id is undefined
        if (_.has(referral, 'receiving_provider') &&
          ((referral.receiving_provider.id === groupId) && isCC && closeInboundReferralsEnabled)
        ) {
          browserHistory.push('/dashboard/new/closed');
        } else {
          browserHistory.push('/dashboard/referrals/sent/closed');
        }

        this.props.resetForm();
        return true;
      });
  }

  onCancel() {
    this.props.resetForm();
    this.props.closeDialog();
    this.setState({ showSkeleton: false, showFields: false });
  }

  render() {
    const {
      fields: { note, outcome_id, resolved },
      handleSubmit,
      outcomes,
      outcomesIsFetching,
      referral: { state },
    } = this.props;

    const validStatuses = ['declined', 'recalled'].includes(state);
    const showNewCloseModal = crtb1350VariableBWebApp && validStatuses;
    const shouldFixModalSize = showNewCloseModal && !this.state.showFields;

    if (outcomesIsFetching) {
      return <Spinner />;
    }

    return (
      <form className="close-referral-form content-with-actions">
        <div
          className={classNames(
            'close-referral-form__inputs content-container',
            { 'fix-modal-size': shouldFixModalSize },
          )}
        >
          <SelectField
            field={resolved}
            className="close-referral-resolve-select"
            id="resolvedInput"
            label="Is Resolved?"
            options={resolutionOptions(outcomes)}
            ref={this.props.registerField}
            validations={validations.isRequired}
            inline={false}
            onChange={this.onResolvedChange}
            required
          />

          {showNewCloseModal ? (
            <>
              {this.state.showSkeleton && (
                <>
                  <Skeleton height={30} width={200} style={{ marginTop: 10 }} />
                  {resolutionOptions(outcomes).map((index) => (
                    <Skeleton
                      key={index}
                      height={25}
                      width={300}
                      style={{ marginTop: 10 }}
                    />
                  ))}
                  <Skeleton height={30} width={200} style={{ marginTop: 30 }} />
                  <Skeleton height={100} width={500} style={{ marginTop: 10 }} />
                  <Skeleton height={30} width={200} style={{ marginTop: 30 }} />
                </>
              )}
              {this.state.showFields && (
                <>
                  <RadioField
                    field={outcome_id}
                    id="outcomeInput"
                    label="Outcome"
                    options={getOutcomesOptions(outcomes, _.get(resolved, 'value'))}
                    required
                    ref={this.props.registerField}
                    validations={validations.isRequired}
                  />
                  <TextField
                    field={note}
                    id="noteInput"
                    label="Note"
                    ref={this.props.registerField}
                    validations={validations.isRequired}
                    inline={false}
                    required
                  />
                </>
              )}
            </>
          ) : (
            <>
              <SelectField
                className="close-referral-outcome-select"
                field={outcome_id}
                id="outcomeInput"
                label="Outcome"
                options={getOutcomesOptions(outcomes, _.get(resolved, 'value'))}
                ref={this.props.registerField}
                validations={validations.isRequired}
                inline={false}
                required
                shouldSort={false}
              />
              <TextField
                field={note}
                id="noteInput"
                label="Note"
                ref={this.props.registerField}
                validations={validations.isRequired}
                inline={false}
                required
              />
            </>
          )}
        </div>

        <div className="close-referral-form__buttons actions">
          <span className="action-item">
            <Button
              id="close-referral-cancel-btn"
              label="Cancel"
              onClick={this.onCancel}
            />
          </span>
          <span className="action-item">
            <Button
              id="close-referral-close-btn"
              onClick={handleSubmit(this.onSubmit)}
              label="Close Referral"
              primary
            />
          </span>
        </div>
      </form>
    );
  }
}

CloseReferralForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  closeReferral: PropTypes.func.isRequired,
  closeInboundReferralsEnabled: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangeSubmit: PropTypes.func.isRequired,
  outcomes: PropTypes.array,
  outcomesIsFetching: PropTypes.bool.isRequired,
  referral: PropTypes.object.isRequired,
  referralId: PropTypes.string.isRequired,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  isCC: PropTypes.bool.isRequired,
};

CloseReferralForm.defaultProps = {
  closeInboundReferralsEnabled: false,
  outcomesIsFetching: false,
};

function mapStateToProps(state) {
  return {
    closeInboundReferralsEnabled: crtb676CloseInboundReferrals(state),
    crtb1350VariableBWebAppEnabled: crtb1350VariableBWebApp(state),
    groupId: _.get(state, 'session.groupId'),
  };
}

CloseReferralForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default validateReduxForm({
  form: CLOSE_REFERRAL_FORM,
  fields: CLOSE_REFERRAL_FIELDS,
}, mapStateToProps, {
  closeReferral,
})(CloseReferralForm);
