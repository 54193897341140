import React from 'react';
import { get } from 'lodash';
import { Card, CardHeader } from 'common/Card';
import { LineItem } from 'common/display/LineItem';
import { usePopulate } from 'api/APIHooks';
import PropTypes from 'prop-types';
import { epochToDate } from 'common/utils/utils';
import CaseStatus from './CaseStatus';

const CaseDetailsCard = ({ serviceAuthorization }) => {
  const kase = get(serviceAuthorization, 'case', {});
  const caseOpenedDate = epochToDate(kase.opened_date);
  const caseClosedDate = epochToDate(kase.closed_date);
  usePopulate('provider', 'provider', kase, { queryConfig: { placeholderData: undefined } });
  usePopulate('network', 'network', kase, { queryConfig: { placeholderData: undefined } });

  const isCaseLoaded = !!kase?.state && !!kase?.provider && !!kase?.network;

  return (
    <Card className="bg-white" data-testid="referral-details-card">
      <CardHeader
        className="pt-2 pl-2 space-y-2"
        title="Case Details"
      />
      <dl className="m-0 p-6 grid grid-cols-4 gap-y-2 gap-x-8">
        <LineItem
          field="Case Status"
          valueClassName="col-span-3 ml-2"
          isLoaded={isCaseLoaded}
          dataTestElement="case-status"
        >
          <CaseStatus
            state={kase.state}
            openedDate={caseOpenedDate}
            closedDate={caseClosedDate}
            showStatusDot={false}
          />
        </LineItem>
        <LineItem
          field="Created at"
          valueClassName="col-span-3 ml-2"
          isLoaded={isCaseLoaded}
          dataTestElement="case-created-at"
        >
          {kase.provider.name}
        </LineItem>
        <LineItem
          field="Recipient Network"
          valueClassName="col-span-3 ml-2"
          isLoaded={isCaseLoaded}
          dataTestElement="case-network"
        >
          {kase.network.name}
        </LineItem>
      </dl>
    </Card>
  );
};

CaseDetailsCard.propTypes = {
  serviceAuthorization: PropTypes.object.isRequired,
};

export default CaseDetailsCard;
