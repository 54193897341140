import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Drawer } from '@unite-us/ui';
import GroupList from 'src/components/Browse/GroupList/GroupList';
import { TOP_NAVIGATION_HEIGHT } from 'src/components/Navigation/constants';
import ReferralCreateGroupDetails from 'src/components/Groups/GroupDetails/ReferralCreateGroupDetails';
import { Serializer, addresses } from '@unite-us/client-utils';
import browse from 'src/components/Browse';
import callOrLog from 'src/common/utils/callOrLog';
import { BROWSE } from 'common/utils/EventTracker/utils/eventConstants';
import {
  hasPaymentsUserAccess,
  paginateNetworkGroups,
  programBasedSearchSelector,
  serviceAreaSupportForOrgs,
  showProgramStatusToggle,
  includePathwaysServices,
  pays6694RemoveContextPerson,
} from 'src/common/utils/FeatureFlags/flags';
import { filterGroupsReceivingReferrals } from 'src/components/Referrals/ReferralFormFields/EditReferralDetails/utils';
import {
  fetchCoreLocations,
  fetchCorePrograms,
  fetchCoreProvider,
} from 'src/actions/Group/Network';
import { requiredMapScript } from '../Map/utils';
import ReferralCreateBrowseFilters from '../BrowseFilters/ReferralCreateBrowseFilters';
import {
  getGoogleAddressDetails,
  getMarkerObjects,
  getOurCoordinates,
} from '../utils/address';
import Map from '../Map';
import { getGroupData } from '../utils/display';
import {
  buildBrowseFilters,
  searchParams,
  getSearchRadius,
} from '../utils/filters';

import './ReferralCreateBrowse.scss';

export class ReferralCreateBrowse extends Component {
  constructor(props) {
    super(props);

    this.clearNetworkBrowseFilters = this.clearNetworkBrowseFilters.bind(this);
    this.fetchGroup = this.fetchGroup.bind(this);
    this.getCurrentSelectedGroupId = this.getCurrentSelectedGroupId.bind(this);
    this.toggleGroupDetail = this.toggleGroupDetail.bind(this);
    this.debouncedToggleGroupDetail = _.debounce(this.toggleGroupDetail, 500);
    this.toggleGroupDetailFromMarker = this.toggleGroupDetailFromMarker.bind(this);
    this.onAddOrRemoveGroup = this.onAddOrRemoveGroup.bind(this);
    this.resetSelectedGroup = this.resetSelectedGroup.bind(this);
    this.trackGroupDetailClick = this.trackGroupDetailClick.bind(this);
    this.toggleFiltersDrawer = this.toggleFiltersDrawer.bind(this);
    this.toggleProviderHover = this.toggleProviderHover.bind(this);

    this.state = {
      existingSelectedGroups: props.selectedGroups.length > 0,
      isSettingCenter: false,
      filtersDrawerOpen: false,
      programs: [],
      providerLocations: [],
      providerServiceAreas: [],
      selectedProvider: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.networkId !== this.props.networkId && !this.props.parentState.uiFetching) {
      this.clearNetworkBrowseFilters(nextProps);
    }
  }

  onAddOrRemoveGroup(group) {
    this.props.addOrRemoveGroup(group);
  }

  getCurrentSelectedGroupId() {
    return _.get(this.props, 'parentState.uiCurrentSelectedGroupId');
  }

  clearNetworkBrowseFilters(props = this.props) {
    this.props.clearNetworkBrowseGroups();

    const centerCoordinates = getOurCoordinates(props);
    const clientCenter = _.get(this.props, 'parentState.clientAddress.latLng', {});
    const browseFilterParams = this.props.callParent('buildBrowseFiltersParams', props);
    const filters = buildBrowseFilters({ ...browseFilterParams, centerCoordinates });
    const sortBy = props.parentState.sortBy;
    if (!_.isEmpty(clientCenter) && filters.addressType === 'client') {
      const contactAddress = addresses.findMainAddress(_.get(this.props, 'contact.addresses', []));

      getGoogleAddressDetails(contactAddress).then((result) => {
        const {
          latLng,
          address,
        } = result;

        if (result) {
          this.props.callParent('setParentState', {
            sortBy,
            uiOpen: false,
            uiFetching: true,
            uiCenter: latLng,
            uiCenterForDistance: latLng,
            uiFilters: {
              ...filters,
              address: {
                address,
                id: address.id,
                latLng,
              },
            },
          });
          this.setState((prevState) => ({
            isSettingCenter: !prevState.isSettingCenter,
          }), () => {
            const q = searchParams({ ...filters, sortBy });
            this.props.callParent('fetchGroups', { reqParams: { q } }, props.networkId);
          });
        }
      });
    } else {
      this.props.callParent('setParentState', {
        uiOpen: false,
        uiFetching: true,
        uiCenter: centerCoordinates,
        uiCenterForDistance: centerCoordinates,
        uiFilters: {
          ...this.props.parentState.uiFilters,
          ...filters,
        },
        sortBy,
      }, () => {
        const q = searchParams({ ...filters, sortBy });
        this.props.callParent('fetchGroups', { reqParams: { q } }, props.networkId);
      });
    }
  }

  fetchGroup(selectedGroup) {
    const selectedGroupId = _.get(selectedGroup, 'id');
    const isCurrentGroupSelected = this.getCurrentSelectedGroupId() === selectedGroupId;

    this.props.callParent('setParentState', {
      uiOpen: true,
      uiCurrentSelectedGroupId: selectedGroupId,
      uiSelectedGroupFromMarker: selectedGroupId,
    }, () => {
      if (!isCurrentGroupSelected) {
        this.props.clearNetworkBrowseGroupPrograms();
      }
    });
  }

  resetSelectedGroup() {
    this.props.callParent('setParentState', {
      uiOpen: false,
      uiCurrentSelectedGroupId: null,
      uiSelectedGroupFromMarker: null,
    }, () => {
      this.props.clearNetworkBrowseGroup();
    });
  }

  /**
    * Filter google params and convert to group object.
    * Trigger toggleGroupDetail if selectedGroup is included in filterGroups
    * current user group is an invalid choice to add/remove referral.
    * The object coming in should have a marker object which contains the groupId key
    * Use this key to find the group object
  */
  toggleGroupDetailFromMarker({ marker }) {
    const { groupId } = marker;
    const { filteredGroups } = this.props;
    const selectedFilteredGroup = _.find(filteredGroups, (group) => group.id === groupId);

    if (selectedFilteredGroup) {
      this.toggleGroupDetail({ selectedGroup: selectedFilteredGroup });
    }
  }

  /**
   * set selectedGroup based on group object
  */
  toggleGroupDetail({ selectedGroup, serviceAreaServed }) {
    const {
      currentUserGroup,
      employeeId,
      parentState,
      contact,
      usePaymentsUserRole,
      includePathways,
    } = this.props;
    const selectedGroupId = _.get(selectedGroup, 'id');
    const referable = { person: contact.id, employee: employeeId };

    // Referral Process Only
    // canViewGroupDetail: current user can not see his own group
    const canViewGroupDetail = selectedGroupId !== currentUserGroup.id;

    if (canViewGroupDetail) {
      if (parentState.uiOpen && this.getCurrentSelectedGroupId() === selectedGroupId) {
        this.resetSelectedGroup();
      } else {
        // TODO: Post migration - update function name
        this.fetchGroup(selectedGroup);
      }

      // Track this only on opening the drawer, or changing groups with drawer open
      if (!parentState.uiOpen || selectedGroup.id !== parentState.uiCurrentSelectedGroupId) {
        this.trackGroupDetailClick(selectedGroup);
        this.setState({ serve: !!serviceAreaServed });
        if (this.props.useProgramBasedSearch) {
          // eslint-disable-next-line no-console, max-len
          console.log('   )\n  ) \\\n / ) (\n \\(_)/   Drawer opened or group changed w/ drawer open (Referral creation)');
        }
      }

      this.setState({ providerServiceAreas: serviceAreaServed });

      fetchCoreProvider(selectedGroupId).then((provider) => {
        const selectedProvider = {
          ..._.get(provider, 'attributes', {}),
          id: selectedGroupId,
        };
        this.setState({ selectedProvider });
      });

      fetchCorePrograms({
        ...(this.props.pays6694RemoveContextPersonFlag ? {} : { clientId: contact.id }), // remove PAYS-7131
        provider: selectedGroupId,
        referable,
        usePaymentsUserRole,
        includePathways,
      }).then((programs) => {
        this.setState({ programs });
      });

      fetchCoreLocations(selectedGroupId).then((providerLocations) => {
        this.setState({ providerLocations });
      });
    }
  }

  toggleFiltersDrawer() {
    this.props.callParent('setParentState', { uiOpen: false });
    if (this.state.filtersDrawerOpen) {
      this.setState({
        filtersDrawerOpen: false,
      });
    } else {
      callOrLog(() => this.context.eventTracker(BROWSE.filtersDrawerOpened));
      this.setState({
        filtersDrawerOpen: true,
      });
    }
  }

  trackGroupDetailClick(group) {
    const { network } = this.props;
    const slimGroup = Serializer.build({ browseGroupDetails: group });

    callOrLog(() => this.context.eventTracker(BROWSE.viewDrawerOrg, {
      ...slimGroup,
      browse_network_id: network.id,
      browse_network_name: network.name,
    }));
  }

  toggleProviderHover(isHovering, hoverGroupId) {
    const groupId = isHovering ? hoverGroupId : '';
    this.setState({ hoverGroupId: groupId });
  }

  render() {
    const {
      addGroups,
      contact,
      currentUserGroup,
      filteredGroups,
      filteredGroupsServiceArea,
      totalElements,
      totalElementsServiceAreas,
      groupsOptionType,
      isFetching,
      isFetchingServiceArea,
      networkId,
      paging,
      pagingServiceArea,
      removeGroup,
      selectedGroup,
      selectedGroups,
      selectedServices,
      serviceType,
      serviceTypes,
      toggleBrowse,
      serviceAreaSupportForOrgsFlag,
      stateDisplayName,
    } = this.props;

    const {
      programs,
      providerLocations,
      providerServiceAreas,
      selectedProvider,
    } = this.state;

    const { filtersDrawerOpen } = this.state;

    const markers = getMarkerObjects(filteredGroups);

    const groupData = getGroupData({
      fetchedGroupData: selectedGroup,
      selectedGroupId: this.getCurrentSelectedGroupId(),
      slimGroups: this.state.serve ? filteredGroupsServiceArea : filteredGroups,
    });

    return (
      <div className="referral-create-browse browse">
        <Drawer
          className="browse__drawer"
          containerClassName="group-detail-drawer"
          onClose={() => this.props.callParent('onDrawerClose')}
          open={this.props.parentState.uiOpen}
          secondary
          topOffset={TOP_NAVIGATION_HEIGHT}
          width="500px"
        >
          <ReferralCreateGroupDetails
            center={this.props.parentState.uiCenterForDistance}
            group={groupData}
            programs={programs}
            selectedGroups={selectedGroups}
            addOrRemoveGroup={this.onAddOrRemoveGroup}
            serviceType={serviceType}
            providerLocations={providerLocations}
            providerServiceAreas={providerServiceAreas}
            selectedProvider={selectedProvider}
            serve={this.state.serve}
            stateDisplayName={stateDisplayName}
          />
        </Drawer>

        <div className="browse__filters">
          {
            !_.isEmpty(currentUserGroup) && (
              <ReferralCreateBrowseFilters
                addGroups={addGroups}
                clearNetworkBrowseFilters={this.clearNetworkBrowseFilters}
                contact={contact}
                currentUserGroup={currentUserGroup}
                existingSelectedGroups={this.state.existingSelectedGroups}
                filters={this.props.parentState.uiFilters}
                filtersDrawerOpen={filtersDrawerOpen}
                resultsCount={totalElements + totalElementsServiceAreas}
                groupsOptionType={groupsOptionType}
                networkId={networkId}
                onFiltersChange={(activeFilters, keys) => {
                  this.props.callParent('onFiltersChange', activeFilters, keys);
                }}
                onSortByChange={(sortBy) => this.props.callParent('onSortByChange', sortBy)}
                removeGroup={removeGroup}
                selectedGroups={_.get(this.props, 'selectedGroups', [])}
                serviceType={serviceType}
                serviceTypes={serviceTypes}
                setGeoFilters={(filters) => this.props.callParent('setGeoFilters', filters)}
                toggleBrowse={toggleBrowse}
                toggleFiltersDrawer={this.toggleFiltersDrawer}
                serviceAreaSupportForOrgsFlag={serviceAreaSupportForOrgsFlag}
              />
            )
          }
        </div>

        <div className="browse__content">
          <div
            className={classNames({
              'browse__content-list': true,
              'referral-browse': true,
            })}
          >
            <GroupList
              center={this.props.parentState.uiCenterForDistance}
              list={filteredGroups}
              loading={isFetching}
              selectedGroupFromMarker={this.props.parentState.uiSelectedGroupFromMarker}
              selectedGroups={selectedGroups}
              selectedServices={selectedServices}
              selectGroup={this.onAddOrRemoveGroup}
              serviceType={serviceType}
              toggleGroupDetail={(selectedGroupValue) => {
                this.debouncedToggleGroupDetail({ selectedGroup: selectedGroupValue });
              }}
              toggleProviderHover={this.toggleProviderHover}
              title={serviceAreaSupportForOrgsFlag ? 'Nearby Organizations' : ''}
              numberOfElements={totalElements}
              appendData={() => this.props.callParent('appendGroups', { type: 'location' })}
              emptyMessage={null}
              paging={paging}
              serviceAreaSupportForOrgsFlag={serviceAreaSupportForOrgsFlag}
            />
            {serviceAreaSupportForOrgsFlag && (
              <GroupList
                center={this.props.parentState.uiCenterForDistance}
                list={filteredGroupsServiceArea}
                loading={isFetchingServiceArea}
                selectedGroupFromMarker={this.props.parentState.uiSelectedGroupFromMarker}
                selectedGroups={selectedGroups}
                selectedServices={selectedServices}
                selectGroup={this.onAddOrRemoveGroup}
                serviceType={serviceType}
                toggleGroupDetail={(selectedGroupValue, serviceAreaServed) => {
                  this.toggleGroupDetail({ selectedGroup: selectedGroupValue, serviceAreaServed });
                }}
                toggleProviderHover={this.toggleProviderHover}
                title={serviceAreaSupportForOrgsFlag && 'Also serves this area'}
                numberOfElements={totalElementsServiceAreas}
                appendData={() => this.props.callParent('appendGroups', { type: 'serviceArea' })}
                emptyMessage={null}
                paging={pagingServiceArea}
                serviceAreaSupportForOrgsFlag={serviceAreaSupportForOrgsFlag}
                serve
                stateDisplayName={stateDisplayName}
              />
            )}
          </div>
          <div className="browse__content-map">
            {
              this.props.parentState.scriptsLoaded && this.props.parentState.uiCenter && (
                <Map
                  center={this.props.parentState.uiCenter}
                  centerForDistance={this.props.parentState.uiCenterForDistance}
                  currentUserGroup={currentUserGroup}
                  google={window.google}
                  handleDragEnd={(newCenter) => this.props.callParent('handleCenterChange', newCenter)}
                  handleSearchAreaChange={() => this.props.callParent('handleSearchAreaChange')}
                  handleSearchAreaClick={(newCenter, formattedAddress) => {
                    this.props.callParent('handleSearchAreaClick', newCenter, formattedAddress);
                  }}
                  markers={markers}
                  onDblClick={(newCenter) => this.props.callParent('handleCenterChange', newCenter)}
                  searchRadius={getSearchRadius(_.get(this.props, 'parentState.uiFilters.distance'))}
                  setSelectedGroupFromMarker={this.toggleGroupDetailFromMarker}
                  showSearchButton={this.props.parentState.uiShowSearchButton}
                  toggleProviderHover={this.toggleProviderHover}
                  hoverGroupId={this.state.hoverGroupId}
                />
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

ReferralCreateBrowse.propTypes = {
  // Required
  addGroups: PropTypes.func.isRequired,
  callParent: PropTypes.func.isRequired,
  clearNetworkBrowseGroup: PropTypes.func.isRequired,
  clearNetworkBrowseGroupPrograms: PropTypes.func.isRequired,
  clearNetworkBrowseGroups: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
  employeeId: PropTypes.string.isRequired,
  filteredGroups: PropTypes.array.isRequired,
  groupsOptionType: PropTypes.string,
  includePathways: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  network: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  networkId: PropTypes.string.isRequired,
  parentState: PropTypes.object.isRequired,
  removeGroup: PropTypes.func.isRequired,
  serviceTypes: PropTypes.array,
  toggleBrowse: PropTypes.func.isRequired,
  usePaymentsUserRole: PropTypes.bool.isRequired,
  filteredGroupsServiceArea: PropTypes.array.isRequired,
  totalElements: PropTypes.number.isRequired,
  totalElementsServiceAreas: PropTypes.number.isRequired,
  isFetchingServiceArea: PropTypes.bool.isRequired,
  stateDisplayName: PropTypes.string.isRequired,
  useProgramBasedSearch: PropTypes.bool.isRequired,
  pays6694RemoveContextPersonFlag: PropTypes.bool.isRequired,

  // Optional
  currentUserGroup: PropTypes.object,
  paging: PropTypes.object,
  pagingServiceArea: PropTypes.object,
  selectedGroup: PropTypes.object,
  selectedGroups: PropTypes.array,
  addOrRemoveGroup: PropTypes.func,
  selectedServices: PropTypes.array,
  serviceType: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  serviceAreaSupportForOrgsFlag: PropTypes.bool,
};

ReferralCreateBrowse.defaultProps = {
  currentUserGroup: {},
  groupsOptionType: '',
  selectedGroup: {},
  selectedGroups: [],
  selectedServices: [],
  serviceAreaSupportForOrgsFlag: false,
  serviceTypes: [],
  paging: {},
  pagingServiceArea: {},
  addOrRemoveGroup: _.noop,
  serviceType: { id: '' },
};

ReferralCreateBrowse.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { restrictGroupsBy, groupsOptionType, serviceType } = ownProps;
  const useReferralToggle = showProgramStatusToggle(state);
  const canPaginateNetworkGroups = paginateNetworkGroups(state);

  const employeeId = _.get(state, 'globalState.currentEmployee.id');
  const browseGroupsState = _.get(state, 'browse.groups', []);
  const totalElements = _.get(state, 'browse.paging.total_count', 0);
  const browseGroupsServiceAreas = _.get(state, 'browse.groupsServiceArea', []);
  const totalElementsServiceAreas = _.get(state, 'browse.pagingServiceArea.total_count', 0);
  const usePaymentsUserRole = hasPaymentsUserAccess(state);
  const pays6694RemoveContextPersonFlag = pays6694RemoveContextPerson(state);

  let browseGroups;
  let browseServiceAreasGroups;

  if (canPaginateNetworkGroups) {
    if (useReferralToggle && groupsOptionType === 'in-network') {
      browseGroups = filterGroupsReceivingReferrals(browseGroupsState, serviceType, usePaymentsUserRole);
      browseServiceAreasGroups = filterGroupsReceivingReferrals(browseGroupsServiceAreas, serviceType);
    } else {
      browseGroups = browseGroupsState;
      browseServiceAreasGroups = browseGroupsServiceAreas;
    }
  } else {
    browseGroups = restrictGroupsBy(browseGroupsState);
    browseServiceAreasGroups = restrictGroupsBy(browseGroupsServiceAreas);
  }

  return {
    employeeId,
    filteredGroups: _.reject(browseGroups, { id: ownProps.currentUserGroup.id })
      ?.map((x, i) => ({ position: i + 1, ...x })),
    filteredGroupsServiceArea: _.reject(browseServiceAreasGroups, { id: ownProps.currentUserGroup.id })
      ?.map((x, i) => ({ position: i + 1, ...x })),
    serviceAreaSupportForOrgsFlag: serviceAreaSupportForOrgs(state),
    totalElements,
    totalElementsServiceAreas,
    usePaymentsUserRole,
    useProgramBasedSearch: programBasedSearchSelector(state),
    includePathways: includePathwaysServices(state),
    pays6694RemoveContextPersonFlag,
  };
}

export default connect(
  mapStateToProps,
)(
  browse(requiredMapScript)(ReferralCreateBrowse),
);
