import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// unite-us
import { Button } from '@unite-us/ui';

// api
import { apiDefault } from 'api/config';
import { useFindRecord, useFind, usePopulate } from 'api/APIHooks';
import { isHttpSuccess } from 'api/utils/httpStatus';

// common
import FeatureFlagContainer from 'common/utils/FeatureFlags/FeatureFlagContainer';

import { useFeatureFlag, useAuthorizeClientMerging } from 'common/hooks';
import Notifier from 'common/helpers/Notifier';
import { browserHistory } from 'common/utils/browserHistory';

// components
import { isOrgAdmin } from 'components/User/utils';
import ConfirmationModal from 'components/People/MergeDetails/components/ConfirmationModal';
import MergeForm from './components/MergeForm';

import { mergeClientData } from './utils/mergeClientData';

export const MergeDetails = ({ location, userIsAdmin }) => {
  const isClientMergingEnabled = useFeatureFlag('cl-67-client-merging');
  const [mergedClientId, setMergedClientId] = useState(null);
  const DuplicatesModalRef = useRef();
  const [submitForm, setSubmitForm] = useState(false);
  const { query: { clients } } = location;

  useAuthorizeClientMerging(isClientMergingEnabled, userIsAdmin);

  const { data: duplicatesClientsResponse } = useFind(`people/${mergedClientId}/duplicates`, {}, {
    api: 'coreApi',
    queryConfig: {
      enabled: mergedClientId !== null,
      placeholderData: undefined,
    },
  });

  const { data: clientsComparison, isFetching: isFetchingClientComparison } = useFind(
    `people/comparison?ids[]=${clients[0]}&ids[]=${clients[1]}&`,
    {},
    {
      api: 'coreApi',
      queryConfig: {
        enabled: isClientMergingEnabled && userIsAdmin,
        placeholderData: undefined,
      },
    },
  );
  const { data: firstClientResponse, isFetching: isFetchingFirstClient } = useFindRecord(
    'person',
    clients[0],
    {
      include: 'addresses',
      queryConfig: { placeholderData: undefined },
      enabled: isClientMergingEnabled && userIsAdmin,
    },
  );
  const firstClientData = firstClientResponse?.data?.data;
  const { data: secondClientResponse, isFetching: isFetchingSecondClient } = useFindRecord(
    'person',
    clients[1],
    {
      include: 'addresses',
      queryConfig: { placeholderData: undefined },
      enabled: isClientMergingEnabled && userIsAdmin,
    },
  );
  const secondClientData = secondClientResponse?.data?.data;
  usePopulate(
    'contact_preference',
    'contact_preference',
    firstClientData,
    {
      queryConfig: { placeholderData: undefined },
    },
  );
  usePopulate(
    'contact_preference',
    'contact_preference',
    secondClientData,
    {
      queryConfig: { placeholderData: undefined },
    },
  );

  const isLoading = isFetchingFirstClient || isFetchingSecondClient || isFetchingClientComparison;
  const mergedClientHasDuplicates = duplicatesClientsResponse?.data?.length >= 2;
  const differentFields = Object.fromEntries(
    Object.entries(clientsComparison?.data?.data || {}).filter(([, value]) => value[2] === true),
  );

  const openConfirmationModal = (formSubmit) => {
    setSubmitForm(() => formSubmit);
    DuplicatesModalRef.current.openModal();
  };

  const confirmMergeClients = async (formValues) => {
    try {
      const { first_name, last_name } = firstClientData;
      const newestClientIndex = new Date(firstClientData.created_at) > new Date(secondClientData.created_at) ? 0 : 1;

      const mergeData = mergeClientData(
        formValues,
        clientsComparison,
        differentFields,
        { first_name, last_name },
        newestClientIndex,
      );

      const response = await apiDefault.post(
        `/people/merge?ids[]=${firstClientData.id}&ids[]=${secondClientData.id}`,
        mergeData,
      );

      if (!response || !isHttpSuccess(response.status)) {
        Notifier.dispatch('error', 'We could not complete the merge. Please try again.');
      } else {
        const clientId = response.data.data.id;
        setMergedClientId(clientId);
      }
    } catch {
      Notifier.dispatch('error', 'We could not complete the merge. Please try again.');
    } finally {
      DuplicatesModalRef.current.closeModal();
    }
  };

  const submitMergeForm = async () => {
    DuplicatesModalRef.current.closeModal();

    if (submitForm) {
      await submitForm();
    }
  };

  const getContent = () => {
    if (mergedClientId) {
      return (
        <div
          className="bg-white border border-solid
            rounded border-filter-border-color w-771px
            h-409px flex flex-col items-center justify-center
            text-brand-blue space-y-6"
        >
          <h1 className="text-4xl">Merge Successful!</h1>
          <div className="text-sm">
            The client profiles are now merged.
            You can view the merge history in the note section of the client&rsquo;s face sheet
          </div>
          <div className="flex items-center justify-center space-x-4">
            <Button
              label="Go to Client's Face Sheet"
              className="w-56"
              onClick={() => browserHistory.push({ pathname: `/facesheet/${mergedClientId}` })}
            />
            {mergedClientHasDuplicates && (
              <Button
                primary
                label="Merge Additional Duplicates"
                className="w-56"
                onClick={() => browserHistory.push({ pathname: `/people/duplicates/${mergedClientId}` })}
              />
            )}
          </div>
        </div>
      );
    }

    return (
      <MergeForm
        confirmMergeClients={confirmMergeClients}
        openConfirmationModal={openConfirmationModal}
        isLoading={isLoading}
        firstClientData={firstClientData}
        secondClientData={secondClientData}
        differentFields={differentFields}
        clientsComparison={clientsComparison}
      />
    );
  };

  return (
    <div className="flex justify-center py-12">
      {getContent()}
      <ConfirmationModal modalRef={DuplicatesModalRef} submitMergeForm={submitMergeForm} />
    </div>
  );
};

MergeDetails.propTypes = {
  location: PropTypes.object.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { session } = state;

  return {
    userIsAdmin: isOrgAdmin(state.user, session.groupId),
  };
};

export default connect(mapStateToProps)(FeatureFlagContainer(MergeDetails));
