import { coreApi } from 'src/api/config';

const fetchClientRelationship = async (contactId, groupId) => {
  const existingRelationship = await coreApi.query('client_relationship', {
    person: contactId,
    provider: groupId,
  });

  return existingRelationship?.data?.data;
};

export default fetchClientRelationship;
