import { SEARCH_CONTACT } from 'actions';
import moment from 'moment';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { isHttp500Error } from 'common/utils/Error';
import { coreApi } from 'src/api/config';
import { isString, lowerCase, get } from 'lodash';

export const PHONE_TYPES = ['home', 'work', 'mobile', 'fax', 'unknown'];

const buildEmpiResults = (list) => {
  try {
    return list.map((person) => {
      const dob = person?.date_of_birth;
      return {
        ...person,
        full_name: `${person?.first_name} ${person?.last_name}`,
        date_of_birth: isString(dob) ? Math.floor(new Date(dob).getTime() / 1000) : dob,
        phone_numbers: get(person, 'phone_numbers', []).map((phone) => ({
          ...phone,
          phone_type: PHONE_TYPES.includes(lowerCase(phone?.phone_type)) ? phone.phone_type : 'unknown',
        })),
      };
    });
  } catch (error) {
    return [];
  }
};

export default function searchContact(groupId, filters, showErrors = false) {
  return async (dispatch) => {
    let searchType = 'empi';
    let list = [];

    let coreResponse = await coreApi.query('empi_person', {
      first_name: filters.first_name,
      last_name: filters.last_name,
      date_of_birth: moment.unix(filters.date_of_birth).utc().format('YYYY-MM-DD'),
      ...(filters.context && { context: filters.context }),
    });

    if (isHttp500Error(coreResponse.status)) {
      searchType = 'core';
      coreResponse = await coreApi.query('person', {
        last_name: filters.last_name,
        date_of_birth: moment.unix(filters.date_of_birth).utc().format('YYYY-MM-DD'),
        'client_relationships.provider': groupId,
      });
    } else if (!isHttpSuccess(coreResponse.status) && showErrors) {
      return Notifier.handleErrors(coreResponse.status);
    }

    list = coreResponse.data.data;

    if (searchType === 'empi') {
      list = buildEmpiResults(list);
    }

    list = list.map((item) => ({
      item: {
        result: {
          ...item,
          date_of_birth: item.date_of_birth,
        },
      },
    }));

    const payload = {
      ...coreResponse,
      data: {
        ...coreResponse.data,
        data: list,
      },
    };

    dispatch({ type: SEARCH_CONTACT, payload });
    return payload;
  };
}
