import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, get, isEmpty, wget } from 'lodash';
import FacesheetInteractions from 'src/components/Facesheet/Overview/components/FacesheetInteractions';
import CaseSummary from 'src/components/Facesheet/Overview/components/CaseSummary';
import {
  cl1224UnconsentedClientFacesheetAccess as cl1224UnconsentedClientFacesheetAccessFF,
} from 'common/utils/FeatureFlags/flags';
import Relationships from './Relationships/Relationships';

const DISPLAY_NAME = 'facesheet-overview';
export function FacesheetOverview({
  contact,
  contactCases,
  employeeId,
  getClientRelationship,
  visibilityConfig,
  cl1224UnconsentedClientFacesheetAccess,
}) {
  if (isEmpty(contact)) {
    return null;
  }

  const contactConsented = contact?.consent?.state === 'accepted';

  return (
    <div className={DISPLAY_NAME}>
      {(!contactConsented && cl1224UnconsentedClientFacesheetAccess) && (
        <div
          className="
            flex justify-between items-center h-52px px-4 mb-4 -mt-4
            rounded border border-solid border-yellow bg-light-yellow
          "
        >
          This client hasn’t provided consent to send referrals yet, so you’re seeing a limited view of
          their face sheet. Capture their consent to see additional information.
        </div>
      )}
      <div className="row">
        <div className="col-md-4">
          {contact && (
            <Relationships
              person={contact}
              employeeId={employeeId}
              getClientRelationship={getClientRelationship}
              visibilityConfig={visibilityConfig}
            />
          )}
          {visibilityConfig.overview.showOpenCases && (
            <div className="mb-6">
              {!isEmpty(contactCases) && (
                <CaseSummary contactCases={contactCases} />
              )}
            </div>
          )}
        </div>
        <div className="col-md-8">
          <FacesheetInteractions
            contact={contact}
            contactCases={contactCases}
            visibilityConfig={visibilityConfig}
          />
        </div>
      </div>
    </div>
  );
}

const OverviewVisibilityConfigPropType = PropTypes.shape({
  showTab: PropTypes.bool.isRequired,
  showCareCoordinator: PropTypes.bool.isRequired,
  showFamilyMembers: PropTypes.bool.isRequired,
  showOpenCases: PropTypes.bool.isRequired,
  showNotes: PropTypes.bool.isRequired,
  showMessages: PropTypes.bool.isRequired,
});

FacesheetOverview.propTypes = {
  contact: PropTypes.object.isRequired,
  contactCases: PropTypes.array.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  employeeId: PropTypes.string,
  getClientRelationship: PropTypes.func.isRequired,
  visibilityConfig: PropTypes.shape({
    overview: OverviewVisibilityConfigPropType.isRequired,
  }).isRequired,
  cl1224UnconsentedClientFacesheetAccess: PropTypes.bool.isRequired,
};

FacesheetOverview.defaultProps = {
  employeeId: '',
};

function mapStateToProps(state, ownProps) {
  const contactId = wget(ownProps, 'params.id', '');
  const contacts = wget(state, 'contacts.contacts', []);
  const contactCaseObj = get(state.serviceCase, contactId);
  const contactCases = get(contactCaseObj, 'data', []);
  const groupId = get(state, 'session.groupId', '');
  const employeeId = get(state, 'globalState.currentEmployee.id', '');

  return {
    contact: find(contacts, { id: contactId }),
    contactCases,
    groupId,
    employeeId,
    cl1224UnconsentedClientFacesheetAccess: cl1224UnconsentedClientFacesheetAccessFF(state),
  };
}

export default connect(mapStateToProps, null)(FacesheetOverview);
