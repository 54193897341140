import { apiDefault } from 'src/api/config';
import coreParamsSerializer from 'src/api/utils/coreParamsSerializer';

export default function fetchCorePrograms({
  clientId,
  provider,
  receiving_referrals,
  referable,
  services,
  usePaymentsUserRole,
  includePathways,
  feeScheduleProgramId,
}) {
  const request = apiDefault.get('/programs', {
    params: {
      include_discrete_eligibility_fields: true,
      include: 'postal_code_requirements,county_requirements,city_requirements,state_requirements,requirements',
      filter: {
        active: true,
        provider,
        receiving_referrals,
        services,
        include_pathways: includePathways,
        ...(referable ? { referable } : {}),
        ...(!usePaymentsUserRole && { has_fee_schedule_program: usePaymentsUserRole }),
        ...(feeScheduleProgramId && { fee_schedule_program: feeScheduleProgramId }),
      },
      ...(usePaymentsUserRole && clientId && { context: { person: clientId } }), // remove PAYS-7131
    },
    paramsSerializer: (params) => coreParamsSerializer(params),
  });

  function onSuccess(payload) {
    const relationshipsWithDataInInclude = [
      'requirements',
      'accessibilities',
      'city_requirements',
      'county_requirements',
      'postal_code_requirements',
      'state_requirements',
    ];

    for (const program of payload.data.data) {
      for (const relationship of relationshipsWithDataInInclude) {
        program.relationships[relationship] = program.relationships[relationship]?.data.map((entry) => {
          const match = payload.data.included.find((item) => item.id === entry.id);
          return { id: match.id, ...match.attributes };
        });
      }
    }

    return payload.data.data;
  }

  function onError(error) {
    return error;
  }

  return request.then(onSuccess, onError);
}
