import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SpinIcon from 'common/spinners/SpinIcon';
import { Pager, Icon } from '@unite-us/ui';
import {
  Filter,
  FilterSingle,
  shapes,
} from '@unite-us/client-utils';
import FilterList from 'common/display/FilterList';
import { useFeatureFlag } from 'common/hooks';
import { setFilterSearchPlaceholder, setAsyncSearch } from '../utils/filter';
import SimpleCount from './SimpleCount';

const FilterBar = ({
  asyncSearch,
  filters,
  isFetching,
  itemLabel,
  items,
  topMargin,
  onFiltersChange,
  onItemClick,
  onMoreFiltersClick,
  onNextClick,
  onPrevClick,
  paging,
  showItemFilter,
  showSecondaryFilters,
  styles,
  dataTestId,
}) => {
  const hasPeopleMaterializedView = useFeatureFlag('cl-1292-clients-index-materialized-view');

  return (
    <div className="filter-bar" data-testid={dataTestId}>
      <div className="filter-bar__wrapper">
        <div className="filters">
          {
            _.map(_.compact(filters), (f) => {
              const {
                asyncSearch: filterAsyncSearch,
                searchPlaceholder,
              } = f;

              if (f.secondary) {
                return null;
              }

              return (
                f.single ? (
                  <FilterSingle
                    key={f.key}
                    name={f.name}
                    options={f.options}
                    value={f.value}
                    onFilterChange={(activeFilter) => onFiltersChange(f.key, activeFilter.value)}
                  />
                ) : (
                  <Filter
                    hideSelectAll={f.hideSelectAll || false}
                    key={f.key}
                    name={f.name}
                    pluralName={f.pluralName}
                    options={f.options}
                    onFiltersChange={(activeFilters, keySelected, callbacks) => {
                      onFiltersChange(f.key, activeFilters, callbacks);
                    }}
                    searchPlaceholder={searchPlaceholder || setFilterSearchPlaceholder(f)}
                    asyncSearch={filterAsyncSearch !== undefined ?
                      setAsyncSearch(f, filterAsyncSearch) :
                      setAsyncSearch(f, asyncSearch)}
                    filterKey={f.key}
                    uniqIdPrefix={f.key}
                  />
                )
              );
            })
          }
          {
            showSecondaryFilters && (
              <div className="filter-bar__more-options">
                <div
                  className="filter-bar__more-filters"
                  onClick={onMoreFiltersClick}
                  onKeyDown={() => { }}
                  role="link"
                  tabIndex={0}
                >
                  <Icon
                    className="filter-toggle__icon"
                    icon="IconFilter"
                    size={15}
                  />
                  <a
                    tabIndex={0}
                    className="ml-half filter-bar__more-options"
                    id="filter-bar-more-options"
                    role="button"
                  >
                    More Filters
                  </a>
                </div>
              </div>
            )
          }
        </div>
        <div className={topMargin ? 'mt-5 pager-container' : 'pager-container'}>
          <div className="inline-block">
            {
              isFetching && (
                <SpinIcon
                  iconStyle={styles.spinIconIconStyles}
                  style={styles.spinIconStyles}
                />
              )
            }
          </div>
          <div className="flex items-center">
            {hasPeopleMaterializedView && (
              <SimpleCount
                currentPage={paging.current_page}
                nextPage={paging.next_page}
                per={paging.per}
                pageLength={paging.page_length}
              />
            )}
            <Pager
              paging={paging}
              disabled={isFetching}
              hideCount={hasPeopleMaterializedView}
              onNextClick={onNextClick}
              onPrevClick={onPrevClick}
            />
          </div>
        </div>
      </div>

      {
        showItemFilter && (
          <FilterList
            className="filter-bar__alpha-filter-list mb-2 ml-2"
            items={items}
            itemLabel={itemLabel}
            onItemClick={onItemClick}
          />
        )
      }
    </div>
  );
};

FilterBar.propTypes = {
  filters: PropTypes.array,
  onFiltersChange: PropTypes.func,
  onMoreFiltersClick: PropTypes.func,
  paging: shapes.paging,
  topMargin: PropTypes.bool,
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func,
  showSecondaryFilters: PropTypes.bool,
  showItemFilter: PropTypes.bool.isRequired,
  items: PropTypes.array,
  itemLabel: PropTypes.string,
  styles: PropTypes.object.isRequired,
  asyncSearch: PropTypes.func,
  dataTestId: PropTypes.string,
};

FilterBar.defaultProps = {
  topMargin: true,
  showItemFilter: false,
  showSecondaryFilters: false,
  styles: {
    spinIconStyles: {
      marginTop: '7px',
      marginRight: '10px',
    },
    spinIconIconStyles: {
      height: '20px',
      width: '20px',
    },
  },
  onMoreFiltersClick: () => { },
  asyncSearch: undefined,
  dataTestId: '',
};

export default FilterBar;
