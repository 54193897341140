/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { get, isEmpty, uniq, isArray } from 'lodash';
import moment from 'moment';
import {
  useInvalidateQueries,
} from 'src/api/APIHooks';
import Notifier from 'common/helpers/Notifier';
import { browserHistory } from 'common/utils/browserHistory';
import SurveyJSFormRenderer from '@unite-us/surveyjs/dist/components/Renderer/SurveyJSFormRenderer';
// import buildResponses from '@unite-us/surveyjs/dist/components/Renderer/buildResponses';
import {
  BaseCard,
  BaseCardBody,
} from '@unite-us/ui';
import { screeningsApiClient } from 'src/api/config';
import { useElAssessment, EL_ASSESSMENT_QUERY_KEYS } from 'src/pages/facesheet/_id/eligibility/hooks/useEligibilityAssessments';
import {
  getIdentifiedNeedsHTML,
  formatDateToScreeningFormat,
  ELIGIBILITY_ASSESSMENT_STATUS,
  getEligibleServicesUtil,
} from 'src/pages/facesheet/_id/eligibility/utils';
import NotEligible from 'src/pages/facesheet/_id/eligibility/components/NotEligible';
import './EligibilityAssessment.scss';
import {
  submitAssessment,
  updateAssessment,
  useMyScreenings,
  USE_MY_SCREENINGS_KEY,
  USE_TEMPLATE_V2,
  useElAssessmentTemplateId,
  useFlagESMFEnabled,
  useTemplateV2,
  useFindConditions,
  getScreeningName,
} from 'src/pages/facesheet/_id/eligibility/hooks/eligibilityApi';
import { ELEMENT_DICTIONARY, OPTION_DICTIONARY } from './surveyjsTemplates/createElAssessTemplate';

const SURVEY_PAGE_NUMBER = 1; // set to 0 for page1, 1 for page2, etc.

/*
  Eligibility Assessment:
  - front-end form for eligibility assessment is created in SurveyJS
  - back-end form is fetched from Screenings 2.0 API, endpoint /templates/:id
  - The visibility rules are defined in the surveyjs form
  - question ids and question option ids come from the backend and are matches to surveyjs elements


  TO DO:
  - When submitting an eligibility assessment, we currently do not save the Screening ID
    that was selected in the form. This is important for tracking the screening associated
    with this assessment. The backend does not current support saving a freetext field.
*/

function buildResponses(survey) {
  const responses = [];
  Object.keys(survey.data).forEach((key) => {
    let value = survey.data[key];
    const questionModel = survey.getQuestionByValueName(key);
    if (isEmpty(questionModel)) { return; }
    const title = questionModel.title;
    let displayValue = value.toString();
    let valuesId = [];
    let choicesCustom = [];
    // Array of elements
    if (!isEmpty(questionModel.choices)) {
      if (!Array.isArray(value)) {
        value = [value];
      }

      if (Array.isArray(value)) {
        const choicesText = [];
        value.forEach((v) => {
          const choice = questionModel.choices.find((c) => c.value === v);
          if (!choice) {
            console.error('Choice not found', { value, questionModel });
            return;
          }
          choicesText.push(choice.text);
          if (choice.uuid) {
            valuesId.push(choice.uuid);
          }
          if (choice.custom) {
            choicesCustom.push(choice.custom);
          }
        });
        displayValue = choicesText.join(', ');
      }
    }

    if (questionModel.inputType === 'date') {
      displayValue = moment(value).format('MM/DD/YYYY');
    }

    responses.push({
      id: questionModel.uuid || null,
      name: key,
      title,
      valueId: isEmpty(valuesId) ? null : valuesId,
      value: value.toString(),
      displayValue,
      custom: choicesCustom,
    });
  });

  return responses;
}

const hideElementsAfterScreeningSelection = (surveyRef) => {
  const surveyPage = surveyRef.current.pages[SURVEY_PAGE_NUMBER];
  let isAfterSelect = false;
  surveyPage.elementsValue.forEach((element) => {
    if (isAfterSelect) {
      element.visible = false;
    }
    if (element.name === 'qIdentifiedNeedsHtml') {
      isAfterSelect = true;
    }
  });
}

// myScreenings is an array of AHC screenings fetched by `useMyScreenings`
const setOptionsForScreeningDropdown = (surveyRef, myScreenings) => {
  const survey = surveyRef.current;
  // get qSelectScreening element
  const element = survey.getQuestionByName('qSelectScreening');
  element.choices = myScreenings.map((screen) => {
    return {
    value: screen.id,
    text: screen.name || screen.id, // to be replaced with screen.name
    custom: {
      // question,
      // questionId: question.id,
      // elementName: element.name,
      option: {
        id: screen.id,
        text: screen.id,
        value: screen.id,
      }
    },
  }});
  element.type = 'dropdown';
  element.visible = false;
  element.visible = true;
};

const hideIdentifiedNeeds = (surveyRef) => {
  const surveyPage = surveyRef.current.pages[SURVEY_PAGE_NUMBER];
  const question = surveyPage.getQuestionByName('qIdentifiedNeedsHtml');
  const htmlElement = document.getElementById(question.id);
  if (htmlElement) {
    htmlElement.innerHTML = '';
  }
};

// const resetAfterScreeningSelections = (surveyRef) => {
//   const survey = surveyRef.current;
//   const surveyPage = surveyRef.current.pages[SURVEY_PAGE_NUMBER];

//   // reset other needs question
//   // survey.setValue('qOtherNeeds', []);
//   const otherNeedsElement = surveyPage.getQuestionByName('qOtherNeeds');
//   otherNeedsElement.choices = [];


//   // clear previous selections
//   survey.setValue('qReceiveHelp', null);
//   survey.setValue('qReceivingServices', []);
//   survey.setValue('qIsEnhancedPopulation', null);
//   survey.setValue('qSelectEnhanced', []);
//   survey.setValue('qDoesClientHaveClinicalConditions', null);
//   survey.setValue('qClinicalConditions', []);

//   const { all: allNeedQuestions } = getNeedQuestions();
//   for (let i = 0; i < allNeedQuestions.length; i++) {
//     allNeedQuestions[i].value = null;
//   }
// };

// const convertNeedToChoiceText = (need) => {
//   if (need === 'transportation') { return 'Transportation'; }
//   if (need === 'food') { return 'Food'; }
//   if (need === 'housing') { return 'Housing'; }
//   if (need === 'interpersonal_safety') { return 'Interpersonal Safety'; }
//   if (need === 'utilities') { return 'Utilities'; }
// };

const renderIdentifiedNeeds = (surveyRef, needs) => {
  const ALL_NEEDS_AVAILABLE = [
    'transportation',
    'food',
    'housing',
    'utilities',
    'unemployment',
    'education',
  ];
  needs = needs.filter((n) => ALL_NEEDS_AVAILABLE.includes(n));
  const surveyPage = surveyRef.current.pages[SURVEY_PAGE_NUMBER];
  const qIdentifiedNeeds = surveyPage.getQuestionByName('qIdentifiedNeedsHtml');
  const htmlElement = document.getElementById(qIdentifiedNeeds.id);

  if (htmlElement) {
    htmlElement.innerHTML = getIdentifiedNeedsHTML(needs);
  }
};

export function EligibilityAssessment(props) {
  const SURVEY_PAGE_NUMBER = 1; // set to 0 for page1, 1 for page2, etc.
  const [identifiedNeeds, setIdentifiedNeeds] = useState([]);
  const globalState = {
    identifiedNeeds,
  };
  const {
    contactId,
    setLoading,
    mode,
    assessmentId,
    employeeId,
  } = props;

  const inEditMode = mode === 'edit';
  const { data: assessment } = useElAssessment(
    assessmentId,
    { enabled: Boolean(inEditMode && assessmentId) }
  );
  const templateId = useElAssessmentTemplateId();
  const { data: templateData } = useTemplateV2({ templateId });
  // const formJSON = get(templateData, 'surveyjsTemplate');
  const [formJSON, setFormJSON] = useState(null);
  const {
    conditions,
    populations,
    isLoading: isLoadingConditions,
  } = useFindConditions(contactId);
  const surveyRef = useRef();

  useEffect(() => {
    const template = get(templateData, 'surveyjsTemplate');
    if (!assessment || !template || !surveyRef) { return; }
    const screeningSelected = get(assessment, 'data.screen.related_screen_id');
    if (screeningSelected) {
      const qSelectScreening = template.configuration.pages[1].elements.find((el) => el.name === 'qSelectScreening');
      qSelectScreening.type = 'text';
      qSelectScreening.value = screeningSelected;
      qSelectScreening.readOnly = true;
    }
    setFormJSON(template);
  }, [templateData, assessment]);

  // options.populateESMF is false when user opens a Draft form to avoid overwriting existing data
  const selectScreening = async (surveyRef, screeningId, options = { populateESMF: true }) => {
    const survey = surveyRef.current.pages[SURVEY_PAGE_NUMBER];
    const surveyCurrent = surveyRef.current;
    hideIdentifiedNeeds(surveyRef);
    const screening = await screeningsApiClient.get(`/screenings/${screeningId}`);
    // resetAfterScreeningSelections(surveyRef);
  
    const identifiedNeeds = get(screening, 'data.identified_needs', null);
    const needs = identifiedNeeds ? Object.keys(identifiedNeeds) : [];

    setIdentifiedNeeds([...needs]);
    globalState.identifiedNeeds = needs;

    if (!options.populateESMF) {
      return;
    }

    // ESMF:
    // pre-populate all identified conditions/enhanced populations from ESMF
    survey.getQuestionByName('qClinicalCriteria').value = conditions;
    survey.getQuestionByName('qEnhancedPopulations').value = populations;
    // survey.getQuestionByName('qEnhancedPopulations').value = ['Enrolled in a NYS-designated Health Home Program'];

    const enableAndSetToESMF = (surveyElementName) => {
      try {
        const element = survey.getQuestionByName(surveyElementName);
        const choices = element.choices;
        const ESMF_OPTION_TEXT = 'ESMF';
        const esmfOption = choices.find((c) => c.value === ESMF_OPTION_TEXT);
        esmfOption.custom.disabled = false;
        element.choices = JSON.parse(JSON.stringify(choices));
        element.value = ESMF_OPTION_TEXT;
      } catch (error) {
        console.error('Error enabling and setting to ESMF', error, { surveyElementName});
      }
    };

    conditions && conditions.forEach((condition) => {
      if (condition === OPTION_DICTIONARY.chronicCondition) {
        enableAndSetToESMF('qChronicConditionsVerification');
      }
      if (condition === OPTION_DICTIONARY.asthma) {
        enableAndSetToESMF('qAsthmaVerification');
      }
      if (condition === OPTION_DICTIONARY.enternalOrParenteral) {
        enableAndSetToESMF('qNutritionTherapyVerification');
      }
      if (condition === OPTION_DICTIONARY.institutionalSetting) {
        enableAndSetToESMF('qInstitutionalVerification');
      }
      if (condition === OPTION_DICTIONARY.heatRelatedIllness) {
        enableAndSetToESMF('qHeatIllnessVerification');
      }
      if (condition === OPTION_DICTIONARY.coldRelatedIllness) {
        enableAndSetToESMF('qColdIllnessVerification');
      }
      if (condition === OPTION_DICTIONARY.atRiskThermo) {
        enableAndSetToESMF('qThermoregulationRiskVerification');
      }
    });

    populations && populations.forEach((condition) => {
      if (condition === OPTION_DICTIONARY.medicaidHighUtilizer) {
        enableAndSetToESMF('qMedicaidVerification');
      }
      if (condition === OPTION_DICTIONARY.enrolledNYS) {
        enableAndSetToESMF('qHealthHomeVerification');
      }
      if (condition === OPTION_DICTIONARY.sud) {
        enableAndSetToESMF('qSUDVerificationMethod');
      }
      if (condition === OPTION_DICTIONARY.mentalHealth) {
        enableAndSetToESMF('qMentalHealthVerification');
      }
      if (condition === OPTION_DICTIONARY.idd) {
        enableAndSetToESMF('qIDDVerification');
      }
      if (condition === OPTION_DICTIONARY.pregnantAndPostpartum) {
        enableAndSetToESMF('qPregnancyVerificationMethod');
      }
      if (condition === OPTION_DICTIONARY.highRiskChildUnderEighteen) {
        enableAndSetToESMF('qChildVerificationMethod');
      }
      if (condition === OPTION_DICTIONARY.individualsChronicReleased) {
        enableAndSetToESMF('qConditionIncarcerationVerification');
      }
      if (condition === OPTION_DICTIONARY.juvenileJustice) {
        enableAndSetToESMF('qYouthVerification');
      }
    });

    return needs;
  };

  const questionElementMap = get(templateData, 'questionElementMap');
  const [eligibleForServices, setEligibleForServices] = useState([]);
  const invalidateQueries = useInvalidateQueries();
  const [isEditModeReady, setIsEditModeReady] = useState(false);
  const [showNotEligible,] = useState(false);
  const { data: screenings, isFetching: isFetchingMyScreenings } = useMyScreenings(contactId);
  const [eligibilityAssessmentTemplate, setEligibilityAssessmentTemplate] = useState(null);
  const divRef = useRef(null); // Create a reference for a DOM element

  const handleSetSurvey = (survey) => {
    surveyRef.current = survey;
    surveyRef.current.currentPageNo = SURVEY_PAGE_NUMBER;
  };

  const onValueChanged = (sender, options, checkAll = false) => {
    const survey = surveyRef.current.pages[SURVEY_PAGE_NUMBER];
    const surveyCurrent = surveyRef.current;
    const elementName = checkAll ? null : options.name;
    if (elementName === 'qSelectScreening') {
      const screeningId = options.value;
      if (screeningId) {
        selectScreening(surveyRef, screeningId, { populateESMF: true });
      }
    }
    
    const qSelectScreening = survey.getQuestionByName('qSelectScreening');
    const hasSelectedScreening = Boolean(qSelectScreening.value);

    const qEmploymentAssistance = survey.getQuestionByName('qEmploymentAssistance');
    const qHousingUtilitiesAssistance = survey.getQuestionByName('qHousingUtilitiesAssistance');
    const qFoodAssistance = survey.getQuestionByName('qFoodAssistance');
    const qEducationAssistance = survey.getQuestionByName('qEducationAssistance');
    const qTransportationAssistance = survey.getQuestionByName('qTransportationAssistance');

    const requireTransportationAssistance = qTransportationAssistance.value === 'Yes';
    const requireFoodAssistance = qFoodAssistance.value === 'Yes';
    const requireHousingUtilitiesAssistance = qHousingUtilitiesAssistance.value === 'Yes';
    const requireEmploymentAssistance = qEmploymentAssistance.value === 'Yes';
    // const requireEducationAssistance = qEducationAssistance.value === 'Yes';

    const isNeedsHeaderVisible = Boolean(
      requireTransportationAssistance ||
      requireFoodAssistance ||
      requireHousingUtilitiesAssistance ||
      requireEmploymentAssistance
      // || requireEducationAssistance
    );

    survey.getQuestionByName('hStepSix').visible = isNeedsHeaderVisible;

    // start of transportation questions
    if (elementName === 'qTransportationAssistance' || checkAll) {
      survey.getQuestionByName('hTransportation').visible = requireTransportationAssistance;
      
      const transportationQuestions = [
        'qTransportationAccess',
        'qTransportationTroubleReasons',
        'qTransportationEffect',
        'qTransportationHelp',
        'qTransportationServicesReceived',
        'qTransportationServicesDescription',
      ];
      for (let i = 0; i < transportationQuestions.length; i++) {
        survey.getQuestionByName(transportationQuestions[i]).visible = Boolean(requireTransportationAssistance);
      }
    }
    // end of transportation questions

    // start of food questions
    if (elementName === 'qFoodAssistance' || checkAll) {
      survey.getQuestionByName('hFood').visible = requireFoodAssistance;
      const foodQuestions = [
        'qFoodAssistanceType',
        'qFoodPreparationAbility',
        'qRefrigerationBreastMilk',
        'qRefrigerationMedication',
        'qNutritionalCounseling',
        'qWICProgram',
        'qSNAPBenefits',
        'qFoodServiceDuplicationCheck',
        'qHomeDeliveredMeals',
      ];
      for (let i = 0; i < foodQuestions.length; i++) {
        survey.getQuestionByName(foodQuestions[i]).visible = Boolean(requireFoodAssistance);
      }
    }
    // end of food questions

    // start of housing and utilities questions
    if (elementName === 'qHousingUtilitiesAssistance' || checkAll) {
      survey.getQuestionByName('hHousingUtilities').visible = Boolean(requireHousingUtilitiesAssistance);
      const housingUtilitiesQuestions = [
        'qLivingSituation',
        'qHousingProvider',
        'qHousingWaitlists',
        'qUtilitiesIssues',
        'qCareRecoveryNeed',
      ];

      const qCareRecoveryNeed = survey.getQuestionByName('qCareRecoveryNeed');
      const qHospitalRisk = survey.getQuestionByName('qHospitalRisk');

      qHospitalRisk.visible = Boolean(Boolean(qCareRecoveryNeed?.value === 'Yes'));

      for (let i = 0; i < housingUtilitiesQuestions.length; i++) {
        survey.getQuestionByName(housingUtilitiesQuestions[i]).visible = Boolean(requireHousingUtilitiesAssistance);
      }
    }
    const qHousingProvider = survey.getQuestionByName('qHousingProvider');
    const qSupportiveHousing = survey.getQuestionByName('qSupportiveHousing');
    const qHealthyHomesProgram = survey.getQuestionByName('qHealthyHomesProgram');

    qSupportiveHousing.visible = Boolean(qHousingProvider?.value === 'Yes');
    qHealthyHomesProgram.visible = Boolean(qHousingProvider?.value === 'Yes');

    const qUtilitiesIssues = survey.getQuestionByName('qUtilitiesIssues');
    const qUtilitiesIssuesTypes = survey.getQuestionByName('qUtilitiesIssuesTypes');
    const qUtilitiesAssistanceType = survey.getQuestionByName('qUtilitiesAssistanceType');
    const qUtilitiesProgramsReceived = survey.getQuestionByName('qUtilitiesProgramsReceived');

    if (qUtilitiesIssuesTypes) {
      qUtilitiesIssuesTypes.visible = Boolean(qUtilitiesIssues?.value === 'Yes');
    }
    if (qUtilitiesAssistanceType) {
      qUtilitiesAssistanceType.visible = Boolean(qUtilitiesIssues?.value === 'Yes');
    }
    if (qUtilitiesProgramsReceived) {
      qUtilitiesProgramsReceived.visible = Boolean(qUtilitiesIssues?.value === 'Yes');
    }

    const qUtilitiesProgramsDescription = survey.getQuestionByName('qUtilitiesProgramsDescription');
    if (qUtilitiesProgramsDescription) {
      qUtilitiesProgramsDescription.visible = Boolean(qUtilitiesProgramsReceived?.value === 'Yes');
    }

    if (elementName === 'qLivingSituation' || checkAll) {
      const qLivingSituation = survey.getQuestionByName('qLivingSituation');
      const qLivingValue = qLivingSituation?.value;
      const hasLivingSituationSelected = Boolean(qLivingValue);
      const hasSafeHousing = qLivingValue === "I have a stable and safe place to live with no problems.";
      survey.getQuestionByName('qHousingIssues').visible = Boolean(hasLivingSituationSelected && !hasSafeHousing);
      survey.getQuestionByName('qHousingSafetyConcerns').visible = Boolean(hasLivingSituationSelected && !hasSafeHousing);
    }

    if (elementName === 'qHousingSafetyConcerns' || checkAll) {
      const qHousingSafetyConcerns = survey.getQuestionByName('qHousingSafetyConcerns');
      if (qHousingSafetyConcerns?.value === "Yes") {
        survey.getQuestionByName('qHousingConcerns').visible = true;
      }
    }
    // end of housing and utilities questions

    // start of employment questions
    survey.getQuestionByName('hEmployment').visible = Boolean(requireEmploymentAssistance);
    const qEmploymentSituation = survey.getQuestionByName('qEmploymentSituation');
    qEmploymentSituation.visible = Boolean(requireEmploymentAssistance);

    const qWorkInterest = survey.getQuestionByName('qWorkInterest');
    qWorkInterest.visible = Boolean(
      qEmploymentSituation?.value === 'I am not currently employed and am looking for work.'
      || qEmploymentSituation?.value === 'I am employed but need more hours or a better job.'
    );
    // end of employment questions

    // start of additional needs

    // end of additional needs

    // start of Clinical Criteria
    survey.getQuestionByName('hAdditionalNeeds').visible = hasSelectedScreening;
    survey.getQuestionByName('hClinicalCriteriaHtml').visible = hasSelectedScreening;

    const qChildcareHelp = survey.getQuestionByName('qChildcareHelp');
    qChildcareHelp.visible = hasSelectedScreening;

    const qClinicalCriteria = survey.getQuestionByName('qClinicalCriteria');
    const qChronicConditionsVerification = survey.getQuestionByName('qChronicConditionsVerification');
    const qChronicConditionsProviderName = survey.getQuestionByName('qChronicConditionsProviderName');
    const qChronicConditionsProviderNPI = survey.getQuestionByName('qChronicConditionsProviderNPI');
    const qChronicConditionsAttestationStored = survey.getQuestionByName('qChronicConditionsAttestationStored');

    qClinicalCriteria.visible = hasSelectedScreening;
    const qClinicalCriteriaValues = get(qClinicalCriteria, 'value');

    qChronicConditionsVerification.visible = Boolean(qClinicalCriteriaValues.includes('Chronic Conditions'));
    qChronicConditionsProviderName.visible = qChronicConditionsVerification?.value === 'Provider Attestation';
    qChronicConditionsProviderNPI.visible = qChronicConditionsVerification?.value === 'Provider Attestation';
    qChronicConditionsAttestationStored.visible = qChronicConditionsVerification?.value === 'Provider Attestation';
    
    const qAsthmaVerification = survey.getQuestionByName('qAsthmaVerification');
    const qAsthmaProviderName = survey.getQuestionByName('qAsthmaProviderName');
    const qAsthmaProviderNPI = survey.getQuestionByName('qAsthmaProviderNPI');
    const qAsthmaAttestationStored = survey.getQuestionByName('qAsthmaAttestationStored');

    qAsthmaVerification.visible = Boolean(qClinicalCriteriaValues.includes('Uncontrolled Asthma'));
    qAsthmaProviderName.visible = Boolean(qAsthmaVerification?.value === 'Provider Attestation');
    qAsthmaProviderNPI.visible = Boolean(qAsthmaVerification?.value === 'Provider Attestation');
    qAsthmaAttestationStored.visible = Boolean(qAsthmaVerification?.value === 'Provider Attestation');

    const qNutritionTherapyVerification = survey.getQuestionByName('qNutritionTherapyVerification');
    const qNutritionTherapyProviderName = survey.getQuestionByName('qNutritionTherapyProviderName');
    const qNutritionTherapyProviderNPI = survey.getQuestionByName('qNutritionTherapyProviderNPI');
    const qNutritionTherapyAttestationStored = survey.getQuestionByName('qNutritionTherapyAttestationStored');

    qNutritionTherapyVerification.visible = Boolean(qClinicalCriteriaValues.includes('Require Enteral or Parenteral Nutrition Therapy'));
    qNutritionTherapyProviderName.visible = Boolean(qNutritionTherapyVerification?.value === 'Provider Attestation');
    qNutritionTherapyProviderNPI.visible = Boolean(qNutritionTherapyVerification?.value === 'Provider Attestation');
    qNutritionTherapyAttestationStored.visible = Boolean(qNutritionTherapyVerification?.value === 'Provider Attestation');

    const qInstitutionalVerification = survey.getQuestionByName('qInstitutionalVerification');
    const qInstitutionalProviderName = survey.getQuestionByName('qInstitutionalProviderName');
    const qInstitutionalProviderNPI = survey.getQuestionByName('qInstitutionalProviderNPI');
    const qInstitutionalAttestationStored = survey.getQuestionByName('qInstitutionalAttestationStored');

    qInstitutionalVerification.visible = Boolean(qClinicalCriteriaValues.includes('Currently in an Institutional Setting'));
    qInstitutionalProviderName.visible = Boolean(qInstitutionalVerification?.value === 'Provider Attestation');
    qInstitutionalProviderNPI.visible = Boolean(qInstitutionalVerification?.value === 'Provider Attestation');
    qInstitutionalAttestationStored.visible = Boolean(qInstitutionalVerification?.value === 'Provider Attestation');

    const qHeatIllnessVerification = survey.getQuestionByName('qHeatIllnessVerification');
    const qHeatIllnessProviderName = survey.getQuestionByName('qHeatIllnessProviderName');
    const qHeatIllnessProviderNPI = survey.getQuestionByName('qHeatIllnessProviderNPI');
    const qHeatIllnessAttestationStored = survey.getQuestionByName('qHeatIllnessAttestationStored');
    const qHeatIllnessAttestHome = survey.getQuestionByName('qHeatIllnessAttestHome');

    qHeatIllnessVerification.visible = Boolean(qClinicalCriteriaValues.includes('Experienced Heat-Related Illness Requiring ER or Urgent Care Visit'));
    qHeatIllnessProviderName.visible = Boolean(qHeatIllnessVerification?.value === 'Provider Attestation');
    qHeatIllnessProviderNPI.visible = Boolean(qHeatIllnessVerification?.value === 'Provider Attestation');
    qHeatIllnessAttestationStored.visible = Boolean(qHeatIllnessVerification?.value === 'Provider Attestation');
    qHeatIllnessAttestHome.visible = Boolean(qHeatIllnessVerification?.value === 'Member Attestation');

    const qColdIllnessVerification = survey.getQuestionByName('qColdIllnessVerification');
    const qColdIllnessProviderName = survey.getQuestionByName('qColdIllnessProviderName');
    const qColdIllnessProviderNPI = survey.getQuestionByName('qColdIllnessProviderNPI');
    const qColdIllnessAttestationStored = survey.getQuestionByName('qColdIllnessAttestationStored');
    const qColdIllnessAttestHome = survey.getQuestionByName('qColdIllnessAttestHome');

    qColdIllnessVerification.visible = Boolean(qClinicalCriteriaValues.includes('Experienced Cold-Related Illness Requiring ER or Urgent Care Visit'));
    qColdIllnessProviderName.visible = Boolean(qColdIllnessVerification?.value === 'Provider Attestation');
    qColdIllnessProviderNPI.visible = Boolean(qColdIllnessVerification?.value === 'Provider Attestation');
    qColdIllnessAttestationStored.visible = Boolean(qColdIllnessVerification?.value === 'Provider Attestation');
    qColdIllnessAttestHome.visible = Boolean(qColdIllnessVerification?.value === 'Member Attestation');

    const qThermoregulationRiskVerification = survey.getQuestionByName('qThermoregulationRiskVerification');
    const qThermoregulationRiskProviderName = survey.getQuestionByName('qThermoregulationRiskProviderName');
    const qThermoregulationRiskProviderNPI = survey.getQuestionByName('qThermoregulationRiskProviderNPI');
    const qThermoregulationRiskAttestationStored = survey.getQuestionByName('qThermoregulationRiskAttestationStored');

    qThermoregulationRiskVerification.visible = Boolean(qClinicalCriteriaValues.includes('At Risk of Thermoregulation Interference'));
    qThermoregulationRiskProviderName.visible = Boolean(qThermoregulationRiskVerification?.value === 'Provider Attestation');
    qThermoregulationRiskProviderNPI.visible = Boolean(qThermoregulationRiskVerification?.value === 'Provider Attestation');
    qThermoregulationRiskAttestationStored.visible = Boolean(qThermoregulationRiskVerification?.value === 'Provider Attestation');
    // end of Clinical Criteria

    // start of NYS Service Duplication Check
    survey.getQuestionByName('hServiceDuplicationCheckHtml').visible = hasSelectedScreening;
    const qServiceDuplicationCheck = survey.getQuestionByName('qServiceDuplicationCheck');
    const qOtherPrograms = survey.getQuestionByName('qOtherPrograms');
    const qAdditionalPrograms = survey.getQuestionByName('qAdditionalPrograms');

    qServiceDuplicationCheck.visible = hasSelectedScreening;
    qOtherPrograms.visible = hasSelectedScreening;
    qAdditionalPrograms.visible = qOtherPrograms?.value === 'Yes';

    // end of NYS Service Duplication Check

    // start of Step 8
    survey.getQuestionByName('hStepEightHtml').visible = hasSelectedScreening;
    const qConnectToServices = survey.getQuestionByName('qConnectToServices');
    qConnectToServices.visible = hasSelectedScreening;
    // end of Step 8

    const enhancedPopSelected = survey.getQuestionByName('qEnhancedPopulations').value;
    const isSelectedMedicaidUtilizer = enhancedPopSelected.includes(OPTION_DICTIONARY.medicaidHighUtilizer);
    const isSelectedNYS = enhancedPopSelected.includes(OPTION_DICTIONARY.enrolledNYS);
    const isSelectedSubstanceUse = enhancedPopSelected.includes(OPTION_DICTIONARY.sud);
    const isSelectedMentalHealth = enhancedPopSelected.includes(OPTION_DICTIONARY.mentalHealth);
    const isSelectedIntellectualDisabilities = enhancedPopSelected.includes(OPTION_DICTIONARY.idd);
    const isSelectedPregnantPostpartum = enhancedPopSelected.includes(OPTION_DICTIONARY.pregnantAndPostpartum);
    const isSelectedHighRiskChild = enhancedPopSelected.includes(OPTION_DICTIONARY.highRiskChildUnderEighteen);
    const isSelectedJuvenileYouth = enhancedPopSelected.includes(OPTION_DICTIONARY.juvenileJustice);
    const isSelectedChronicConditionIncarceration = enhancedPopSelected.includes(OPTION_DICTIONARY.individualsChronicReleased);
    const qMedicaidVerification = survey.getQuestionByName('qMedicaidVerification');
    if (qMedicaidVerification) {
      qMedicaidVerification.visible = Boolean(isSelectedMedicaidUtilizer);
    }

    const qHealthHomeVerification = survey.getQuestionByName('qHealthHomeVerification');
    qHealthHomeVerification.visible = Boolean(isSelectedNYS);
    
    const qIDDProviderName = survey.getQuestionByName('qIDDProviderName');
    const qIDDProviderNPI = survey.getQuestionByName('qIDDProviderNPI');
    const qIDDFormStored = survey.getQuestionByName('qIDDFormStored');

    const qIDDVerification = survey.getQuestionByName('qIDDVerification');
    qIDDVerification.visible = Boolean(isSelectedIntellectualDisabilities);

    qIDDProviderName.visible = Boolean(qIDDVerification?.value === 'Provider Attestation');
    qIDDProviderNPI.visible = Boolean(qIDDVerification?.value === 'Provider Attestation');
    qIDDFormStored.visible = Boolean(qIDDVerification?.value === 'Provider Attestation');

    const qPregnancyVerificationMethod = survey.getQuestionByName('qPregnancyVerificationMethod');
    const qMentalHealthVerification = survey.getQuestionByName('qMentalHealthVerification');
    const qSUDVerificationMethod = survey.getQuestionByName('qSUDVerificationMethod');
    const qChildVerificationMethod = survey.getQuestionByName('qChildVerificationMethod');
    const qConditionIncarcerationVerification = survey.getQuestionByName('qConditionIncarcerationVerification');
    const qYouthVerification = survey.getQuestionByName('qYouthVerification');

    if (elementName === 'qEnhancedPopulations' || checkAll) {
      // const enhancedPopSelected = options.value;

      // const isSelectedMedicaidUtilizer = enhancedPopSelected.includes('Medicaid High Utilizer');                     // HIDDEN because of ESMF requirements
      // const isSelectedHealthHome = enhancedPopSelected.includes('Enrolled in a NYS-designated Health Home Program'); // HIDDEN because of ESMF requirements

      qSUDVerificationMethod.visible = Boolean(isSelectedSubstanceUse); // ESMF option is not possible
      // pre-select the second option (Provider Attestation)

      qMentalHealthVerification.visible = Boolean(isSelectedMentalHealth); // ESMF option is not possible
      // pre-select the second option (Provider Attestation)
      // survey.getQuestionByName('qMentalHealthVerification').value = 'Provider Attestation';

      survey.getQuestionByName('qEstimatedDeliveryDate').visible = Boolean(isSelectedPregnantPostpartum);
      qPregnancyVerificationMethod.visible = Boolean(isSelectedPregnantPostpartum);
      
      qChildVerificationMethod.visible = Boolean(isSelectedHighRiskChild);  // ESMF option is not possible
      // pre-select the second option (Provider Attestation)
      // survey.getQuestionByName('qChildVerificationMethod').value = 'Provider Attestation';

      qConditionIncarcerationVerification.visible = Boolean(isSelectedChronicConditionIncarceration);
      survey.getQuestionByName('qConditionIncarcerationDocsStored').visible = Boolean(isSelectedChronicConditionIncarceration);

      qYouthVerification.visible = Boolean(isSelectedJuvenileYouth);
    }

    if (elementName === 'qSUDVerificationMethod' || checkAll) {
      const qSUDVerificationValue = qSUDVerificationMethod.value;
      const providerAttestationSelected = Boolean(qSUDVerificationValue === 'Provider Attestation');
      survey.getQuestionByName('qSUDProviderName').visible = Boolean(providerAttestationSelected);
      survey.getQuestionByName('qSUDProviderNPI').visible = Boolean(providerAttestationSelected);
      survey.getQuestionByName('qSUDAttestationStored').visible = Boolean(providerAttestationSelected);
    }

    if (elementName === 'qMentalHealthVerification' || checkAll) {
      const qMentalValue = qMentalHealthVerification?.value;
      const providerAttestationSelected = Boolean(qMentalValue === 'Provider Attestation');
      survey.getQuestionByName('qMentalHealthProviderName').visible = Boolean(providerAttestationSelected);
      survey.getQuestionByName('qMentalHealthProviderNPI').visible = Boolean(providerAttestationSelected);
      survey.getQuestionByName('qMentalHealthAttestationStored').visible = Boolean(providerAttestationSelected);
    }

    if (elementName === 'qPregnancyVerificationMethod' || checkAll) {
      const qPregnancyValue = qPregnancyVerificationMethod?.value;
      const providerAttestationSelected = Boolean(qPregnancyValue === 'Provider Attestation');
      const memberAttestationSelected = Boolean(qPregnancyValue === 'Member Attestation');
      survey.getQuestionByName('qPregnancyProviderName').visible = Boolean(providerAttestationSelected);
      survey.getQuestionByName('qPregnancyProviderNPI').visible = Boolean(providerAttestationSelected);
      survey.getQuestionByName('qPregnancyAttestationStored').visible = Boolean(providerAttestationSelected);
      survey.getQuestionByName('qPregnancyMemberAttestation').visible = Boolean(memberAttestationSelected);
    }

    if (elementName === 'qChildVerificationMethod' || checkAll) {
      const qChildValue = qChildVerificationMethod?.value;
      const providerAttestationSelected = Boolean(qChildValue === 'Provider Attestation');
      survey.getQuestionByName('qChildProviderName').visible = Boolean(providerAttestationSelected);
      survey.getQuestionByName('qChildProviderNPI').visible = Boolean(providerAttestationSelected);
      survey.getQuestionByName('qChildAttestationStored').visible = Boolean(providerAttestationSelected);
    }

    if (elementName === 'qConditionIncarcerationVerification' || checkAll) {
      const qConditionValue = qConditionIncarcerationVerification?.value;
      const memberDocumentationSelected = Boolean(qConditionValue === 'Member Documentation');
      survey.getQuestionByName('qConditionIncarcerationDocsStored').visible = Boolean(memberDocumentationSelected);
    }

    if (elementName === 'qYouthVerification' || checkAll) {
      const qYouthValue = qYouthVerification?.value;
      const providerAttestationSelected = Boolean(qYouthValue === 'Provider Attestation');
      const memberDocumentationSelected = Boolean(qYouthValue === 'Member Documentation');
      survey.getQuestionByName('qYouthProviderName').visible = Boolean(providerAttestationSelected);
      survey.getQuestionByName('qYouthProviderNPI').visible = Boolean(providerAttestationSelected);
      survey.getQuestionByName('qYouthAttestationStored').visible = Boolean(providerAttestationSelected);
      survey.getQuestionByName('qYouthDocsStored').visible = Boolean(memberDocumentationSelected);
    }
    
    const allElements = surveyCurrent.pages[SURVEY_PAGE_NUMBER].elements;

    allElements.forEach((question) => {
      if (!question.visible && question.value !== undefined) {
        question.clearValue();
      }
    });

    getEligibleServices();
  }

  useEffect(() => {
    if (!surveyRef || !surveyRef.current) { return; }
    const survey = surveyRef.current.pages[SURVEY_PAGE_NUMBER];

    if (!identifiedNeeds) { return; }
    renderIdentifiedNeeds(surveyRef, identifiedNeeds);

    const qEducationAssistance = survey.getQuestionByName('qEducationAssistance');
    qEducationAssistance.visible = Boolean(identifiedNeeds.includes('education'));

    const qEmploymentAssistance = survey.getQuestionByName('qEmploymentAssistance');
    qEmploymentAssistance.visible = Boolean(identifiedNeeds.includes('unemployment'));

    const qHousingUtilitiesAssistance = survey.getQuestionByName('qHousingUtilitiesAssistance');
    qHousingUtilitiesAssistance.visible = Boolean(
      identifiedNeeds.includes('housing') ||
      identifiedNeeds.includes('utilities')
    );

    const qFoodAssistance = survey.getQuestionByName('qFoodAssistance');
    qFoodAssistance.visible = Boolean(identifiedNeeds.includes('food'));

    const qTransportationAssistance = survey.getQuestionByName('qTransportationAssistance');
    qTransportationAssistance.visible = Boolean(identifiedNeeds.includes('transportation'));

    survey.getQuestionByName('qEnhancedPopulations').visible = true;
    survey.getQuestionByName('hEnhancedPopulationsHtml').visible = true;
  }, [identifiedNeeds]);

  const getEligibleServices = () => {
    if (!surveyRef || !surveyRef.current) { return; }
    const survey = surveyRef.current;
    
    const qSelectEnhanced = survey.getQuestionByName('qEnhancedPopulations');
    const enhancedPopulationMembershipKeys = get(qSelectEnhanced, 'value', []);
    const enhancedPopulationMembership = enhancedPopulationMembershipKeys.map((key) => {
      // remove ?. from ?.text when EA Template is updated with `Individuals with a Chronic Condition Released from Incarceration in the Past 90 Days`
      return qSelectEnhanced.choices.find((choice) => choice.value === key)?.text;
    });

    const qFoodAssistance = survey.getQuestionByName('qFoodAssistance');
    const qTransportationAssistance = survey.getQuestionByName('qTransportationAssistance');
    const qHousingUtilitiesAssistance = survey.getQuestionByName('qHousingUtilitiesAssistance');

    const hasTransportationNeed = qTransportationAssistance.value === 'Yes' &&
      globalState.identifiedNeeds.includes('transportation');
    const hasFoodNeed = qFoodAssistance.value === 'Yes' &&
      globalState.identifiedNeeds.includes('food');
    const hasHousingUtilitiesNeed = qHousingUtilitiesAssistance.value === 'Yes' &&
      globalState.identifiedNeeds.includes('housing') &&
      globalState.identifiedNeeds.includes('utilities');

    let snomedOptionsSelected = [];

    // const transportationElements = [
    //   'qTransportationAccess',
    //   'qTransportationTroubleReasons',
    //   'qTransportationEffect',
    //   'qTransportationHelp',
    //   'qTransportationServicesReceived',
    //   'qTransportationServicesDescription',
    // ];
    // transportationElements.forEach((name) => {
    //   const element = survey.getQuestionByName(name);
    //   element.choices.forEach((choice) => {
        
    //   })
    // });

    const qEducationAssistance = survey.getQuestionByName('qEducationAssistance');
    const qEducationAssistanceValues = get(qEducationAssistance, 'value', []);
    if (qEducationAssistanceValues.includes("Yes")) {
      snomedOptionsSelected.push('4506002');
    }

    const qTransportationAccess = survey.getQuestionByName('qTransportationAccess');
    if (qTransportationAccess?.value === 'Yes') {
      snomedOptionsSelected.push('713458007');
    }

    const qTransportationTroubleReasons = survey.getQuestionByName('qTransportationTroubleReasons');
    const qTransportationTroubleReasonsValues = get(qTransportationTroubleReasons, 'value', []);
    if (qTransportationTroubleReasonsValues.includes("I can't afford transportation costs.")) {
      snomedOptionsSelected.push('551761000000000');
    }
    if (qTransportationTroubleReasonsValues.includes("I don't have access to a vehicle.")) {
      snomedOptionsSelected.push('551741000124108');
    }
    if (qTransportationTroubleReasonsValues.includes("I don't have a valid driver's license.")) {
      snomedOptionsSelected.push('551721000124101');
    }
    if (qTransportationTroubleReasonsValues.includes("Public transportation isn't available where I live.")) {
      snomedOptionsSelected.push('611151000124107');
    }
    if (qTransportationTroubleReasonsValues.includes("Transportation options feel unsafe.")) {
      snomedOptionsSelected.push('551701000000000');
    }
    if (qTransportationTroubleReasonsValues.includes("It takes too long to get to where I need to go")) {
      snomedOptionsSelected.push('551711000124109');
    }
    if (qTransportationTroubleReasonsValues.includes("The places I want to go are too far away.")) {
      snomedOptionsSelected.push('160696009');
    }

    const qTransportationEffect = survey.getQuestionByName('qTransportationEffect');
    const qTransportationEffectValues = get(qTransportationEffect, 'value', []);
    if (qTransportationEffectValues.includes("It makes it hard for me to get food.")) {
      snomedOptionsSelected.push('611161000124109');
    }
    if (qTransportationEffectValues.includes("It makes it hard for me to get healthcare.")) {
      snomedOptionsSelected.push('551731000124103');
    }
    if (qTransportationEffectValues.includes("It makes it hard for me to access community services.")) {
      snomedOptionsSelected.push('551751000124105');
    }

    const qLivingSituation = survey.getQuestionByName('qLivingSituation');
    const qLivingSituationValues = get(qLivingSituation, 'value', []);
    if (qLivingSituationValues.includes("I have a place to live, but I'm having some problems with it.")) {
      snomedOptionsSelected.push('1156191002');
    }
    if (qLivingSituationValues.includes("I don't have a steady place to live right now.")) {
      snomedOptionsSelected.push('32911000');
    }

    const qHousingIssues = survey.getQuestionByName('qHousingIssues');
    const qHousingIssuesValues = get(qHousingIssues, 'value', []);
    if (qHousingIssuesValues.includes("I'm behind on rent or mortgage payments.")) {
      snomedOptionsSelected.push('1156195006');
    }
    if (qHousingIssuesValues.includes("I have to move frequently from place to place.")) {
      snomedOptionsSelected.push('1156193004');
    }
    if (qHousingIssuesValues.includes("My housing costs too much for me to afford.")) {
      snomedOptionsSelected.push('1187272007');
    }
    if (qHousingIssuesValues.includes("I'm worried about being evicted.")) {
      snomedOptionsSelected.push('1156196007');
    }
    if (qHousingIssuesValues.includes("I might lose my housing in the next two weeks.")) {
      snomedOptionsSelected.push('1156192009');
    }
    if (qHousingIssuesValues.includes("I've been homeless at some point in the past 12 months.")) {
      snomedOptionsSelected.push('1156194005');
    }
    if (qHousingIssuesValues.includes("I'm currently staying with friends or family temporarily.")) {
      snomedOptionsSelected.push('611551000124102');
    }
    if (qHousingIssuesValues.includes("I'm staying in a shelter or temporary housing.")) {
      snomedOptionsSelected.push('611131000124100');
    }
    if (qHousingIssuesValues.includes("I'm sleeping in a place not meant for living (e.g., outside, car).")) {
      snomedOptionsSelected.push('611551000124105');
    }
    if (qHousingIssuesValues.includes("I'm sleeping in a place not meant for living, but I feel safe.")) {
      snomedOptionsSelected.push('611551000124104');
    }
    
    const qHousingSafetyConcerns = survey.getQuestionByName('qHousingSafetyConcerns');
    const qHousingSafetyConcernsValues = get(qHousingSafetyConcerns, 'value', []);
    if (qHousingSafetyConcernsValues.includes("Yes")) {
      snomedOptionsSelected.push('105531004');
    }

    const qHousingConcerns = survey.getQuestionByName('qHousingConcerns');
    const qHousingConcernsValues = get(qHousingConcerns, 'value', []);
    if (qHousingConcernsValues.includes("Mold or mildew is present.")) {
      snomedOptionsSelected.push('224255009');
    }
    if (qHousingConcernsValues.includes("Possible lead paint or pipes.")) {
      snomedOptionsSelected.push('1197631001');
    }
    if (qHousingConcernsValues.includes("No heating or cooling when needed.")) {
      snomedOptionsSelected.push('105535008');
    }
    if (qHousingConcernsValues.includes("Bugs or rodents like mice or rats.")) {
      snomedOptionsSelected.push('1162585007');
    }
    if (qHousingConcernsValues.includes("No access to water, electricity, or other utilities.")) {
      snomedOptionsSelected.push('105531004');
    }
    if (qHousingConcernsValues.includes("Kitchen or cooking equipment is inadequate.")) {
      snomedOptionsSelected.push('1197634009');
    }
    if (qHousingConcernsValues.includes("Smoke detectors don't work.")) {
      snomedOptionsSelected.push('1197640002');
    }

    const qUtilitiesIssuesTypes = survey.getQuestionByName('qUtilitiesIssuesTypes');
    const qUtilitiesIssuesTypesValues = get(qUtilitiesIssuesTypes, 'value', []);
    if (qUtilitiesIssuesTypesValues.includes("My electricity has been shut off.")) {
      snomedOptionsSelected.push('105536009');
    }
    if (qUtilitiesIssuesTypesValues.includes("I don't have running water in my home.")) {
      snomedOptionsSelected.push('5491000175103');
    }
    if (qUtilitiesIssuesTypesValues.includes("I can't get electricity because I can't afford it.")) {
      snomedOptionsSelected.push('671391000124106');
    }
    if (qUtilitiesIssuesTypesValues.includes("I can't heat my home because I can't afford it.")) {
      snomedOptionsSelected.push('671371000124105');
    }
    if (qUtilitiesIssuesTypesValues.includes("I can't cool my home because I can't afford it.")) {
      snomedOptionsSelected.push('671371000124105');
    }

    const qEmploymentSituation = survey.getQuestionByName('qEmploymentSituation');
    const qEmploymentSituationValue = get(qEmploymentSituation, 'value', []);
    if (qEmploymentSituationValue.includes("I am not currently employed and am looking for work.")) {
      snomedOptionsSelected.push('73438004');
    }
    if (qEmploymentSituationValue.includes("I am employed but need more hours or a better job.")) {
      snomedOptionsSelected.push('75148009');
    }
    if (qEmploymentSituationValue.includes("I am not employed and not looking for work.")) {
      snomedOptionsSelected.push('73438004');
    }

    snomedOptionsSelected = uniq(snomedOptionsSelected);



    // const qTransportationNeed2 = survey.getQuestionByName('qTransportationNeed2');
    // const transporationNeedsTwoKeys = get(qTransportationNeed2, 'value', []);
    // const transporationNeeds = transporationNeedsTwoKeys.map((key) => {
    //   return qTransportationNeed2.choices.find((choice) => choice.value === key)
    // });
    let eligibleServices = [];

    // Transportation need
    if (hasTransportationNeed) {
      // const snomedOptionsSelected = transporationNeeds.map((option) => option.custom.option.snomed);
      const eligibleSnomedOptions = snomedOptionsSelected.includes("551761000000000") ||
        snomedOptionsSelected.includes("551741000124108") ||
        snomedOptionsSelected.includes("713458007") ||
        snomedOptionsSelected.includes("551721000124101") ||
        snomedOptionsSelected.includes("551711000124109") ||
        snomedOptionsSelected.includes("160696009") ||
        snomedOptionsSelected.includes("611151000124107") ||
        snomedOptionsSelected.includes("551701000000000") ||
        snomedOptionsSelected.includes("611161000124109") ||
        snomedOptionsSelected.includes("551731000124103") ||
        snomedOptionsSelected.includes("551751000124105");
      const eligibleTransportationEnhancedPopulationMembership = enhancedPopulationMembership.includes("Medicaid High Utilizer") || // V2
        enhancedPopulationMembership.includes("Enrolled in a NYS-designated Health Home Program") || // V2
        enhancedPopulationMembership.includes("Individuals Facing Substance Use Challenges") || // V2
        enhancedPopulationMembership.includes("Individuals with Serious Mental Health Needs") || // V2
        enhancedPopulationMembership.includes("Individuals with Intellectual or Developmental Disabilities") || // V2
        enhancedPopulationMembership.includes("Pregnant or Postpartum Person") || // V2

        
        enhancedPopulationMembership.includes(OPTION_DICTIONARY.individualsChronicReleased) ||
        enhancedPopulationMembership.includes("Juvenile Justice-Involved, Foster Care Youth and Those Under Kinship Care") || // no match
        enhancedPopulationMembership.includes("High-Risk Children Under 6 Years Old") ||
        enhancedPopulationMembership.includes("Children Under 18 Years Old with a Chronic Condition"); // no match
      if (eligibleSnomedOptions && eligibleTransportationEnhancedPopulationMembership) {
        eligibleServices.push('Private Transportation - Care Management');
        eligibleServices.push('Private Transportation - HRSN Services Only');
        eligibleServices.push('Public Transportation - Care Management');
        eligibleServices.push('Public Transportation - HRSN Services Only');
      }
    }

    // Food need
    if (hasFoodNeed) {
      // Food Education
      const eligibleFoodEducation = enhancedPopulationMembership.includes("Medicaid High Utilizer") || // no match
        enhancedPopulationMembership.includes("Individuals Enrolled in a NYS Health Home") ||
        enhancedPopulationMembership.includes("Individuals with Substance Use Disorders") ||
        enhancedPopulationMembership.includes("Individuals with Serious Mental Illness") ||
        enhancedPopulationMembership.includes("Individuals with an Intellectual or Developmental Disability") ||
        enhancedPopulationMembership.includes("Pregnant and Postpartum Persons") ||
        enhancedPopulationMembership.includes(OPTION_DICTIONARY.individualsChronicReleased) ||
        enhancedPopulationMembership.includes("Juvenile Justice-Involved, Foster Care Youth and Those Under Kinship Care") || // we dont have this as an enhanced population option
        enhancedPopulationMembership.includes("High-Risk Children Under 6 Years Old") ||
        enhancedPopulationMembership.includes("Children Under 18 Years Old with a Chronic Condition"); // this doesn't match the option we have: "Children Under 18 Years Old with a Chronic Condition"
      if (eligibleFoodEducation) {
        eligibleServices.push('Food education, guidance, and counseling');
      }

      // Medically Tailor Meals
      const eligibleMedicallyTailorMeals = enhancedPopulationMembership.includes("Medicaid High Utilizer") ||
        enhancedPopulationMembership.includes("Individuals Enrolled in a NYS Health Home") ||
        enhancedPopulationMembership.includes("Individuals with Substance Use Disorders") ||
        enhancedPopulationMembership.includes("Individuals with Serious Mental Illness") ||
        enhancedPopulationMembership.includes("Individuals with an Intellectual or Developmental Disability") ||
        enhancedPopulationMembership.includes("Pregnant and Postpartum Persons") ||
        enhancedPopulationMembership.includes(OPTION_DICTIONARY.individualsChronicReleased) ||
        enhancedPopulationMembership.includes("Juvenile Justice-Involved, Foster Care Youth and Those Under Kinship Care") ||
        enhancedPopulationMembership.includes("High-Risk Children Under 6 Years Old") ||
        enhancedPopulationMembership.includes("Children Under 18 Years Old with a Chronic Condition");  // no match
      if (eligibleMedicallyTailorMeals) {
        eligibleServices.push('Medically Tailored Meals');
      }

      // Cooking Supplies
      const eligibleCookingSupplies = enhancedPopulationMembership.includes("Medicaid High Utilizer") ||   // no match
        enhancedPopulationMembership.includes("Individuals Enrolled in a NYS Health Home") ||
        enhancedPopulationMembership.includes("Individuals with Substance Use Disorders") ||
        enhancedPopulationMembership.includes("Individuals with Serious Mental Illness") ||
        enhancedPopulationMembership.includes("Individuals with an Intellectual or Developmental Disability") ||
        enhancedPopulationMembership.includes("Pregnant and Postpartum Persons") ||
        enhancedPopulationMembership.includes(OPTION_DICTIONARY.individualsChronicReleased) ||
        enhancedPopulationMembership.includes("Juvenile Justice-Involved, Foster Care Youth and Those Under Kinship Care") ||
        enhancedPopulationMembership.includes("High-Risk Children Under 6 Years Old") ||
        enhancedPopulationMembership.includes("Children Under 18 Years Old with a Chronic Condition"); // no match
      if (eligibleCookingSupplies) {
        eligibleServices.push('Provision of kitchenware');
        eligibleServices.push('Provision of microwave oven');
        eligibleServices.push('Provision of refrigerator');
      }

      // Provision of food prescriptions
      const eligibleProvisionOfFoodPrescriptions = enhancedPopulationMembership.includes("Medicaid High Utilizer") ||  // no match
        enhancedPopulationMembership.includes("Individuals Enrolled in a NYS Health Home") ||
        enhancedPopulationMembership.includes("Individuals with Substance Use Disorders") ||
        enhancedPopulationMembership.includes("Individuals with Serious Mental Illness") ||
        enhancedPopulationMembership.includes("Individuals with an Intellectual or Developmental Disability") ||
        enhancedPopulationMembership.includes("Pregnant and Postpartum Persons") ||
        enhancedPopulationMembership.includes(OPTION_DICTIONARY.individualsChronicReleased) ||
        enhancedPopulationMembership.includes("Juvenile Justice-Involved, Foster Care Youth and Those Under Kinship Care") || // no match
        enhancedPopulationMembership.includes("High-Risk Children Under 6 Years Old") ||
        enhancedPopulationMembership.includes("Children Under 18 Years Old with a Chronic Condition");  // no match
      if (eligibleProvisionOfFoodPrescriptions) {
        eligibleServices.push('Provision of food prescription');
        eligibleServices.push('Provision of food voucher');
      }

      // Pantry Stocking
      const eligiblePantryStocking = enhancedPopulationMembership.includes("Pregnant and Postpartum Persons") ||
        enhancedPopulationMembership.includes("High-Risk Children Under 6 Years Old") ||
        enhancedPopulationMembership.includes("Children Under 18 Years Old with a Chronic Condition");  // no match
      if (eligiblePantryStocking) {
        eligibleServices.push('Provision of food');
      }
    }

    setEligibleForServices(eligibleServices);
    return eligibleServices;
  };

  const buildAnswersPayload = () => {
    const survey = surveyRef.current;

    const responses = buildResponses(surveyRef.current);
    const answers = responses
      .filter((response) => {
        // if (['qSelectScreening'].includes(response.name)) {
        //   return false;
        // }
        return true;
      })
      .map((response) => {
        const elementName = response.name;

        if (['qEstimatedDeliveryDate'].includes(elementName)) {
          return {
            question_id: response.id,
            value: response.value ? moment(response.value).format('YYYY-MM-DDTHH:mm:ss[Z]') : null,
          };
        }

        let option_id = null;
        const optionsSelected = response.custom.map((c) => c.option.uuid || c.option.id);
        const questionType = get(response, 'custom[0].question.type', '');
        option_id = optionsSelected;
        if (questionType === '' || questionType === 'text') {
          return {
            question_id: response.id,
            value: response.value,
          };
        }
        if (questionType === 'select_multiple') {
          option_id = Array.isArray(optionsSelected) ? optionsSelected : [optionsSelected];
        } else if (optionsSelected.length > 1) {
          option_id = optionsSelected;
        } else {
          option_id = optionsSelected[0];
        }
        return {
          question_id: response.id,
          option_id,
        }
      });

    return answers;
  };

  const getSelectedScreeningId = () => {
    const survey = surveyRef.current;
    const qSelectScreening = survey.getQuestionByName('qSelectScreening');
    return get(qSelectScreening, 'value');
  };

  const submit = async () => {
    try {
      const survey = surveyRef.current;
      
      const surveyPage = survey.pages[SURVEY_PAGE_NUMBER];
      // validates form and displays errors/warnings on the UI for required questions
      const validateForm  = () => {
        let isFormValid = true;
        surveyPage.questions.forEach(function (question) {
          if (question.hasErrors(true)) {
            isFormValid = false;
          }
        });
        return isFormValid;
      };

      const isValid = validateForm();

      if (!isValid) {
        Notifier.dispatch(400, 'Please ensure all required information is provided.');
        return;
      }
      setLoading(true);

      const answers = buildAnswersPayload();
      const related_screen_id = getSelectedScreeningId();

      const eligible_services = getEligibleServicesUtil(assessment, identifiedNeeds);

      const response = await updateAssessment({
        personId: contactId,
        employeeId,
        status: ELIGIBILITY_ASSESSMENT_STATUS.COMPLETE,
        answers,
        related_screen_id,
        outreach_status: 'success',
        templateId,
        consent: 'accepted',
        assessmentId,
        eligible_services,
      });

      invalidateQueries(USE_MY_SCREENINGS_KEY)
      invalidateQueries(USE_TEMPLATE_V2)
      invalidateQueries(EL_ASSESSMENT_QUERY_KEYS.ASSESSMENTS);
      invalidateQueries(EL_ASSESSMENT_QUERY_KEYS.ASSESSMENT);

      const screenId = get(response, 'data.screen.id');
      setLoading(false);
      if (screenId) {
        Notifier.dispatch(200, 'Eligibility Assessment Complete')
        browserHistory.push(`/facesheet/${contactId}/eligibility/view/${screenId}`);
        return;
      }
      Notifier.dispatch(200, 'We ran into an issue, please try again.');
    } catch (error) {
      console.error('EligibilityAssessment.submit', error);
      // rollbar error here

      invalidateQueries(USE_MY_SCREENINGS_KEY)
      invalidateQueries(USE_TEMPLATE_V2)
      invalidateQueries(EL_ASSESSMENT_QUERY_KEYS.ASSESSMENTS);
      invalidateQueries(EL_ASSESSMENT_QUERY_KEYS.ASSESSMENT);

      setLoading(false);
    }
  };

  const saveDraft = async () => {
    try {
      setLoading(true);
      const answers = buildAnswersPayload();

      const eligible_services = getEligibleServicesUtil(assessment, identifiedNeeds);
      const response = await updateAssessment({
        personId: contactId,
        employeeId,
        status: ELIGIBILITY_ASSESSMENT_STATUS.DRAFT,
        answers,
        related_screen_id: getSelectedScreeningId(),
        outreach_status: 'success',
        templateId,
        consent: 'accepted',
        assessmentId,
        eligible_services,
      });

      invalidateQueries(USE_MY_SCREENINGS_KEY)
      invalidateQueries(USE_TEMPLATE_V2)
      invalidateQueries(EL_ASSESSMENT_QUERY_KEYS.ASSESSMENTS);
      invalidateQueries(EL_ASSESSMENT_QUERY_KEYS.ASSESSMENT);

      const screenId = get(response, 'data.screen.id');
      setLoading(false);
      if (screenId) {
        browserHistory.push(`/facesheet/${contactId}/eligibility/view/${screenId}`);
        Notifier.dispatch(200, 'Eligibility Assessment Saved as Draft');
        return;
      }
      Notifier.dispatch(200, 'We ran into an issue, please try again.');
    } catch (error) {
      console.error('EligibilityAssessment.saveDraft', error);

      invalidateQueries(USE_MY_SCREENINGS_KEY)
      invalidateQueries(USE_TEMPLATE_V2)
      invalidateQueries(EL_ASSESSMENT_QUERY_KEYS.ASSESSMENTS);
      invalidateQueries(EL_ASSESSMENT_QUERY_KEYS.ASSESSMENT);

      setLoading(false);
    }
  };

  const cancel = () => {
    browserHistory.push(`/facesheet/${contactId}/eligibility/all`);
  };

  const populateForm = async (assessment) => {
    try {
      const survey = surveyRef.current;
      const questionsWithAnswers = get(assessment, 'data.screen.questions');

      const surveyData = {};

      survey.pages[SURVEY_PAGE_NUMBER].elements.forEach((element) => {
        const elementType = element.getType();
        const found = questionsWithAnswers.find((q) => q.primary_text === element.title);
        if (!formJSON || !found || (!found.answer && !found.answers)) { return; }

        const value = get(found, 'answer.value', null);
        const rValues = get(found, 'answers', null);
        const values = rValues && rValues.length ? rValues.map((a) => a.value) : null;
        if (elementType === 'dropdown' && value) {
          surveyData[element.name] = value;
        }
        if (elementType === 'text' && value) {
          surveyData[element.name] = value;
        }
        if (elementType === 'dropdown-multiselect' && values) {
          surveyData[element.name] = values;
        }
      });

      Object.keys(surveyData).forEach((key) => {
        if (isArray(surveyData[key])) {
          return surveyData[key] = surveyData[key].map((s) => s.replace(/'/g, ''));
        }
        if (surveyData[key]) {
          surveyData[key] = surveyData[key].replace(/'/g, '');
        }
      });
      if (surveyData.qEstimatedDeliveryDate) {
        surveyData.qEstimatedDeliveryDate = moment(surveyData.qEstimatedDeliveryDate).toDate();
      }
      const selectedScreeningId = get(assessment, 'data.screen.related_screen_id');
      if (surveyData.qSelectScreening) {
        surveyData.qSelectScreening = getScreeningName(get(assessment, 'data.screen'));
      }
      survey.data = surveyData;
      if (surveyData.qSelectScreening) {
        await selectScreening(surveyRef, selectedScreeningId, { populateESMF: false });
      } else {
        hideElementsAfterScreeningSelection(surveyRef);
      }
      setOptionsForScreeningDropdown(surveyRef, screenings);
      onValueChanged(null, null, true);
    } catch (error) {
      // console.log('populateForm', error);
    }
  };

  useEffect(() => {
    if (!assessment || !surveyRef || !surveyRef.current || !screenings) { return; }
    populateForm(assessment);
  }, [assessment, screenings, formJSON]);

  if (!formJSON || isLoadingConditions) { return null; }

  return (
    <div className="eligibility-assessment">
      <BaseCard className="bg-white">
        <div
          className='border border-solid border-medium-border-blue border-l-0 border-r-0 border-t-0'
        >
          <div className="px-5 py-8">
            <h2 className="text-2xl font-bold mb-2">Eligibility Assessment</h2>
            <p className="text-md">This form is to determine eligibility for HRSN services, if the client is not eligible, you can proceed to send referrals for regular client services.</p>
          </div>
        </div>

        <div className="px-6">
          <BaseCardBody>
            <SurveyJSFormRenderer
              formData={formJSON}
              formSubmission={null}
              editMode={true}
              handleSetSurveyModel={handleSetSurvey}
              onValueChanged={onValueChanged}
            />

            {
              showNotEligible ? (
                <NotEligible />
              ) : null
            }

            {/* {
              eligibleForServices && eligibleForServices.length ? (
                <div>
                  <h4 className="text-lg font-bold mt-8 mb-4">Client is eligible for services:</h4>
                  {
                    eligibleForServices.map((service, index) => (
                      <p className="ml-4">{ service }</p>
                    ))
                  }
                </div>
              ) : null
            } */}

            <div
              className="flex justify-between items-center mb-6 mt-3"
            >
              <button
                onClick={cancel}
                className="h-12 mr-4 px-16 py-3 border border-solid border-light-border-blue rounded text-action-darker-blue">
                <span className="inline-block align-middle">Cancel</span>
              </button>

              <div className="flex space-x-4">
                <button
                  onClick={saveDraft}
                  className="h-12 px-16 py-3 border border-solid border-medium-border-blue rounded text-action-darker-blue"
                >
                  <span className="inline-block align-middle mr-2">
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.9643 3.5L13 0.535714C12.7143 0.25 12.1786 0 11.7857 0H2.21429C1.25 0 0.5 0.785714 0.5 1.71429V14.2857C0.5 15.25 1.25 16 2.21429 16H14.7857C15.7143 16 16.5 15.25 16.5 14.2857V4.71429C16.5 4.32143 16.25 3.78571 15.9643 3.5ZM8.5 13.7143C7.21429 13.7143 6.21429 12.7143 6.21429 11.4286C6.21429 10.1786 7.21429 9.14286 8.5 9.14286C9.75 9.14286 10.7857 10.1786 10.7857 11.4286C10.7857 12.7143 9.75 13.7143 8.5 13.7143ZM11.9286 2.85714V6.42857C11.9286 6.67857 11.7143 6.85714 11.5 6.85714H3.21429C2.96429 6.85714 2.78571 6.67857 2.78571 6.42857V2.71429C2.78571 2.5 2.96429 2.28571 3.21429 2.28571H11.3571C11.4643 2.28571 11.5714 2.35714 11.6786 2.42857L11.7857 2.53571C11.8571 2.60714 11.9286 2.75 11.9286 2.85714Z" fill="#4467AB"/>
                    </svg>
                  </span>
                  <span className="inline-block align-middle">Save Draft</span>
                </button>
                
                <button
                  onClick={submit}
                  className="h-12 px-16 py-3 bg-action-blue rounded text-white"
                >
                  <span className="inline-block align-middle text-white">
                    Submit Assessment
                  </span>
                </button>
              </div>

            </div>
          </BaseCardBody>
        </div>
      </BaseCard>
      <div ref={divRef} style={{ display: "none" }}></div>
    </div>
  );
}

EligibilityAssessment.propTypes = {
  setLoading: PropTypes.func.isRequired,
  contactId: PropTypes.string.isRequired,
  children: PropTypes.node,
};

EligibilityAssessment.defaultProps = {
  children: null,
};

export default EligibilityAssessment;
