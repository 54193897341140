import { AUTH_URL } from 'src/config/env/env.config';

const isTrainingEnv = AUTH_URL.includes('training'); // NOTE: expected value: https://app.auth.uniteustraining.com
const isDevelopmentEnv = AUTH_URL.includes('dev'); // NOTE: expected value: https://app.auth.uniteusdev.com

// eslint-disable-next-line import/no-mutable-exports
let FeatureFlagDevTools = () => null;

if (process.env.NODE_ENV === 'development' || isTrainingEnv || isDevelopmentEnv) {
  // eslint-disable-next-line global-require
  FeatureFlagDevTools = require('./FeatureFlagsDevTools').default;
}

export default FeatureFlagDevTools;
