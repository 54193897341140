import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import RelationshipHeader from './RelationshipHeader';

const DISPLAY_NAME = 'relationship-card';

const RelationshipCard = ({
  children, onAddClick, className, title, addButtonLabel,
}) => (
  <div className={classNames(DISPLAY_NAME, className)}>
    <RelationshipHeader
      title={title}
      onAddClick={onAddClick}
      addButtonLabel={addButtonLabel}
    />
    {children}
  </div>
);

RelationshipCard.propTypes = {
  addButtonLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  onAddClick: PropTypes.func,
  className: PropTypes.string,
};

RelationshipCard.defaultProps = {
  className: '',
  addButtonLabel: '',
  onAddClick: null,
};

export default RelationshipCard;
