import React from 'react';
import PropTypes from 'prop-types';

const SimpleCount = (props) => {
  const {
    currentPage,
    per,
    pageLength,
    nextPage,
  } = props;

  if (!currentPage || !per || !pageLength) {
    return null;
  }

  const base = ((currentPage - 1) * per);
  const start = base + 1;
  const end = nextPage ? currentPage * per : (base + pageLength);

  return (
    <span>
      {`${start}-${end}`}
    </span>
  );
};

export default SimpleCount;

SimpleCount.propTypes = {
  currentPage: PropTypes.number.isRequired,
  per: PropTypes.number.isRequired,
  pageLength: PropTypes.number.isRequired,
  nextPage: PropTypes.number,
};

SimpleCount.defaultProps = {
  nextPage: null,
};
