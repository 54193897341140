import { useState } from 'react';
import {
  useFind,
  useFindRecord,
  useCreateRecord,
  useUpdateRecord,
  useInvalidateQueries,
  useSetRelationship,
  useDeleteRelationship,
  useDeleteRecord,
} from 'src/api/APIHooks';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { SERVICE_AREA_TYPE_NATIONAL, SERVICE_AREA_TYPE_STATE } from 'components/Groups/constants';
import { ORG_SETTINGS } from 'src/common/utils/EventTracker/utils/eventConstants';
import useTrackEvent from 'src/components/Organization/utils/useTrackEvent';
import { defaultQueryConfig, defaultMutationConfig } from './apiHookOptions';

export const usePrograms = (providerId, options = {}) => useFind('program', {
  provider: providerId,
}, {
  queryConfig: {
    ...defaultQueryConfig,
    enabled: Boolean(providerId),
    placeholderData: undefined,
    select: (data) => {
      const programs = defaultQueryConfig.select(data);
      const { excludeDefault, excludeDeactivated, excludeProgramsWithFsps } = options;
      if (!excludeDefault && !excludeDeactivated && !excludeProgramsWithFsps) {
        return programs;
      }

      return programs.filter((program) => {
        let include = true;

        if (excludeDefault) {
          // Todo: do we need to check the name here?
          include = !(program.default && program.name === 'Referred Out of Network');
        }

        if (excludeDeactivated && include) {
          include = program.is_active;
        }

        if (excludeProgramsWithFsps && include) {
          include = !program.fee_schedule_program;
        }

        return include;
      });
    },
  },
  customParams: {
    include_discrete_eligibility_fields: true,
    include: 'postal_code_requirements,county_requirements,city_requirements,state_requirements,requirements',
    page: { size: 150 },
  },
});

export const useFindProgram = ({ programId }) => useFindRecord('program', programId, {
  queryConfig: {
    ...defaultQueryConfig,
    placeholderData: undefined,
  },
  customParams: {
    include_discrete_eligibility_fields: true,
    include: 'postal_code_requirements,county_requirements,city_requirements,state_requirements,requirements',
  },
});

export const useCreateProgram = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useCreateRecord(
    'program',
    {
      mutationConfig: {
        onSuccess: () => {
          invalidateQueries('program');
          invalidateQueries('provider');
        },
        ...defaultMutationConfig,
        ...mutationConfig,
      },
    },
  );
};

export const useUpdateProgram = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useUpdateRecord('program', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('program');
        invalidateQueries('provider');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useFindProgramServiceAreas = ({ programId }) => useFind('service_area', {
  program: programId,
}, {
  queryConfig: {
    enabled: Boolean(programId),
    ...defaultQueryConfig,
    placeholderData: undefined,
  },
});

export const useCreateServiceArea = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useCreateRecord(
    'service_area',
    {
      mutationConfig: {
        onSuccess: () => {
          invalidateQueries('service_area');
          invalidateQueries('program');
        },
        ...defaultMutationConfig,
        ...mutationConfig,
      },
    },
  );
};

export const useDeleteServiceArea = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useDeleteRecord('service_area', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('service_area');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useSetProgramLocation = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useSetRelationship('program', 'location', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('program');
        invalidateQueries('location');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useDeleteProgramLocation = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useDeleteRelationship('program', 'location', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('program');
        invalidateQueries('location');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useSubmitProgramAndLocations = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const { updateRecord: updateProgram } = useUpdateProgram();
  const { createRecord: createProgram } = useCreateProgram();
  const { setRelationship: addProgramLocations } = useSetProgramLocation();
  const { deleteRelationship: deleteProgramLocations } = useDeleteProgramLocation();
  const { createRecord: addServiceArea } = useCreateServiceArea();
  const { deleteRecord: deleteServiceArea } = useDeleteServiceArea();
  // Define update program
  const trackEventData = useTrackEvent();
  const submit = async (values, updateProgramId, userHasBackOffice, currentProviderName) => {
  const orgName = currentProviderName;
  const deleteLocationIds = values.locations
      .filter((location) => !location.selected && location.submitChange)
      .map((filterdLocation) => filterdLocation.location.id);
    const addLocationIds = values.locations
      .filter((location) => location.selected && location.submitChange)
      .map((filterdLocation) => filterdLocation.location.id);
    // delete locations, service_areas from programs payload
    const updatedValues = { ...values };
    delete updatedValues.locations;
    delete updatedValues.service_areas_national;
    delete updatedValues.service_areas_states;

    const promises = [];
    let isCreate = true;
    const programName = updatedValues.name;
    let programId = updateProgramId;
    if (!programId && !isError) {
      const programResponse = await createProgram(updatedValues);
      if (isHttpSuccess(programResponse?.status)) {
        programId = programResponse.data.data.id;
      }
    } else {
      isCreate = false;
      promises.push(updateProgram(programId, updatedValues));
    }

    const createServiceAreas = values.service_areas_states
      .filter((sa) => sa.selected && sa.submitChange)
      .map((sa) => ({
        state_abbreviation: sa.state_abbreviation,
        service_area_type: SERVICE_AREA_TYPE_STATE,
        program: programId,
      }));

    const deleteServiceAreaIds = values.service_areas_states
      .filter((sa) => !sa.selected && sa.submitChange)
      .map((sa) => (sa.id));

    if (values.service_areas_national?.submitChange) {
      if (values.service_areas_national.selected) {
        createServiceAreas.push({
          state_abbreviation: '',
          service_area_type: SERVICE_AREA_TYPE_NATIONAL,
          program: programId,
        });
      } else if (!values.service_areas_national.selected && values.service_areas_national.id) {
        deleteServiceAreaIds.push(values.service_areas_national.id);
      }
    }

    if (!programId) {
      setIsError(true);
      return;
    }

    if (addLocationIds.length) {
      promises.push(
        addProgramLocations(programId, addLocationIds),
      );
    }

    if (deleteLocationIds.length) {
      promises.push(
        deleteProgramLocations(programId, deleteLocationIds),
      );
    }

    if (deleteServiceAreaIds.length) {
      const deleteServiceAreasPromises = [];
      deleteServiceAreaIds.forEach((sA) => {
        deleteServiceAreasPromises.push(
          deleteServiceArea(sA, {}),
        );
      });
      setIsError(false);
      setIsSuccess(false);
      const updates = await Promise.all(deleteServiceAreasPromises);

      if (updates.every((data) => isHttpSuccess(data.status))) {
        setIsSuccess(true);
      } else {
        setIsError(true);
      }
    }

    if (createServiceAreas.length) {
      createServiceAreas.forEach((sA) => {
        promises.push(
          addServiceArea(sA),
        );
      });
    }

    setIsError(false);
    setIsSuccess(false);
    const updates = await Promise.all(promises);

    if (updates.every((data) => isHttpSuccess(data.status))) {
      // Create
      if (!isError && !userHasBackOffice && isCreate) {
        trackEventData(
          ORG_SETTINGS.createdProgram,
          {
            created_program_id: programId,
            program_name_that_was_added: programName,
            organization_name: orgName,
          },
        );
      }
      // Update
      if (!isError && !userHasBackOffice && !isCreate) {
        trackEventData(
          ORG_SETTINGS.editedProgram,
          {
            updated_program_id: programId,
            program_name_that_was_edited: programName,
            organization_name: orgName,
          },
        );
      }

      setIsSuccess(true);
    } else {
      setIsError(true);
    }
  };

  return {
    submit,
    isSuccess,
    isError,
  };
};

export const useFindProgramRequirementOptions =
  () => useFind('program_requirement_options', {}, {
    queryConfig: {
      ...defaultQueryConfig,
      placeholderData: undefined,
    },
  });
