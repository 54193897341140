import PropTypes from 'prop-types';
import React from 'react';
import { trackEvent } from '@unite-us/client-utils';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  showNCDisplay,
  showNCTracks,
  hasEnrollmentsWQ,
  requireSCCStartDate,
  hasPaymentsUserAccess,
  hasLimitedGenderOptions,
  hasClientIdStandardization,
  pays5525ShowCurrentInsuranceCoverage,
  cl787SexualityFields,
  pays3513PreventOverlappingRequests,
  cl1018PreferredLanguagesFields,
} from 'common/utils/FeatureFlags/flags';
import featureFlag from 'common/utils/FeatureFlag/FeatureFlag';
import { hasFeatureRole } from 'common/utils/Roles/hasFeatureRole';
import { AppClientProfile } from '@unite-us/app-client-profile';
import Notifier from 'common/helpers/Notifier';
import fetchGroupContact from 'src/actions/Contact/Group/fetchGroupContact';
import { coreApi, apiDefault, corePhase3ClientLegacy } from 'src/api/config';
import * as apiHooks from 'src/api/APIHooks';
import { CORE_BASE_URL, GOOGLE_MAPS_API_KEY } from 'src/config/env/env.config';
import './FacesheetProfile.scss';

export const FacesheetProfile = (props) => {
  const {
    contact = {},
    groupId,
    networkId,
    setHeaderContact,
    ncDisplayOnly,
    token,
    currentEmployee,
    hasEnrollments,
    requireStartDate,
    isPaymentsUser,
    shouldShowNCTracks,
    limitedGenderOptions,
    clientIdStandardizationEnabled,
    hasPays5525ShowCurrentInsuranceCoverage,
    shouldShowSexualityFields,
    shouldPreventOverlappingRequests,
    shouldShowPreferredLanguages,
    hasSocialCareNavigation,
  } = props;

  if (_.isEmpty(contact)) {
    return null;
  }

  return (
    <AppClientProfile
      contact={contact}
      groupId={groupId}
      networkId={networkId}
      token={token}
      currentEmployee={currentEmployee.id}
      callbacks={{
        onClientSaved: async ({ message = 'Client Successfully Updated' } = {}) => {
          const response = await props.fetchGroupContact(groupId, contact.id);
          setHeaderContact(response?.data?.data);
          Notifier.dispatch(200, message);
        },
        toast: {
          success: (message) => Notifier.dispatch(200, message),
          error: (message) => Notifier.dispatch(400, message),
          warn: (message) => Notifier.dispatch(100, message),
        },
      }}
      adapters={{
        coreApi,
        coreApiClientBase: apiDefault,
        legacyClient: corePhase3ClientLegacy,
      }}
      hooks={{
        ...apiHooks,
      }}
      trackEvent={trackEvent}
      flags={{
        ncDisplayOnly,
        'enrollment-wq': hasEnrollments,
        'pays-1554-nctracks': shouldShowNCTracks,
        'pays-2525-temp-scc-start-at': requireStartDate,
        'pays-5525-show-current-insurance-coverage': hasPays5525ShowCurrentInsuranceCoverage,
        'cl-787-sexuality-fields': shouldShowSexualityFields,
        'pays-3513-prevent-overlapping-scc-and-enrollment-requests': shouldPreventOverlappingRequests,
        'cl-1018-preferred-language': shouldShowPreferredLanguages,
        isPaymentsUser,
        limitedGenderOptions,
        clientIdStandardizationEnabled,
        hasSocialCareNavigation,
      }}
      constants={{
        CORE_BASE_URL,
        GOOGLE_MAPS_API_KEY,
      }}
    />
  );
};

FacesheetProfile.propTypes = {
  contact: PropTypes.shape({
    has_military: PropTypes.bool.isRequired,
    full_name: PropTypes.string.isRequired,
    household: PropTypes.shape({
      total: PropTypes.number,
    }).isRequired,
  }).isRequired,
  enums: PropTypes.shape({
    people: PropTypes.shape({
      marital_status: PropTypes.array.isRequired,
    }).isRequired,
    contact_data: PropTypes.shape({
      communication_preferences: PropTypes.shape({
        methods_of_contact: PropTypes.array.isRequired,
      }),
    }).isRequired,
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  networkId: PropTypes.string.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  setHeaderContact: PropTypes.func.isRequired,
  ncDisplayOnly: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  currentEmployee: PropTypes.object.isRequired,
  fetchGroupContact: PropTypes.func.isRequired,
  hasEnrollments: PropTypes.bool.isRequired,
  requireStartDate: PropTypes.bool.isRequired,
  isPaymentsUser: PropTypes.bool.isRequired,
  shouldShowNCTracks: PropTypes.bool.isRequired,
  limitedGenderOptions: PropTypes.bool.isRequired,
  clientIdStandardizationEnabled: PropTypes.bool.isRequired,
  hasPays5525ShowCurrentInsuranceCoverage: PropTypes.bool.isRequired,
  shouldShowSexualityFields: PropTypes.bool.isRequired,
  shouldPreventOverlappingRequests: PropTypes.bool.isRequired,
  shouldShowPreferredLanguages: PropTypes.bool.isRequired,
  hasSocialCareNavigation: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    contacts,
    session: { enums, groupId, networkId },
  } = state;

  const contactId = _.get(ownProps, 'params.id');
  const contact = _.find(_.get(contacts, 'contacts', []), { id: contactId });
  const ncDisplayOnly = showNCDisplay(state);
  const hasEnrollments = hasEnrollmentsWQ(state);
  const requireStartDate = requireSCCStartDate(state);
  const isPaymentsUser = hasPaymentsUserAccess(state);
  const limitedGenderOptions = hasLimitedGenderOptions(state);
  const clientIdStandardizationEnabled = hasClientIdStandardization(state);
  const shouldShowSexualityFields = cl787SexualityFields(state);
  const currentEmployee = state.globalState.currentEmployee;
  const token = state.session.access_token;
  const shouldShowNCTracks = showNCTracks(state);
  const hasPays5525ShowCurrentInsuranceCoverage = pays5525ShowCurrentInsuranceCoverage(state);
  const shouldPreventOverlappingRequests = pays3513PreventOverlappingRequests(state);
  const shouldShowPreferredLanguages = cl1018PreferredLanguagesFields(state);
  const hasSocialCareNavigation = hasFeatureRole({
    employeeRoles: currentEmployee?.roles,
    targetRoleKey: 'social_care_navigation',
  });

  return {
    contact,
    enums,
    networkId,
    groupId,
    ncDisplayOnly,
    currentEmployee,
    token,
    hasEnrollments,
    requireStartDate,
    isPaymentsUser,
    shouldShowNCTracks,
    limitedGenderOptions,
    clientIdStandardizationEnabled,
    hasPays5525ShowCurrentInsuranceCoverage,
    shouldShowSexualityFields,
    shouldPreventOverlappingRequests,
    shouldShowPreferredLanguages,
    hasSocialCareNavigation,
  };
}

export default featureFlag(connect(
  mapStateToProps,
  { fetchGroupContact },
)(FacesheetProfile));
