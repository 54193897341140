import {
  DASHBOARD_FETCH_CONTACTS,
  SET_DASHBOARD_FETCHING,
} from 'actions';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import Notifier from 'common/helpers/Notifier';
import { unsetDashboardFetching } from 'actions/Dashboard';
import { coreApi } from 'src/api/config';
import { get, isEmpty, isEqual } from 'lodash';
import { cl1292clientsIndexMaterializedView } from 'common/utils/FeatureFlags/flags';

const defaultOptions = {
  sidx: 'last_name,first_name',
  sord: 'asc',
  filters: {},
  page: 1,
};

const onError = (error) => {
  Notifier.handleErrors(error);
  return error;
};

const fetchDashboardContacts = (groupId, options = defaultOptions, networkId, isCC = false) => (
  async (dispatch, getState) => {
    try {
      const careCoordinatorUsers = get(options, 'filters.care_coordinator_users', []);
      const careCoordinators = careCoordinatorUsers.filter((c) => c !== 'none');
      const noneAssigned = careCoordinatorUsers.includes('none');
      const flagged = get(options, 'filters.flagged');

      dispatch({
        type: SET_DASHBOARD_FETCHING,
        target: options.target,
        filters: options.filters,
        cancel: undefined,
      });

      const state = getState();
      const clientsIndexMaterializedView = cl1292clientsIndexMaterializedView(state);
      let resource = 'person';
      if (clientsIndexMaterializedView) {
        resource = 'people_view';
      }

      const response = await coreApi.query(resource, {
        last_name: options.filters.last_name_starts_with_letter,
        ...(isCC ?
          { 'client_relationships.provider.networks': networkId } : { 'client_relationships.provider': groupId }
        ),
        ...(!isEqual(flagged, 'none') ? {
          'client_relationships.flagged': options.filters.flagged,
          'client_relationships.provider': groupId,
        } : null),
        ...(!isEmpty(careCoordinators) ? {
          'client_relationships.care_coordinator': careCoordinators.join(','),
          'client_relationships.provider': groupId,
        } : null),
        ...(noneAssigned ? { 'client_relationships.has_care_coordinator': false } : null),
      }, {
        page: options.page,
        sort: options.sidx,
        customParams: {
          fields: {
            person: 'first_name,last_name,phone_numbers,email_addresses',
          },
        },
      });
      if (!response || !isHttpSuccess(response.status)) {
        return onError(response);
      }
      const payload = response;
      dispatch({
        type: DASHBOARD_FETCH_CONTACTS,
        payload: response,
        target: options.target,
        filters: options.filters,
      });

      dispatch(unsetDashboardFetching(payload, options.target));
      return payload;
    } catch (error) {
      return onError(error);
    }
  }
);

export default fetchDashboardContacts;
