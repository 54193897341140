import React from 'react';
import PropTypes from 'prop-types';
import { SearchableSelectField } from 'components/Backoffice/form/SearchableSelectField';
import { SelectField } from 'components/Backoffice/form/SelectField';
import { Icon } from '@unite-us/ui';
import { SearchableField } from 'components/Backoffice/form/SearchableField';
import useFeatureFlag from '../../../common/hooks/useFeatureFlag';

const FilterSelectors = ({
  filterValues,
  filterOptions,
  resetFilters,
  onUpdateFilter,
  setRequestTypeFilter,
  setSccPlanFilter,
  setDaysSinceLastUpdatedFilter,
  setOpenAllFiltersDrawer,
  setClientNameFilter,
}) => {
  const pays6096UpdateEWQDesign = useFeatureFlag('pays-6096-update-ewq-design');
  const clearFilters = () => {
    resetFilters();
    onUpdateFilter();
  };
  return (
    <div className="flex items-start items-center space-x-4">
      <SelectField
        className="w-64"
        label="Request Type"
        placeholder="Choose Type"
        options={filterOptions.requestType}
        value={filterValues.requestType ?? ''}
        onChange={({ value }) => {
          setRequestTypeFilter(value);
          onUpdateFilter();
        }}
        clearable
      />
      { pays6096UpdateEWQDesign && (
        <SearchableField
          className="w-64"
          label="Client Name"
          placeholder="Search for a client"
          onChange={({ value }) => {
            setClientNameFilter(value);
          }}
          searchQuery={filterValues.clientName ?? ''}
          onInputChange={setClientNameFilter}
          clearable
        />
      )}
      <SearchableSelectField
        className="w-100"
        label="Social Care Coverage Plan"
        placeholder="Choose Plan"
        value={filterValues.sccPlan ?? ''}
        onChange={({ value }) => {
          setSccPlanFilter(value);
          onUpdateFilter();
        }}
        options={filterOptions.sccPlan}
        truncateOptions={false}
        clearable
      />
      {
        pays6096UpdateEWQDesign ? (
          <button
            type="button"
            aria-label="All Filters"
            className="min-w-max pt-8 md:pt-6 lg:pt-6 lg:max-xlg:6 xl:pt-8 "
            onClick={() => setOpenAllFiltersDrawer(true)}
          >
            <Icon
              icon="IconFilter"
              ariaLabel="All Filters"
              color="#2C405A"
            />
            <span className="ml-1">All Filters</span>
          </button>
        ) : (
          <>
            <SelectField
              className="w-64"
              label="Last Updated"
              placeholder="Choose Last Updated"
              value={filterValues.daysSinceLastUpdated ?? ''}
              onChange={({ value }) => {
                setDaysSinceLastUpdatedFilter(value);
                onUpdateFilter();
              }}
              options={filterOptions.daysSinceLastUpdated}
              clearable
            />
            <button
              aria-label="Clear all filters"
              className="mt-5 text-action-blue"
              type="button"
              onClick={clearFilters}
            >
              <span className="text-action-blue hover:text-text-blue focus:outline-none hover:text-text-blue">
                Clear all
              </span>
            </button>
          </>
        )
      }
    </div>
  );
};

FilterSelectors.propTypes = {
  filterValues: PropTypes.shape({
    requestType: PropTypes.string,
    sccPlan: PropTypes.string,
    daysSinceLastUpdated: PropTypes.string,
    clientName: PropTypes.string,
  }).isRequired,
  filterOptions: PropTypes.shape({
    requestType: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    sccPlan: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    daysSinceLastUpdated: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  resetFilters: PropTypes.func.isRequired,
  onUpdateFilter: PropTypes.func.isRequired,
  setRequestTypeFilter: PropTypes.func.isRequired,
  setSccPlanFilter: PropTypes.func.isRequired,
  setDaysSinceLastUpdatedFilter: PropTypes.func.isRequired,
  setOpenAllFiltersDrawer: PropTypes.func.isRequired,
  setClientNameFilter: PropTypes.func.isRequired,
};

export default FilterSelectors;
