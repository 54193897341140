/* eslint-disable no-alert */
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Dialog } from '@unite-us/ui';

import { FEATURE_FLAG_UPDATE } from 'src/actions';
import { Toggle } from '../../Toggle';

const FeatureFlagDevTools = ({ featureFlags, dispatch }) => {
  const dialogRef = useRef();

  const handleUpdate = (key, value) => {
    const newFlag = { [_.camelCase(key)]: value };
    const updatedFlags = { ...featureFlags, ...newFlag };

    dispatch({
      type: FEATURE_FLAG_UPDATE,
      payload: { flags: updatedFlags },
    });
  };

  const handleUpdateFlag = (flagName) => () => {
    const currentValue = featureFlags[flagName];

    let newValue;
    if (typeof currentValue === 'boolean') {
      newValue = !currentValue;
    } else if (typeof currentValue === 'string') {
      newValue = prompt(`Enter new value for ${flagName}`, currentValue);
    } else if (typeof currentValue === 'number') {
      const result = prompt(`Enter new number value for ${flagName}`, currentValue);
      newValue = result === null ? null : Number(result);
    } else if (typeof currentValue === 'object' && currentValue !== null) {
      newValue = JSON.parse(prompt(`Enter new JSON value for ${flagName}`, JSON.stringify(currentValue)));
    } else {
      newValue = currentValue;
    }

    if (newValue === null) {
      return;
    }

    if (currentValue === newValue) {
      return;
    }

    handleUpdate(flagName, newValue);
  };

  const openModal = () => {
    dialogRef.current.openDialog();
  };

  const closeDialog = () => {
    dialogRef.current.closeDialog();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'D') {
        openModal();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div style={{ height: 0 }}>
      <Dialog
        id="feature-flags-dialog"
        ref={dialogRef}
        title="Feature Flags Dev Tools"
        onClose={closeDialog}
        dialogContentStyles={{ padding: '1rem' }}
      >
        <div className="p-4">
          {Object.keys(featureFlags).map((flag) => (
            <div key={flag} className="flex items-center mb-4">
              <span className="mr-2 font-bold">{_.kebabCase(flag)}:</span>
              <span className="mr-2">{JSON.stringify(featureFlags[flag])}</span>
              {typeof featureFlags[flag] === 'boolean' ? (
                <Toggle
                  checked={Boolean(featureFlags[flag])}
                  value={Boolean(featureFlags[flag])}
                  onChange={handleUpdateFlag(flag)}
                />
              ) : (
                <button type="button" onClick={handleUpdateFlag(flag)}>
                  Edit
                </button>
              )}
            </div>
          ))}
        </div>
      </Dialog>
    </div>
  );
};

FeatureFlagDevTools.propTypes = {
  featureFlags: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.object, PropTypes.string]),
  ).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  featureFlags: state.flags,
});

export default connect(mapStateToProps)(FeatureFlagDevTools);
