import { useState, useCallback } from 'react';

const useEnrollmentDrawer = ({
  enrollmentRequests,
  totalPageCount,
  pageNumber,
  setPageNumber,
  pageSize,
}) => {
  const [open, setOpen] = useState(false);
  const [clickedIdx, setClickedIdx] = useState();

  const clickedRequest = enrollmentRequests[clickedIdx];
  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === totalPageCount;
  const isFirstRequest = clickedIdx === 0;
  const isLastRequest = clickedIdx === enrollmentRequests.length - 1;

  const openDrawer = (idx) => {
    setClickedIdx(idx);
    setOpen(true);
  };

  const getRequest = useCallback((increment) => () => {
    if (
      (increment > 0 && isLastRequest && !isLastPage) ||
      (increment < 0 && isFirstRequest && !isFirstPage)
    ) {
      setPageNumber(pageNumber + increment);
      setClickedIdx(increment > 0 ? 0 : pageSize - 1);
    } else {
      setClickedIdx(clickedIdx + increment);
    }
  }, [
    clickedIdx,
    enrollmentRequests,
    pageNumber,
    pageSize,
  ]);

  return {
    open,
    openDrawer,
    clickedRequest,
    clickedIdx,
    getNextRequest: isLastRequest && isLastPage ? undefined : getRequest(1),
    getPrevRequest: isFirstRequest && isFirstPage ? undefined : getRequest(-1),
    setOpen,
  };
};

export default useEnrollmentDrawer;
