import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BaseCard, BaseCardBody } from '@unite-us/ui';
import { updateGlobalState } from 'actions/Global/globalActions';
import { browserHistory } from 'common/utils/browserHistory';
import { CONSENT_APP_URL } from 'src/config/env/env.config';
import RequestOrUploadConsentForm from 'src/components/InformedConsent/components/RequestOrUploadConsentForm';
import { showTranslationsNewLanguages } from 'common/utils/FeatureFlags/flags';
import { LanguageSelector } from '@unite-us/app-components';
import { get, find } from 'lodash';
import 'src/components/InformedConsent/stylesheets/informedConsent.scss';

function getStateFromProps(props) {
  const {
    location: { query }, params: { personId },
  } = props;
  return {
    allowSkipConsent: query.allowSkipConsent !== 'false',
    contactId: personId,
    to: query.to,
    showConsentApp: false,
    showConsentAppAttestation: false,
    fullName: query.fullName,
    consentAppUrl: '',
    currentLanguage: props.language,
    field: {},
    registerField: null,
  };
}

class RequestOrUploadConsent extends Component {
  constructor(props) {
    super(props);
    this.state = getStateFromProps(props);

    this.onNavigateAway = this.onNavigateAway.bind(this);
    this.hideConsent = this.hideConsent.bind(this);
    this.showConsent = this.showConsent.bind(this);
    this.setConsentAttestation = this.setConsentAttestation.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.getConsentAppUrl = this.getConsentAppUrl.bind(this);
  }

  componentDidUpdate(prevProps) {
    const differentPath = this.props.location.pathname !== prevProps.location.pathname;
    if (differentPath) {
      this.setState(getStateFromProps(this.props));
    }
  }

  handleOnChange(val, field, registerField) {
    const { consentAppUrl } = this.state;
    const indexToReplace = consentAppUrl.lastIndexOf('language=') + 'language'.length;
    if (indexToReplace !== -1) {
      const trimURL = consentAppUrl.substring(0, indexToReplace);
      const newURL = trimURL.concat(`=${val}`);
      this.props.updateGlobalState({
        language: val,
      });
      this.setState({
        consentAppUrl: newURL,
        currentLanguage: val,
        field,
        registerField,
      });
    }
  }

  onNavigateAway() {
    const { contactId, to } = this.state;
    browserHistory.push(to || `/facesheet/${contactId}`);
  }

  getConsentAppUrl(groupId, contactId, contact, consentAttestation) {
    const { currentEmployee } = this.props;
    if (!contactId) {
      return;
    }
    const submission_method = consentAttestation === true ? 'attestation' : '';
    this.setState({
      // eslint-disable-next-line
      consentAppUrl: `${CONSENT_APP_URL}?person_id=${contactId}&first_name=${contact.first_name}&last_name=${contact.last_name}&employee_id=${currentEmployee.id}&employee_fullname=${currentEmployee.full_name}&submission_method=${submission_method}&form-only&language=${this.state.currentLanguage}`,
      showConsentAppAttestation: consentAttestation === true,
    });
  }

  setConsentAttestation(value) {
    this.setState({ showConsentAppAttestation: value });
  }

  showConsent(field, registerField) {
    this.setState({
      showConsentApp: true,
      field,
      registerField,
    });
  }

  hideConsent() {
    this.setState({ showConsentApp: false });
  }

  render() {
    const {
      allowSkipConsent,
      contactId,
      fullName,
      showConsentApp,
      consentAppUrl,
      field,
      registerField,
      showConsentAppAttestation,
    } = this.state;
    const {
      shouldShowTranslationsNewLanguages,
    } = this.props;

    const Title = () => {
      if (showConsentApp && !showConsentAppAttestation) {
        return (
          <div className="flex justify-between">
            <div className="pl-2">Informed Consent</div>
            <LanguageSelector
              // eslint-disable-next-line react/no-this-in-sfc
              onChange={this.handleOnChange}
              field={field}
              registerField={registerField}
              translationsNewLanguageFlag={shouldShowTranslationsNewLanguages}
            />
          </div>
        );
      }
      return (
        <div className="h-10">
          <div className="pt-2 pl-2">Informed Consent</div>
        </div>
      );
    };
    return (
      <div className="consent-request row">
        <BaseCard className="consent-container">
          <header
            className="title flex justify-between items-center px-4"
            style={{
              borderBottom: '1px solid #C7D8E8',
              backgroundColor: 'white',
            }}
          >
            <span className="capitalize text-lg my-4 w-full">
              {Title()}
            </span>
          </header>
          <BaseCardBody withPadding>
            <RequestOrUploadConsentForm
              allowSkipConsent={allowSkipConsent}
              contactId={contactId}
              fullName={fullName}
              hideConsent={this.hideConsent}
              onNavigateAway={this.onNavigateAway}
              showConsent={this.showConsent}
              showConsentApp={showConsentApp}
              setConsentAttestation={this.setConsentAttestation}
              showConsentAppAttestation={this.state.showConsentAppAttestation}
              handleOnChange={this.handleOnChange}
              consentAppUrl={consentAppUrl}
              getConsentAppUrl={this.getConsentAppUrl}
            />
          </BaseCardBody>
        </BaseCard>
      </div>
    );
  }
}

RequestOrUploadConsent.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      allowSkipConsent: PropTypes.string,
      fullName: PropTypes.string,
      to: PropTypes.string,
    }).isRequired,
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  params: PropTypes.shape({
    personId: PropTypes.string.isRequired,
  }).isRequired,
  contact: PropTypes.shape({
    id: PropTypes.string,
  }),
  currentEmployee: PropTypes.object.isRequired,
  shouldShowTranslationsNewLanguages: PropTypes.bool,
  updateGlobalState: PropTypes.func.isRequired,
};

RequestOrUploadConsent.defaultProps = {
  shouldShowTranslationsNewLanguages: false,
  contact: {},
};

function mapStateToProps(state, ownProps) {
  const language = state.globalState.language;
  const currentEmployee = state.globalState.currentEmployee;
  const contactId = get(ownProps, 'params.personId');
  const contact = find(state.contacts.contacts, { id: contactId });

  return {
    language,
    shouldShowTranslationsNewLanguages: showTranslationsNewLanguages(state),
    currentEmployee,
    contactId,
    contact,
  };
}

export default connect(mapStateToProps, {
  updateGlobalState,
})(RequestOrUploadConsent);
