import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { validations } from '@unite-us/app-components';
import {
  DateField,
} from '@unite-us/ui';
import _ from 'lodash';
import today from 'common/utils/today';
import minOpenCaseDate from 'common/utils/minOpenCaseDate';

const ENTRY_DATE_SELECT = 'entryDateSelect';

class DateDiv extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { programEnrollment } = nextProps;
    const currentEnrollmentActiveEnrollment = _.get(this.props, 'programEnrollment.is_active_enrollment', null);
    const nextEnrollmentActiveEnrollment = _.get(programEnrollment, 'is_active_enrollment', null);

    if (nextEnrollmentActiveEnrollment && (currentEnrollmentActiveEnrollment !== nextEnrollmentActiveEnrollment)) {
      const enrolledAt = programEnrollment.enrolled_at;
      if (enrolledAt !== this.props.entryDate.value) {
        this.props.entryDate.onChange(enrolledAt);
      }
    }

    if (currentEnrollmentActiveEnrollment !== nextEnrollmentActiveEnrollment &&
      (nextEnrollmentActiveEnrollment === null)) {
      this.props.entryDate.onChange('');
    }
  }

  render() {
    const {
      checkingEnrollment,
      entryDate,
      hidden,
      iconColor,
      id,
      label,
      programEnrollment,
      registerField,
    } = this.props;

    const enrollementActive = _.get(programEnrollment, 'is_active_enrollment', false);
    const enrollmentInNetwork = !_.get(programEnrollment, 'out_of_network', false);
    const dateFieldDisabled = checkingEnrollment || (enrollementActive && enrollmentInNetwork);

    return (
      <DateField
        disabled={dateFieldDisabled}
        field={entryDate}
        iconColor={iconColor}
        id={id}
        inline={false}
        label={label}
        maxDate={today()}
        minDate={minOpenCaseDate()}
        ref={registerField}
        required
        validations={[{
          func: (value) => {
            if (!hidden && (value === '' || _.isUndefined(value))) {
              return validations.isRequired();
            }
            return null;
          },
        }]}
      />
    );
  }
}

DateDiv.propTypes = {
  checkingEnrollment: PropTypes.bool,
  entryDate: PropTypes.object.isRequired,
  hidden: PropTypes.bool,
  iconColor: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  programEnrollment: PropTypes.object,
  registerField: PropTypes.func.isRequired,
};

DateDiv.defaultProps = {
  hidden: false,
  id: ENTRY_DATE_SELECT,
  label: 'Case Opened',
};

export default DateDiv;
