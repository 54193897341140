export const AR_ROUTE_PREFIX = 'assistance-requests';
export const FROM_AR_QUERY_PARAM = 'fromAssistanceRequest';

export const MULTIPLE_CC_RECIPIENT_ERROR_MESSAGE = 'A referral can only be made to one Coordination Center at a time.';

export const MULTIPLE_RECIPIENT_CC_ERROR_MESSAGE = [
  'A referral with multiple recipients cannot include a Coordination Center.',
  'Refer to a Coordination Center only if you are uncertain about which',
  'organization(s) can serve your client.',
].join(' ');

export const SENSITIVE_SERVICE_OR_PROVIDER_MESSAGE = [
  'It looks like you’re a trying to create a grouped referral involving a sensitive organization,',
  'but sensitive organizations can’t send or receive grouped referrals.',
  'Remove the sensitive organization to send the grouped referral.',
].join(' ');

export const MULTI_OFF_PLATFORM_SENSITIVE_SERVICE_REFERRALS_MESSAGE = [
  'It looks like you’re trying to add multiple recipients to a case with a sensitive service type. You',
  'cannot use grouped referrals for cases with sensitive service types. Create separate referrals for each recipient.',
].join(' ');
