import { compact, filter, find, get, includes, map } from 'lodash';
import { getDeterminantNetworkId } from 'src/components/Referrals/utils/form';
import {
  MULTIPLE_RECIPIENT_CC_ERROR_MESSAGE,
} from '../../constants';

function getInvalidSelectedGroupsErrorMessages({
  networkId,
  networks,
  selectedGroupIds = [],
}) {
  const network = find(networks, { id: networkId }) || {};
  const { ccIds } = network;

  const ccCount = (
    filter([...selectedGroupIds], (id) => includes(ccIds, id)) || []
  ).length;

  const providerCount = selectedGroupIds.length - ccCount;
  const hasMultipleRecipientsOrMixedProviders = (ccCount > 0 && providerCount > 0) || (ccCount > 1);

  return hasMultipleRecipientsOrMixedProviders ? [MULTIPLE_RECIPIENT_CC_ERROR_MESSAGE] : '';
}

function getInvalidSelectedGroupsErrors({ networks = [], service = {} }) {
  const isOONCase = get(service, 'isOONCase.checked', false);
  if (isOONCase) {
    return [];
  }
  return getInvalidSelectedGroupsErrorMessages({
    networkId: getDeterminantNetworkId(service),
    networks,
    selectedGroupIds: compact(map(service.selected, (selected) => get(selected, 'group.value.id', ''))),
  });
}

export default getInvalidSelectedGroupsErrors;
