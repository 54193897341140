import React from 'react';
import PropTypes from 'prop-types';
import Card from 'common/Card/Card';
import CareTeamMember from './CareTeamMember/CareTeamMember';
import FamilyMember from './FamilyMember/FamilyMember';

const Relationships = ({
  person, employeeId, getClientRelationship, visibilityConfig,
}) => (
  <>
    <h2 className="mb-4">Relationships</h2>
    <Card
      borderColor="border-dark-border-blue"
      className="p-4 mb-8 bg-dark-fill-blue"
    >
      <CareTeamMember
        person={person}
        getClientRelationship={getClientRelationship}
        className="mb-6"
        visibilityConfig={visibilityConfig}
      />
      {visibilityConfig.overview.showFamilyMembers && (
        <FamilyMember person={person} employeeId={employeeId} />
      )}
    </Card>
  </>
);

const OverviewVisibilityConfigPropType = PropTypes.shape({
  showFamilyMembers: PropTypes.bool.isRequired,
});

Relationships.propTypes = {
  person: PropTypes.object.isRequired,
  employeeId: PropTypes.string,
  getClientRelationship: PropTypes.func.isRequired,
  visibilityConfig: PropTypes.shape({
    overview: OverviewVisibilityConfigPropType.isRequired,
  }).isRequired,
};

Relationships.defaultProps = {
  employeeId: '',
};

export default Relationships;
