import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useFind } from 'src/api/APIHooks';
import {
  useCurrentPayerId,
  useCurrentProviderId,
  useIsCBOProvider,
  useIsNetworkLead,
  useIsPayer,
} from 'common/contexts/CurrentProviderContext';

const useFeeScheduleScreeningOptions = (feeScheduleScreeningOption) => {
  const providerId = useCurrentProviderId();
  const payerId = useCurrentPayerId();
  const isPayer = useIsPayer();
  const isNetworkLead = useIsNetworkLead();
  const isCBO = useIsCBOProvider();

  const { data: nlrData } = useFind(
    'network_lead_relationship',
    { network_lead: providerId },
    { queryConfig: { enabled: isNetworkLead, placeholderData: undefined } },
  );
  const networkLeadFeeScheduleIds = nlrData?.data?.data
    ?.map((nlr) => nlr.fee_schedule.id)
    ?.join(',');

  const [name, setName] = useState(null);
  const debounceSearch = useRef(debounce(setName, 300)).current;
  useEffect(() => {
    debounceSearch(feeScheduleScreeningOption?.label);
    return () => {
      debounceSearch.cancel();
    };
  }, [feeScheduleScreeningOption]);

  const enableQuery = !!name && (!isNetworkLead || !!networkLeadFeeScheduleIds);
  const { data } = useFind(
    'fee_schedule_screening',
    {
      name,
      ...(isCBO ? { providers: providerId } : {}),
      ...(isNetworkLead ? { fee_schedule: networkLeadFeeScheduleIds } : {}),
      ...(isPayer ? { 'fee_schedule.payers': payerId } : {}),
    },
    {
      queryConfig: {
        enabled: enableQuery,
        placeholderData: undefined,
      },
    },
  );

  return data?.data?.data?.map(({ name: screeningName, id }) => ({ label: screeningName, value: id })) ?? [];
};

export default useFeeScheduleScreeningOptions;
