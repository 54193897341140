import { useCallback } from 'react';
import { capitalize } from 'lodash/string';
import { useFilterSearchParamWithFF } from 'common/hooks/useFilterSearchParam';
import { useCurrentPayerId } from 'common/contexts/CurrentProviderContext';
import { useFind } from 'src/api/APIHooks';
import { REQUEST_TYPES } from '../constants';
import useFeatureFlag from '../../../common/hooks/useFeatureFlag';

export const useEnrollmentFilters = () => {
  const currentPayerId = useCurrentPayerId();
  const pays6096UpdateEWQDesign = useFeatureFlag('pays-6096-update-ewq-design');

  const { isLoading: isSccPlantDataLoading, data: sccPlanData } = useFind(
    'plans',
    {
      payer: currentPayerId,
      plan_type: 'social',
      enrollment_required: true,
    },
    {
      queryConfig: {
        enabled: !!currentPayerId,
        placeholderData: undefined,
      },
    },
  );
  const sccPlans = sccPlanData?.data.data ?? [];
  const sccPlanOptions = sccPlans?.map((plan) => ({
    label: plan.name,
    value: plan.id,
  }));

  const requiredHealthPlanIds = sccPlans?.map((plan) => (
    plan.required_health_plans?.map((required_plan) => required_plan.id)
  )).filter((value) => value !== undefined);
  const { isLoading: isRequiredHealthPlanDataLoading, data: insurancePlanData } = useFind(
    'plans',
    {
      id: requiredHealthPlanIds.join(','),
    },
    {
      queryConfig: {
        enabled: requiredHealthPlanIds?.length > 0 && pays6096UpdateEWQDesign,
        placeholderData: undefined,
      },
    },
  );
  const insurancePlans = insurancePlanData?.data.data ?? [];
  const insurancePlanOptions = insurancePlans.map((plan) => ({
    label: plan.name,
    value: plan.id,
  }));

  const { isLoading: isProviderDataLoading, data: providerData } = useFind(
    'providers',
    {
      'requester.enrollment_request.plan.payer': currentPayerId,
    },
    {
      queryConfig: {
        enabled: !!currentPayerId && pays6096UpdateEWQDesign,
        placeholderData: undefined,
      },
    },
  );
  const sendingProviders = providerData?.data.data ?? [];
  const sendingProviderOptions = sendingProviders.map((org) => ({
    label: org.name,
    value: org.id,
  }));

  const [requestType, setRequestType] = useFilterSearchParamWithFF(
    'request_type',
    undefined,
    'pays-6096-update-ewq-design',
  );
  const [sccPlan, setSccPlan] = useFilterSearchParamWithFF(
    'plan',
    undefined,
    'pays-6096-update-ewq-design',
  );
  const [daysSinceLastUpdated, setDaysSinceLastUpdated] = useFilterSearchParamWithFF(
    'days_since_last_updated',
    undefined,
    'pays-6096-update-ewq-design',
  );
  const [clientName, setClientName] = useFilterSearchParamWithFF(
    'client_name',
    undefined,
    'pays-6096-update-ewq-design',
  );
  const [requestShortId, setRequestShortId] = useFilterSearchParamWithFF(
    'request_short_id',
    undefined,
    'pays-6096-update-ewq-design',
  );
  const [insurancePlan, setInsurancePlan] = useFilterSearchParamWithFF(
    'insurance_plan',
    undefined,
    'pays-6096-update-ewq-design',
  );
  const [sendingProvider, setSendingProvider] = useFilterSearchParamWithFF(
    'sending_provider',
    undefined,
    'pays-6096-update-ewq-design',
  );
  const [createdAfter, setCreatedAfter] = useFilterSearchParamWithFF(
    'created_after',
    undefined,
    'pays-6096-update-ewq-design',
  );
  const [createdBefore, setCreatedBefore] = useFilterSearchParamWithFF(
    'created_before',
    undefined,
    'pays-6096-update-ewq-design',
  );

  const isFiltered = !!requestType || !!sccPlan || !!daysSinceLastUpdated || !!clientName ||
                    !!requestShortId || !!clientName || !!insurancePlan ||
                    !!sendingProvider || !!createdAfter || !!createdBefore;

  const resetFilters = useCallback(() => {
    setRequestShortId(undefined);
    setRequestType(undefined);
    setClientName(undefined);
    setSccPlan(undefined);
    setDaysSinceLastUpdated(undefined);
    setClientName(undefined);
    setInsurancePlan(undefined);
    setSendingProvider(undefined);
    setCreatedAfter(undefined);
    setCreatedBefore(undefined);
  }, [setRequestShortId,
    setRequestType,
    setClientName,
    setSccPlan,
    setDaysSinceLastUpdated,
    setInsurancePlan,
    setSendingProvider,
    setCreatedAfter,
    setCreatedBefore,
  ]);

  const daysSinceLastUpdatedOptions = [
    {
      label: 'Today',
      value: '1',
    },
    {
      label: 'Last 7 days',
      value: '7',
    },
    {
      label: 'Last 14 days',
      value: '14',
    },
    {
      label: 'Last 30 days',
      value: '30',
    },
    {
      label: 'Last 60 days',
      value: '60',
    },
  ];

  return {
    filterValues: {
      requestType,
      sccPlan,
      daysSinceLastUpdated,
      requestShortId,
      clientName,
      insurancePlan,
      sendingProvider,
      createdAfter,
      createdBefore,
    },
    filterOptions: {
      requestType: Object.values(REQUEST_TYPES).map((type) => (
        { value: type, label: capitalize(type) }
      )),
      sccPlan: sccPlanOptions,
      daysSinceLastUpdated: daysSinceLastUpdatedOptions,
      insurancePlan: insurancePlanOptions,
      sendingProvider: sendingProviderOptions,
    },
    isFiltered,
    isFilterDataLoading: (isSccPlantDataLoading || isRequiredHealthPlanDataLoading || isProviderDataLoading),
    resetFilters,
    setRequestShortIdFilter: setRequestShortId,
    setRequestTypeFilter: setRequestType,
    setClientNameFilter: setClientName,
    setSccPlanFilter: setSccPlan,
    setDaysSinceLastUpdatedFilter: setDaysSinceLastUpdated,
    setInsurancePlanFilter: setInsurancePlan,
    setSendingProviderFilter: setSendingProvider,
    setCreatedAfterFilter: setCreatedAfter,
    setCreatedBeforeFilter: setCreatedBefore,
  };
};
