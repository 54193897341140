import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import SurveyJSFormRenderer from '@unite-us/surveyjs/dist/components/Renderer/SurveyJSFormRenderer';
import buildResponses from '@unite-us/surveyjs/dist/components/Renderer/buildResponses';
import {
  Icon,
  Expandable,
} from '@unite-us/ui';
import { get, isEqual } from 'lodash';
import { coreApi } from 'src/api/config';
import FeatureFlagContainer from 'src/common/utils/FeatureFlags/FeatureFlagContainer';
import { useFeatureFlag } from 'common/hooks';

function FormForm({
  formId,
  groupId,
  expanded,
  handleExpandChange,
  handleSensitiveFormSkipped,
  setRef,
  currentEmployee,
  hasErrors,
  contextType,
  context,
  contextPerson,
  response,
  usage_type,
}) {
  const [state, setState] = useState({
    form: null,
    formSubmissionsData: null,
    formUsageData: null,
    isFetching: true,
  });
  const [surveyRef, setSurveyRef] = useState();

  let responsesForEdit;

  let isFetchingForm = true;
  let isFetchingSubmission = true;
  let isFetchingFormUsage = true;
  let isFetchingCase = false;

  const hasHideFieldInFormFlag = useFeatureFlag('crtb-1121-hide-field-in-form');

  useEffect(() => {
    const getData = async () => {
      const formResponse = await coreApi.findRecord('form', formId);
      const form = get(formResponse, 'data.data');
      isFetchingForm = false;
      const apiResponses = await Promise.all([
        coreApi.query('form_submission', {
          'context.type': contextType,
          context: contextPerson ? null : context,
          'context.person': contextPerson,
          form: formId,
          'form.form_usages.provider': groupId,
        }, {
          page: {
            number: 1,
            size: 1,
          },
        }),
        coreApi.query('form_usage', {
          form: formId,
          provider: groupId,
          usage_type,
        }, {
          page: {
            number: 1,
            size: 1,
          },
        }),
      ]);
      const [formSubmissionsData, formUsageData] = apiResponses;

      isFetchingSubmission = false;
      isFetchingFormUsage = false;

      if (response) {
        responsesForEdit = response.data;
      }
      const formSubmission = responsesForEdit || get(formSubmissionsData, 'data.data[0]');
      let isSensitive = false;
      if (contextType === 'case') {
        isFetchingCase = true;
        // Check for sensitive form submission
        try {
          await coreApi.populateRelationship('context', 'case', formSubmission);
        } catch (error) {
          isSensitive = get(error, 'response.status') === 403;
        }

        isFetchingCase = false;
        if (isSensitive) {
          handleSensitiveFormSkipped();
        }
      }

      if (hasHideFieldInFormFlag) {
        const featureFlagQuestion = form.configuration.pages[0].elements.find((e) => (
          e.name === 'crtb-1121-hide-field-in-form'
        ));

        if (featureFlagQuestion) {
          const questionIdToHide = featureFlagQuestion.html;
          form.configuration.pages = form.configuration.pages.map((page) => ({
            ...page,
            elements: page.elements.map((element) => {
              if (element.id === questionIdToHide) {
                return { ...element, visible: false };
              }
              return element;
            }),
          }));
        }
      }

      const isFetching = isFetchingForm || isFetchingSubmission || isFetchingFormUsage || isFetchingCase;
      setState({
        form,
        formSubmissionsData,
        formUsageData,
        isSensitive,
        isFetching,
      });
    };

    getData();
  }, []);

  const {
    form,
    formSubmissionsData,
    formUsageData,
    isSensitive,
    isFetching,
  } = state;
  const formUsage = get(formUsageData, 'data.data[0]');

  // If response is inherited from props, it means that a form that haven't been submitted is being edited.
  const formSubmission = responsesForEdit || get(formSubmissionsData, 'data.data[0]');

  if (!form || !formSubmissionsData || !formUsageData) {
    return null;
  }

  const buildRequestBody = () => ({
    responses: buildResponses(surveyRef),
    form: form.id,
    provider: groupId,
    contextType,
    context,
    submitter: currentEmployee.id,
  });

  setRef({
    ref: surveyRef,
    buildRequestBody,
  });

  const surveyJSON = {
    configuration: {
      title: form.name,
      description: form.description,
      ...form.configuration,
    },
  };

  const handleSetSurvey = (survey) => {
    if (!surveyRef || !isEqual(survey.data, surveyRef.data)) {
      setSurveyRef(survey);
    }
  };

  if (isFetching) {
    return (
      <Expandable
        expanded={expanded}
        header="Loading..."
        onExpandChange={handleExpandChange}
      />
    );
  }

  const title = (
    <span>
      {get(form, 'name', '')}
      {
        hasErrors && (
          <Icon
            className="ml-one"
            color="#E53935"
            icon="ExclamationTriangle"
            size={12}
          />
        )
      }
    </span>
  );
  const showFormSubmission = get(formUsage, 'prepopulate') ? formSubmission : null;

  return (
    <Expandable
      className="form-form"
      expanded={expanded}
      header={title}
      onExpandChange={handleExpandChange}
    >
      {(!isFetching) && (
        <SurveyJSFormRenderer
          formData={surveyJSON}
          formSubmission={isSensitive ? null : showFormSubmission}
          editMode
          handleSetSurveyModel={handleSetSurvey}
        />
      )}
    </Expandable>
  );
}

FormForm.propTypes = {
  currentEmployee: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  formId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  handleExpandChange: PropTypes.func.isRequired,
  handleSensitiveFormSkipped: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  contextType: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
  contextPerson: PropTypes.string,
  response: PropTypes.object,
  usage_type: PropTypes.string.isRequired,
};

FormForm.defaultProps = {
  response: undefined,
  contextPerson: null,
};

export default FeatureFlagContainer(FormForm);
