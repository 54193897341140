import { ComponentLibraryLoader } from '@unite-us/app-components';
import { datadogRum } from '@unite-us/client-utils';

const OrganizationContainerLazyLoader = ComponentLibraryLoader({
  loadFn: () => import('./OrganizationContainer'),
  component: 'default',
  loadingApp: 'app-client',
  source: 'org-admin',
  datadogRum,
});

export default OrganizationContainerLazyLoader;
