import { useFind } from 'src/api/APIHooks';
import { get, groupBy, map, isEmpty } from 'lodash';

const useEnrollmentShortIdOptions = (
  providerId,
  shortIdLike,
  enrollmentStatus,
) => {
  const { isLoading, data } = useFind(
    'enrollment_requests',
    {
      enrollmentStatus,
      short_id_like: shortIdLike,
      'plan.payer': providerId,
    },
    {
      queryConfig: {
        enabled: (!isEmpty(shortIdLike)),
        placeholderData: undefined,
      },
    },
  );

  const enrollments = groupBy(get(data, 'data.data', []), 'short_id');
  const requestShortIdOptions = map(enrollments, (val, key) => ({
    label: key,
    value: map(val, 'short_id').join(','),
  }));

  return isLoading ? [] : requestShortIdOptions.slice(0, 30);
};

export default useEnrollmentShortIdOptions;
