import { useFind } from 'api/APIHooks';
import {
  useCurrentPayerId,
  useCurrentProviderId,
  useIsCBOProvider,
  useIsNetworkLead,
  useIsPayer,
} from 'common/contexts/CurrentProviderContext';
import { INVOICE_TYPES } from '../constants';

const useInvoiceTypes = () => {
  const providerId = useCurrentProviderId();
  const payerId = useCurrentPayerId();

  const isPayer = useIsPayer();
  const isNetworkLead = useIsNetworkLead();
  const isCBO = useIsCBOProvider();

  const { data: nlrData } = useFind(
    'network_lead_relationship',
    { network_lead: providerId },
    { queryConfig: { enabled: isNetworkLead, placeholderData: undefined } },
  );
  const networkLeadFeeScheduleIds = nlrData?.data?.data
    ?.map((nlr) => nlr.fee_schedule.id)
    ?.join(',');

  const enableQueries = isCBO || isPayer || (isNetworkLead && !!networkLeadFeeScheduleIds);
  const { data: fspData } = useFind(
    'fee_schedule_program',
    {
      ...(isCBO ? { 'programs.provider': providerId } : {}),
      ...(isNetworkLead ? { fee_schedule: networkLeadFeeScheduleIds } : {}),
      ...(isPayer ? { 'fee_schedule.payers': payerId } : {}),
    },
    {
      page: { number: 1, size: 1 },
      queryConfig: { enabled: enableQueries, placeholderData: undefined },
    },
  );

  const { data: fssData } = useFind(
    'fee_schedule_screening',
    {
      ...(isCBO ? { providers: providerId } : {}),
      ...(isNetworkLead ? { fee_schedule: networkLeadFeeScheduleIds } : {}),
      ...(isPayer ? { 'fee_schedule.payers': payerId } : {}),
    },
    {
      page: { number: 1, size: 1 },
      queryConfig: { enabled: enableQueries, placeholderData: undefined },
    },
  );

  const hasFeeSchedulePrograms = !!(fspData?.data?.data?.length);
  const hasFeeScheduleScreenings = !!(fssData?.data?.data?.length);

  return [
    hasFeeSchedulePrograms && INVOICE_TYPES.SERVICE,
    hasFeeScheduleScreenings && INVOICE_TYPES.SCREENING,
  ].filter(Boolean);
};

export default useInvoiceTypes;
